.SettingsContainer {
  display: grid;
  grid-template-areas:
    "header header header"
    "sidebar main main";
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr; 
  height: 100vh;
}

@media (max-width: 768px) {
  .SettingsContainer {
    grid-template-columns: 1fr; 
  }
}

.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin:1em 0;
  gap:1em;
  /* border-bottom:1px solid #d2d2d2; */
  >span{
    display: flex;
    gap:.5em;
    align-items: center;
  }
}

.compose,.filter{
  display: flex;
  gap:.5em;
  align-items: center;
  font-size:16px;
  background-color: green;
  color:#fff;
  padding: 8px 16px;
  font-weight: 800;
  border-radius: 8px;
  height:45px;
}
.filter{
  background-color: transparent;
  border:1px solid gray;
  color:#000;
}

.compose:hover{
  background-color: green;
color:#fff;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

}
.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  gap:1em;
  width:100%;
}

.searchIcon {
  cursor: pointer;
  font-size: 25px;
  color:gray;
  padding:.5em;
  border-radius: 15px;
  background-color: transparent;
}

.search {
  width: 200px;
  transform: translateY(-7px);
  outline:none;
  font-size:16px;
  opacity: 1;
  border: none;
  border-bottom: 2px solid transparent;
  outline: none;
  transition: width 0.4s ease, opacity 0.3s ease, border-color 0.3s ease;
  padding:8px 16px;
  border-bottom:1px solid gray;
  border:1px solid #d2d2d2;
  border-radius: 5px;
  height:45px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */

}



.search.expanded {
  width: 200px; /* Adjust as needed */
  opacity: 1;
  border-radius: 15px;
  outline:none;
  border:1px solid #d2d2d2;
}

.search:focus {
  outline:none;
border:none;
box-shadow: 0 4px 8px rgba(0,0,0,0.4);
border:1px solid #d2d2d2;

}

@media (max-width:768px){
  .parent{
  margin-top:1em;

  }
}
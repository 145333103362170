.parent{
  display: flex;
  flex-direction: column-reverse;
  width:100%;
  box-sizing: border-box;
  padding:1em;
  .modalButton{
  display:none;
 }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}



.zoomIn {
  animation: zoomInAnimation 0.6s ease-in-out;
}



  

.teachingContainer{
  padding:1em 0;
  width:100%;
  .buttonContainer{
    padding:.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  width:100%;
    gap:.5em;
    flex-direction: row;
    justify-content: flex-end;
    span{
      display: flex;
      align-items: center;
      gap:.5em;
      font-size:14px;
      font-weight: 600;
      padding:8px;
      background-color: #242629;
      color:gray;
      &:hover{
        color:#fff;
        scale: 1.03;
      }
      
    }
  }
  
  div:nth-of-type(1){
    display:none;
  }
}


.activeButton {
  background-color: #242629 !important; /* Higher specificity to override existing styles */
  color: #fff !important;
  border:5px double #fff;
  outline:1px solid #fff;
}

.cardsContainer{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap:1em;

  span{
    border-radius: 25px;
    border:1px solid gray;
    height:150px;
    padding:1em;
    p:nth-child(1){
      font-size:16px;
      color:gray;
    }

    .cardNumber{
      font-weight: 800;
      font-size:40px;
      color :gray;
    }
  }
}

.methodContainer{
  display: none;

}

@media(max-width:768px){
  .parent{
    margin:.5em 0;
  }

  .cardsContainer {
    padding: 0.5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }
  
  .card {
    padding: 0.5em;
    border-radius: 5px;
    min-width: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 120px;
    font-size: 12px;
    background-color: white;
    display: grid;
    place-items: center;
    position: relative;
  }
  
  /* teacing methods */
  .teachingContainer,
  .methodContainer {
    padding: 0.5em;
    margin:0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .teachingContainer{
    padding:1em 0;
    width:100%;
    .buttonContainer{
      padding:.5em;
      display: grid;
      grid-template-columns: 1fr 1fr ;
    width:100%;
      gap:.5em;
      flex-direction: row;
      justify-content: flex-end;
      span{
        display: flex;
        align-items: center;
        gap:.5em;
        font-size:10px;
        padding:8px;
        background-color: #242629;
        color:gray;
        &:hover{
          color:#fff;
        }
        
      }
    }
    
    div:nth-of-type(1){
      display:none;
    }
  }
  .header {
    padding: 0.5em;
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-weight: 800;
    color: #383232;
  }
  
  .cardTitle {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
    width: 100%;
    text-align: left;
  }
  
  .cardNumber {
    width: 100%;
    font-size: 25px;
    font-weight:800;
  }
  
  /* icons/ buttons */
  .buttonContainer {
    padding: 0.5em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em;
    width: 100%;
  }
  
  .span {
    content: "";
    font-size: 12px;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    gap: 0.5em;
  }
  
  .icon {
    font-size: 25px;
    position:relative;
  }
  
  
  .indicator{
    position:absolute;
    top:-.5em;
    right:-.5em;
    background-color: red;
    color:#383232;
    border-radius: 50%;
    font-size:12px;
    min-width:15px;
    min-height:15px;
  }
  
}

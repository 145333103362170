.classworkViewContainer {
  width: 100%;
  padding: 1em;
  overflow-x: hidden;
}

.classworkViewHeader {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  /* padding: 1em; */
  padding-bottom: 0;
  font-weight: 600;
  /* margin-bottom: 1em; */
  justify-content: space-between;
  > span {
    font-size: 20px;
    font-weight: 800;
    color: gray;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
  }
  > button {
    background-color: transparent;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(255, 83, 83);
    border-radius: 5px;
    /* padding: 8px 16px; *
    /
    /* margin:1em; */

    cursor: pointer;
    &:hover {
      scale: 1.03;
      background-color: #fff;
    }
  }
}

.classworkBottom {
  margin-top: 1em;
  display: flex;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding: 0;
  margin: 0;

  button {
    color: #d2d2d2;
    width: 100%;
    margin: 0;
    margin-top: 0.5em;

    /* padding: 8px 16px; */
    background-color: green;
    font-weight: 600;
    font-size: 20px;
    &:hover {
      background-color: green;
      color: #fff;
    }
  }
}

.max {
  /* margin: 0 1em; */
}
.classworkMain {
  /* padding-bottom: 3em; */
  max-height: 400px;
  min-height: 300px;
  overflow-y: auto;
  border-radius: 5px;
  border: 1px solid #d2d2d2;
  /* margin: 0.5em; */
  position: relative;
}

.participantRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
  border-bottom: 1px solid #e2e2e2;
}

.participantRow span {
  flex: 1;
  font-size: 1em;
}

.participantRow input {
  width: 60px;
  padding: 0.5em;
  border: 1px solid #d2d2d2;
  border-radius: 5px;
  text-align: center;
}

.searchContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 0.5em; */
  position: relative;
  margin: 0;
  /* border: 1px solid black; */
  gap: 0.5em;
  margin-bottom: 0.5em;
  > span {
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin: 0;
    /* position:absolute; */
    transform: translateX(-5px);
    cursor: pointer;
    top: 0;
    right: 0;
    padding: 0;
    border: none;
    /* transform: translateY(20px); */
    font-size: 45px;
    &:hover {
      color: #000;
    }
  }
}
.searchInput {
  max-width: 200px;
  border-radius: 15px;
}

/* Style for Add Modal overlay */
.addModal {
  width: 100%;
  height: 300px;
  /* padding: 10px; */
  border-radius: 8px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.modalContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 0.5em;   */
  overflow-x: hidden;

  > span {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: #eaeaea;
    /* padding:1em; */
    padding:.5em;
    border-radius: 0;
    > span::first-line {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      color:gray;
    }
    > span {
      color: green;
      text-align: left;
      font-size: 12px;
    }
    >button{
    }
  }
}

.availableParticipants {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  border-top: 1px solid #e2e2e2;
  /* margin-top: 1em; */
  /* padding-top: 1em; */
}

.studentRow {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom:1px solid #d1d1d1;

  gap: 1em;
  padding: .5em;
  justify-content: space-between;
  > input[type="number"] {
    width: 100px;
    font: 14px;
    /* padding:8px 16px; */
    padding:.5em;
    margin:0;
    border: 1px solid #d2d2d2;
    border-radius: 12px;
    /* margin:0 1em; */
  }
}

.studentRow span {
  font-size: 14px;
  text-transform: capitalize;
}

.studentRow button {
  background-color: #4caf50;
  color: white;
  border: none;
  /* padding: 5px 10px; */
  border-radius: 5px;
  margin:0;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.studentRow button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  
}

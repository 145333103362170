.sidebarParent {
  padding: 1em;
  border-radius: 12px;
}
.sidebarParent .sidebarTitle {
  font-weight: 800;
  font-size: 1.5em;
  margin: 0.2em;
}

.sidebarParent .sidebarContainer {
  margin: 0;
  display: flex;
    flex-wrap: wrap;
    max-width: 15vw;
    gap:.2em;
  padding: 0;
}

.sidebarParent .sidebarContainer:hover > :not(:hover) {
  opacity: 0.4;
}

.searchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  width:100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom:.5em;
  >a{
    font-size: 10px;
    color:greenyellow;
    border:1px solid greenyellow;
    padding:.5em;
    border-radius: 5px;
  }
}

.search[type="search"] {
  width: 100%;
  border-radius: 12px;
  outline: none;
  padding: 0.6em;
  font-size: 12px;
  border-radius: 12px;
  border: none;
}
.searchIcon {
  cursor: pointer;
  font-size:20px;
  padding:.2em;
}
.searchIcon:hover {
  scale: 1.1;
}

@media (max-width: 800px) {
  .sidebarParent .sidebarContainer {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 100vw;
    gap:0;

  }


}

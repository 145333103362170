.buttons{
    width:50vw;
    display: flex;
    gap:1em;
    justify-content: flex-end;
    width: 100%;
    margin:1em 0;
    /* padding-right:1em; */
    align-items: center;
    button{
        font-size:14px;
        display: flex;
        align-items: center;
        border:1px solid gray;
        gap:.5em;
        font-weight: 600;
        color:gray;
        :hover{
            background-color: transparent;
        }
    }
}

.componentContainer{
    padding:0 ;
}
:root{
  --min-height:45px;
  --max-height:45px;
}
* {
  list-style-type: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  outline: none;
  scrollbar-width: none; /* Hides scrollbar in Firefox */
  -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
}
input[type = "password"],input[type="text"],input[type="email"]{
  border:1px solid #d1d1d1 !important;
  border-radius: 5px !important;
  min-width: 150px;
  font-size: 14px !important;
  min-height: var(--min-height) !important;
  color:#242629 !important;
  max-height: var(--max-height) !important;
}
input[type= "search"]{
  box-shadow: 0 4px 4px rgba(0,0,0,0.1) !important;
  border-radius: 8px !important;
  outline:1px solid #d1d1d1 !important;
}
input[type = "file"]{
  border:1px solid #d1d1d1 !important;

}

button{
  border:none !important;
}
table{
  /* border:2px solid #fff !important; */
  border-radius: 8px !important;
  padding:.5em;
  min-width: 150px;
  border-radius: 5px !important;

}
th{
  margin-bottom: .5em !important;
  padding:.5em;
  text-align: left;
}
td{
  border:1.5px solid #fff !important;
  font-size: 12px;
  text-align: left;
  padding:.5em;
  max-width: 500px;
}
input[type = "radio"]{
  min-height: 25px !important;
  max-height: 25px !important;
  margin:0 !important;
}
input[type = "checkbox"]{
  border:1px solid #d1d1d1 !important;
}
select{
  border:1px solid #d1d1d1 !important;
  font-size: 14px !important;
  min-height: var(--min-height) !important;
  max-height: var(--max-height) !important;
}
textarea{
  border:1px solid #d1d1d1 !important;
  color:#242629 !important;
  padding:.5em !important;

}
/* Hide scrollbar for Chrome, Safari, and Opera */
*::-webkit-scrollbar {
  display: none;
}

a{
  text-decoration: none;
}

.App {
  min-height:100vh;
  height:100%;
  text-align:center;
  position:relative;
}



/* Container with glassmorphism effect */
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.changePasswordContainer {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2em;
  width: 350px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

/* Styling for the form title and button */
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1em;
}

/* Input fields styling */
.inputGroup {
  margin-bottom: 1.5em;
}

.input {
  width: 100%;
  padding: 0.6em;
  margin: 0.5em 0;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 1em;
}

/* Button styling */
.button {
  width: 100%;
  padding: 0.6em;
  margin-top: 1em;
  border-radius: 5px;
  border: none;
  background-color: greenyellow;
  color: #000;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.header {
  top: 80px;
  width: 100%;
  text-align: center;
  padding: 1em 1em 0 1em;
  top: 0;
  position: sticky;
  display: flex;
  z-index: 4;
  background-color: #fff;
  justify-content: space-between;
  border-radius: 0 0 12px 12px;
  button {
    padding: 8px 16px;
    cursor: pointer;
    margin: 0;
    margin-top: 1em;
    color: gray;
    display: flex;
    gap: 0.5em;
    font-weight: 800;
    background-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    align-items: center;
    font-size: 16px;
    &:hover {
      scale: 1.1;
      background-color: #fff;
    }
  }
}

.viewClassContainer {
  position: relative;
  width: 100%;
  min-height: 100vh;
  .main {
    transform: translateY(30px);
    width: 100%;
    display: flex;
    justify-content: center;
    /* materials */
    .materialsContainer {
      display: flex;
      flex-direction: column;
      padding:0 1em;
      .materialsHeader {
        display: grid;
        grid-template-columns: 2fr 1fr;
        width: 100%;
        gap: 0.5em;
        .searchMaterial {
          max-height: 40px;
          padding: 0.5em;
          border:1px solid #d1d1d1;
        }
        .hideMaterialButton {
          background-color: red;
          color: #fff;
          max-height: 40px;
          min-height: 40px;
          font-weight: 600;
        }
      }

      .materialCardContainer {
        max-height: 100vh;
        min-height: 70vh;
        padding: 0.5em 0em 3em 0em;
        border-radius: 0;
        /* border:1px solid #d2d2d2; */
        overflow-y: auto;
        .materialCard {
          width: 100%;
          padding: 0 4px;
          border: 1px solid #d2d2d2;
          display: flex;
          border-radius: 5px;
          position: relative;
          align-items: center;
          justify-content: space-between;
          .materialInfo {
            text-align: left;
            display: grid;
            p {
              font-size: 10px;
            }
            p:nth-of-type(1) {
              font-weight: 600;
              font-size: 14px;
            }
          }
          .downloadButton {
            transform: translateY(7px);
            background-color: green;
            color: #fff;
            padding: 8px;
          }
        }
      }
    }
    /* contents */
    .contentsContainer {
      /* border: 1px solid gray; */
      flex: 3;
      width: 100%;
      padding: 0 1em;
      .banner {
        width: 100%;
        min-height: 250px;
        border-radius: 15px;
        position: relative;
        .btnTopMaterialsToggler {
          position: absolute;
          top: 0.5em;
          left: 0.5em;
          background-color: #fff;
          color: #000;
        }
        .topBanner {
          position: absolute;
          top: -5px;
          right: 10px;
          width: 200px;
          display: flex;
          align-items: flex-end;
          padding: 0.5em;
          justify-content: center;
          height: 200px;
          font-size: 60px;
          color: gray;
          border: 5px double gray;
          border-radius: 5px 15px 50% 50%;
          background-color: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
        .topBanner::after {
          content: "";
          left: -25px;
          position: absolute;
          top: -5px;
          width: 10px;
          height: 110px;
          font-size: 0px;
          color: gray;
          border-radius: 0 0 5px 5px;
          background-color: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
        .topBanner::before {
          content: "";
          left: -45px;
          position: absolute;
          top: -5px;
          width: 10px;
          height: 70px;
          font-size: 0px;
          color: gray;
          border-radius: 0 0 5px 5px;
          background-color: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
        .classInfo {
          position: absolute;
          bottom: 20px;
          display: flex;
          flex-direction: column;
          gap: 0.2em;
          left: 20px;
          color: #eaeaea;
          .classSubject {
            font-weight: 800;
            font-size: 30px;
            text-transform: uppercase;
            text-align: left;
            max-width: 420px;
          }
          .classSection {
            font-size: 20px;
            text-align: left;
          }
        }
      }
    }

    .streamsContainer {
      margin-top: 1em;
      border-radius: 15px;
      display: grid;
      gap: 1em;
      padding-bottom: 3em;
      .contentCard {
        width: 100%;
        min-height: 200px;
        max-height: 200px;
        border-radius: inherit;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5em;
        .videoContainer {
          max-width: 300px;
          width: 100%;
          height: 100%;
          overflow: hidden;
          /* transform: scaleX(-1); */
          flex: 1;
          iframe {
            width: 300px;
            object-fit: cover;
            /* transform: scaleX(-1); */

            width: 100%;
            border-radius: 12px;
            height: 100%;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          gap: 0.2em;
          border: 1px solid #d2d2d2;
          flex: 1;
          height: 100%;
          padding: 0.5em;
          position: relative;
          border-radius: 12px;

          span {
            text-align: left;
          }
          .title {
            font-weight: 800;
            font-size: 25px;
            color: #242629;
          }

          .date {
            color: gray;
            font-size: 12px;
          }

          .attachmentButton {
            position: absolute;
            bottom: 5px;
            right: 5px;
            color: #fff;
            padding: 8px 16px;
            background-color: green;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

@media (max-width: 768px) {
  .viewClassContainer {
    position: relative;
    width: 100%;
    min-height: 100vh;
    .main {
      transform: translateY(30px);
      width: 100%;
      display: flex;
      justify-content: center;
      /* materials */
      .materialsContainer {
        position: absolute;
        z-index: 100;
        background-color: #fff;
        width: 100%;
        transform: translateY(-10px);
        height: auto;
        max-height: auto;
        .materialsHeader {
          .searchMaterial {
            padding: 0.5em;
          }
          .hideMaterialButton {
            max-height: 40px;
            min-height: 40px;
          }
        }

        .materialCardContainer {
          border-radius: 0;
          .materialCard {
            padding: 0 4px;
            .materialInfo {
              p {
                font-size: 10px;
              }
              p:nth-of-type(1) {
                font-size: 14px;
              }
            }
            .downloadButton {
              transform: translateY(7px);
              padding: 8px;
            }
          }
        }
      }
      /* contents */
      .contentsContainer {
        flex: 3;
        width: 100%;
        padding: 0 1em;
        .banner {
          width: 100%;
          min-height: 250px;
          border-radius: 15px;
          position: relative;
          .topBanner {
            position: absolute;
            top: -5px;
            right: 10px;
            width: 200px;
            display: flex;
            align-items: flex-end;
            padding: 0.5em;
            justify-content: center;
            height: 200px;
            font-size: 60px;
            color: gray;
            border: 5px double gray;
            border-radius: 5px 15px 50% 50%;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }
          .topBanner::after {
            content: "";
            left: -25px;
            position: absolute;
            top: -5px;
            width: 10px;
            height: 110px;
            font-size: 0px;
            color: gray;
            border-radius: 0 0 5px 5px;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }
          .topBanner::before {
            content: "";
            left: -45px;
            position: absolute;
            top: -5px;
            width: 10px;
            height: 70px;
            font-size: 0px;
            color: gray;
            border-radius: 0 0 5px 5px;
            background-color: #fff;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }
          .classInfo {
            position: absolute;
            bottom: 20px;
            display: flex;
            flex-direction: column;
            gap: 0.2em;
            left: 20px;
            color: #eaeaea;
            .classSubject {
              font-weight: 800;
              font-size: 30px;
              text-transform: uppercase;
              text-align: left;
              max-width: 420px;
            }
            .classSection {
              font-size: 20px;
              text-align: left;
            }
          }
        }
      }

      .streamsContainer {
        margin-top: 1em;
        border-radius: 15px;
        display: grid;
        gap: 1em;
        padding-bottom: 3em;
        .contentCard {
          width: 100%;
          min-height: 200px;
          max-height: 200px;
          border-radius: inherit;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5em;
          .videoContainer {
            max-width: 300px;
            width: 100%;
            height: 100%;
            overflow: hidden;
            transform: scaleX(-1);
            flex: 1;
            video {
              width: 300px;
              object-fit: cover;
              transform: scaleX(-1);

              width: 100%;
              border-radius: 12px;
              height: 100%;
            }
          }
          .info {
            display: flex;
            flex-direction: column;
            gap: 0.2em;
            border: 1px solid #d2d2d2;
            flex: 1;
            height: 100%;
            padding: 0.5em;
            position: relative;
            border-radius: 12px;

            span {
              text-align: left;
            }
            .title {
              font-weight: 800;
              font-size: 25px;
              color: #242629;
            }

            .date {
              color: gray;
              font-size: 12px;
            }

            .attachmentButton {
              position: absolute;
              bottom: 5px;
              right: 5px;
              color: #fff;
              padding: 8px 16px;
              background-color: green;
            }
          }
        }
      }
    }
  }
}

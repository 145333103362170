@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.main {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  padding: 5em 2em;
  margin: 60px 0;
  gap: 3em;
  min-height: 600px;
  font-family: Poppins;
}
.main section:last-of-type {
  border-radius: 500px 300px 700px 1000px;
  padding: 1em;
  display: flex;
  justify-content: center;
}

/* animation */
.main section:first-of-type .leftContent > * {
  opacity: 0;
  filter: blur(10px);
  transform: translateY(20px);
  animation: showContent 1s ease forwards;
}

.main section:nth-of-type(2) .imageContainer {
  animation: slideLeft 0.4s ease forwards;
}

.slideDown {
  animation: showContent 0.4s ease forwards;
}

.slideLeft {
  animation: slideLeft 0.4s ease forwards;
}

@keyframes showContent {
  0% {
    opacity: 0;
    filter: blur(10px);
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.main section:first-of-type {
  position: relative;
  h2 {
    text-align: left;
    width: 100%;
    padding: 1em;
    margin:0 1em;

    border-bottom: 1px solid #d1d1d1;

  }
  img {
    position: absolute;
    inset: 0;
    width: 100%;
    display: none;
    height: 100%;
    z-index: -1;
  }
  > .leftInformation {
    padding: 1em;
    display: flex;
    flex-direction: column;
    color: #242629;
  }

  .leftContent {
    padding: 1em;
    text-align: left;
    .leftAuthor {
      letter-spacing: 5px;
      animation-delay: 0.1s;
      font-weight: bold;
      margin-bottom: 0.5em;
      font-size: 12px;
      text-transform: uppercase;

    }
    .leftTitle {
      font-weight: 800;
      font-size: 3.3em;
      animation-delay: 0.2s;
      color: green;
    }
    .leftTopic {
      font-weight: 800;
      font-size: 3.0em;
      margin-bottom: 0.2em;
      animation-delay: 0.3s;
    }
    .leftDescription {
      font-size: 12px;
      line-height: 1.5em;
      text-shadow: 0 4px 8px rgba(0,0,0,0.4);
      animation-delay: 0.4s;
    }
    .leftButtons {
      animation-delay: 0.5s;
    }
  }

  .leftButtons {
    display: flex;
    margin-top: 1em;
    gap: 1em;
    width: 100%;
    align-items: center;
    justify-content: flex-start;

    > button:nth-of-type(1) {
      background-color: green;
      color: #fff;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      gap: 0.5em;
    }
    > button:nth-of-type(2) {
      &:hover {
        background-color: #fff;
      }
    }
  }
}

/* hand welcome image */
.imageContainer {
  padding: 1em;
  width: 100%;
  position: relative;
  > h2 {
    border-radius: 25px 25px 0 0;
    padding: 0.5em;
    /* text-shadow: 0 4px 4px rgba(0, 0, 0, 0.4); */
    color: #242629;
    font-size: 20px;
    > span {
      color: green;
    }
  }
}
.imageContainer > img {
  width: 100%;
  object-fit: cover;
  border-radius: 500px 300px 600px 1000px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

label {
  display: flex;
  gap: 0.5em;
}
/* signup panel */
.signup {
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 1em;
  position: relative;
  display: flex;
  background-color: #fff;
  justify-content: center;

  text-align: center;

  
  form {
    display: flex;
    flex-direction: column;
    h4 {
      padding: 0.5em 0;
      margin:0 1em;

      border-bottom: 1px solid #d1d1d1;
      text-align: left;
    }
    .firstSection {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0 1em;
      gap: 1em;
    }
    .firstSection div:nth-of-type(1) {
      display: flex;
      width: 100%;
      align-items: flex-start;
      gap: 1em;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      /* border: 1px solid gray;; */
    }
    .firstSection div:nth-of-type(1) > img {
      width: 130px;
      height: 130px;
      text-align: center;
      min-width: 130px;
      min-height: 130px;
      border-radius: 50%;
      border: 5px double #d1d1d1;
      object-fit: cover;
      font-size: 1px;
      text-align: center;
      margin-bottom:1em !important;
    }
    input[type="radio"] {
      font-size: 50px;
    }
    input:not([type="radio"]):not([type="file"]) {
      padding: 0.5em;
      border: 1px solid #d1d1d1;
    }
    input[type="file"] {
      border: 1px solid #d1d1d1;
    }

    .firstSection div:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      border: 1px solid rgb(183, 183, 183);
      border-radius: 15px;
      padding: 0.5em;
      label {
        display: flex;
        justify-content: center;
        width: auto;
        width: 150px;
        padding: 1em;
      }
    }
    /* second section */
    .secondSection {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 1em;
      flex-wrap: wrap;
      gap: 1em;
    }

    /* third section */
    .thirdSection {
      gap: 1em;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 0 1em;
      flex-wrap: wrap;
      width: 100%;
      white-space: no-wrap;

      > p {
        color: red;
        width: 100%;
        grid-column: 1 / span 3;
        text-align: right;
        font-size: 16px;
        white-space: no-wrap;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 0.5em;
      }
      .success {
        color: green;
      }
    }
    .emailVerificationBox {
      width: 100%;
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      text-align: left;
      gap: 0.5em;
      padding: 0 1em;
      > input {
        max-width: 120px;
        border: 1px solid #d1d1d1;
        max-height: 40px;
        margin-right:.5em;

      }
      
    }
    .validCode {
      width: 100%;
      font-size: 16px;
      color: green;
      text-align: left;
    }
    .invalidCode {
      padding: 0 1em;
      text-align: left;
      width: 100%;
      color: red;
      font-size: 16px;
    }
    /* fourthsection buttons */
    .fourthSection {
      padding: 8px 16px;
      margin-top: 1em;
      gap: 1em;
      display: grid;
      grid-template-columns: 1fr 2fr;
      .submitButton {
        background-color: green;
        color: #fff;
        padding: 8px 16px;
        font-weight: 600;
      }
      
    }
    .termsAcceptence{
      display:flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      display: flex;
    }
  }
}
.absoluteBackground {
  position: fixed;
  object-fit: cover;
  top: 80px;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  /* filter: blur(10px); */
  opacity: 0.5;
}

/* login panel */
.choices {
  width: 100%;
  border-radius: 25px;
  padding: 1em 0;
  margin-left: 2em;
  position: relative;
  background: rgba(255, 255, 255, 0.3);
  background-image: 
    linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px; 
  backdrop-filter: blur(10px);

  >.choicesAbsoluteBackground{
    width:100%;
    height:100%;
    display: none;
    position:absolute;
    object-fit: cover;
    left:0;
    top:0;
    z-index: -1;
    border-radius: inherit;
    filter: blur(6px);
    opacity: 0.3;
    /* filter:blur(6px) */
  }

}

@media (max-width: 760px) {
  .main {
    grid-template-columns: 1fr;
    padding: 0;
    padding-bottom: 150px;
  }

  .imageContainer {
    > h2 {
      display: none;
    }
  }

  /* signup panel */
  .signup {
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    padding: 0.5em;
    position: relative;
    display: flex;
    background-color: #fff;
    justify-content: center;

    text-align: center;

    > h4 {
      padding: 0.5em 1em;
      font-size: 12px;
      right: -0.5em;
      border-radius: 12px;
      background-color: green;
      color: #fff;
      border: 5px double gray;
      position: absolute;
      top: -1em;
      margin-bottom: 0.5em;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    }
    form {
      display: flex;
      flex-direction: column;
      h4 {
        padding: 0.5em 1em;
        border-bottom: 1px solid #d1d1d1;

        margin:0 1em;
        text-align: left;
      }
      .firstSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin: 1em;
        gap: 1em;
      }
      .firstSection div:nth-of-type(1) {
        display: flex;
        width: 100%;
        align-items: flex-start;
        gap: 1em;
        flex-direction: column;
        justify-content: center;
      }
      .firstSection div:nth-of-type(1) > img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: 5px double gray;
        object-fit: cover;
        font-size: 1px;
        text-align: center;
      }

      .firstSection div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        border: 1px solid rgb(183, 183, 183);
        border-radius: 15px;
        padding: 0.5em;
        label {
          display: flex;
          justify-content: center;
          width: auto;
          width: 150px;
          padding: 1em;
        }
      }
      /* second section */
      .secondSection {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 1em;
        flex-wrap: wrap;
        gap: 1em;
      }

      /* third section */
      .thirdSection {
        gap: 1em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 1em;
        flex-wrap: wrap;
        width: 100%;
        white-space: no-wrap;

        input {
          border: 1px solid #d1d1d1;
        }
      }
      .invalidCode {
        padding: 0 1em;
        text-align: left;
        width: 100%;
        color: red;
        font-size: 12px;
      }
      /* fourthsection buttons */
      .fourthSection {
        padding: 1em;
        grid-column: 3;
        gap: 1em;
       
        .submitButton {
          background-color: green;
          font-weight: 800;
          color: #fff;
        }
      }
      .termsAcceptence{
        font-size: 10px;
      }
    }
  }

  .main section:first-of-type {
    position: relative;
    h2 {
      text-align: left;
      width: 100%;
      padding: 1em;
      margin:0 1em;

      border-bottom: 1px solid #d1d1d1;

    }
    img {
      position: absolute;
      inset: 0;
      width: 100%;
      display: none;
      height: 100%;
      z-index: -1;
    }
    > .leftInformation {
      padding: 1em;
      margin-top: 3em;
      display: flex;
      flex-direction: column;
      color: #242629;
    }

    .leftContent {
      padding: 1em;
      text-align: left;
      .leftAuthor {
        margin-bottom: 0.5em;
      }
      .leftTitle {
        font-weight: 800;
        font-size: 2.5em;
        animation-delay: 0.2s;
        color: green;
      }
      .leftTopic {
        font-weight: 800;
        font-size: 2.5em;
        margin-bottom: 0.2em;
        animation-delay: 0.3s;
      }
      .leftDescription {
        font-size: 12px;
        animation-delay: 0.4s;
      }
      .leftButtons {
        animation-delay: 0.5s;
      }
    }

    .leftButtons {
      display: flex;
      margin-top: 1em;
      gap: 1em;
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      > button:nth-of-type(1) {
        background-color: green;
        color: #fff;
        border: none;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        gap: 0.5em;
      }
      > button:nth-of-type(2) {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }

/* login panel */
.choices {
  margin-left:0;
  position:relative;
 
}
}

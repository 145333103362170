/* Styles for the glassmorphic background */
.forgotContainer {
  background: url("../../../../../assets/lms.gif") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.glassCard {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2em;
  width: 300px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);

  /* Style for the inputs, button, and links */
  input[type="email"],
  input[type="text"] {
    width: 100%;
    padding: 0.5em;
    margin: 1em 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
}

.getCodeButton,
.authenticateButton {
  width: 100%;
  padding: 0.6em;
  margin-top: 1em;
  border-radius: 5px;
  border: none;
  background-color: greenyellow;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
    color: #fff;
  }
}

.link {
  color: #000;

  max-width: 100px;
  background-color: greenyellow;
  margin-top: 1em;
  display: block;
  text-decoration: none;
  border-radius: 8px;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:.5em;
  white-space: nowrap;
}

.link:hover {
  color: #000;
}

/* For the popup messages */
.popup {
  margin: 0.5em 0;
  color: #ff4d4d;
  font-size: 0.9em;
}

.mailContent {
    padding: 20px;
    background-color: #fff;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    overflow-x: auto; /* Allow horizontal scrolling if needed */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9rem; /* Smaller font size */
    margin-top: 20px;
  }
  
  th, td {
    padding: 8px 12px; /* Smaller padding */
    text-align: left;
    /* font-size: 8px; */
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  tbody tr:hover {
    background-color: #f9f9f9;
  }
  
  .closeButton {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;

    cursor: pointer;
    margin:0;
    margin-top: 20px;

  }
  
  .closeButton:hover {
    background-color: #d32f2f;
  }
  
  /* Add responsiveness */
  @media (max-width: 768px) {
    table {
      font-size: 0.8rem; /* Further reduce font size on smaller screens */
    }
  
    th, td {
      padding: 6px 10px; /* Smaller padding for mobile */
    }
  }
  
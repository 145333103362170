.header {
  grid-area: header;
  position:relative;
}
.sidebar { 
  grid-area: sidebar;
}
.main { 
  grid-area: main;
  margin-top: 60px; 

  padding: 1em;
  background-color: #fff;
  height: calc(100vh - 60px);
  overflow-y: auto;
  text-align: center;
  padding-bottom:80px;
}
.mainContent {
  /* margin-left: 250px; */
  margin-left:0;
  padding: 2em;
  background-color: #fff;
  height: calc(100vh - 60px);
  overflow-y: auto;
}

.welcome {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 1em;
}

.content {
  font-size: 1.2em;
  color: #333;
}

@media (max-width: 768px) {
  .mainContent {
    margin-left: 0;
    padding: 1em;
  }
.main{
  /* margin-left: 15px; */
  margin-left: 0;

}
  .welcome {
    text-align: center;
  }
}

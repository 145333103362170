.container {
  display: flex;
  flex-direction: column;
  margin-top: 80px; 
  position: sticky;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;

}

.toggleContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #3f51b5;
  border-radius:5px;
  top: 0;
  padding:0;
  margin-bottom:.5em;
  z-index: 2; 
}

.toggle {
  cursor: pointer;
  padding: 0.3em .48em;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  transition: background 0.3s ease;
  font-size: 25px;
  margin:0;
}

.toggle:hover {
  background: rgba(255, 255, 255, 0.2);
}

.sidebar {
  width: 300px;
  background-color: #3f51b5;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 1em;
  border: 1px solid rgb(200, 200, 200);
  border-top: none;
  border-left: none;
  transition: width 0.3s ease;
  border-bottom-right-radius: 8px;
  position: sticky;
  height: auto;
  z-index: 1; 
  
}

.collapsed {
  width: 100px;
}

.nav {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
}

.navItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  margin-bottom: 1em;
  font-size: 22px;
  font-weight:600;
  gap:.5em;
  border:none !important;

  background-color: transparent;
  transition: color 0.3s ease, transform 0.3s ease; 
  padding: 0.5em 0.5em;
  width: 100%;
}

.navItem:hover {
  color: #ffc107; 
  transform: scale(1.1); 
}

.icon {
  margin-right: 10px;
  font-size:25px;
}

.text {
  white-space: nowrap;
}

.collapsed .icon {
  margin-right: 0;
}

.collapsed .navItem {
  justify-content: center;
}

.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: row;
    margin-top: 60px; 
    position: relative;
    overflow: hidden; 
    
  }
  
  .toggleContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #3f51b5;
    border-radius: 5px;
    z-index: 2; 
    display:none;
  }
  
  .toggle {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    transition: background 0.3s ease;
    font-size: 0.8em;
  }
  
  .toggle:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .sidebar {
    height:auto;
    width:100%;
    border: 1px solid rgb(200, 200, 200);
    border-top: none;
    border-left: none;
    
    border-bottom: none;
    transition: width 0.3s ease;
    position: fixed;
    bottom:0;
    left:0;
    z-index: 2; 
  }
  
  .collapsed {
    width: 20vw;
    
  }
  
  .nav {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    width:100%;
  }
  
  .navItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    border:none !important;
    margin-bottom: 1em;
    font-size: 0.8em;
    transition: color 0.3s ease, transform 0.3s ease;
    padding: 0.5em;
    width: 100%;
  }
  
  .navItem:hover {
    color: #ffc107; 
    transform: scale(1.1);
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .text {
    white-space: nowrap;
    display:none;

  }
  
  .collapsed .icon {
    margin-right: 0;
  }
  
  .collapsed .navItem {
    justify-content: center;
  }
  
  .active {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }
}

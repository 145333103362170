.parent {
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  padding:1em;;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  } 
}

.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5em;
  padding: 0 1em;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}
.col {
  padding: 0.5em;
  border: 1px solid #d2d2d2;
  /* background-color: #ffffff; */
  border-radius: 25px;
  cursor: pointer;
  transition: 0.2s;
  border: 1px solid rgb(200, 200, 200);
}
.col:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.template {
  width: 100%;
  min-height: 200px;
  border-radius: 18px 25px 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border:5px double gray;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
  .subject{
    font-size: 25px;
    color: gray;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: .5em;
    position: absolute;
  }

  .logoIcon{
    font-size: 30px;
    color: gray;
    font-weight: 800;
    display: flex;
    align-items: center;
    gap: .5em;
    position: absolute;
    bottom:10px;
  }
  .partipantsButton,
  .announceButton {
    position: absolute;
    padding: 0.5em 0.5em 0.5em 1em;
    border-radius: 5px 25px 5px 25px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    top: -5px;
    font-size: 14px !important;
    color:#d2d2d2;
    right: -5px;
    border: 5px double gray !important;
    font-weight: 800;

    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  .partipantsButton{
    border-radius: 5px 5px 5px 25px;
    top:40px;
    width:70px;
  }
}

.cardImg {
  width: calc(100%);
  height: 150px;
}
.cardBody {
  display: flex;
  flex-direction: column;
  position: relative;
  span {
    display: flex;
    gap: 1em;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 1em;
  }
}
.cardTitle {
  font-size: 20px;
  text-align: left;
  width: 100%;
  color: #d2d2d2;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: .5em;
}
.cardText {
  font-size: 16px;
  font-weight: 800;
  color: #d2d2d2;
  display: flex;
  align-items: center;

  gap:.5em;
  .clipboardButton{
    border-radius: 15px;
    padding:0;
    bottom:-5px;
    display: flex;
    width:auto;
    align-items: center;
    color:#d2d2d2;

    right:0;
    background-color: transparent;
    &:hover{
        background-color: transparent;
        scale:1.1;
        color:#d2d2d2;
    }
}
}

.notification{
    font-size: 10px;
    white-space: no-wrap;
    text-overflow: ellipsis;
    background-color: #fff;
    color:gray;
    padding:.5em;
    border-radius:5px 5px 5px 5px;
}


.menuToggler {
  font-size: 20px;
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #fff;
  border-radius: 25px;
  display: grid;
  place-content: center;
  transition: 0.2s;
  padding:0.3em 0;
  font-size: 25px;
  /* border: 5px double gray; */
  &:hover {
    scale: 1.04;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  }
}

.buttonContainer {
  position: absolute;
  top: 30px;
  left: 0;
  display: flex;
  padding: 0.5em;
  gap:1em;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left:.5em;
  top:3em;
  padding:0;
  /* border-right: 5px double gray; */
  border-radius: 25px;

  button {
    background-color: transparent;
    color: gray;
    font-size: 20px;
    padding: 0;
    /* padding: 0.5em; */
    margin:0;
    &:hover {
      color: #000;
    }
  }
}



@media (max-width: 760px) {
  .parent {
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    padding-bottom:5em;
  }
  .container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    width: 100%;
    padding:2em;
    box-sizing: border-box;
  }
  .col {
    padding: 0.5em;
    border: 1px solid #a6a6a6;
    background-color: #ffffff;
  }

  .card {
  }
  .cardImg {
    width: calc(100%);
    height: 150px;
  }
  .cardBody {
    display: flex;
    flex-direction: column;
  }
  .cardTitle {
    font-size: 20px;
    margin: 0.5em;
    min-height: 40px;
  }

  .cardText {
    font-size: 18px;
    font-weight: 800;
    color: #d2d2d2;
    display: flex;
    align-items: center;
  
    gap:.5em;
    .clipboardButton{
      border-radius: inherit;
      padding:0;
      bottom:-10px;
      border:none;
      display: flex;
      width:auto;
      align-items: center;
      font-size: 25px;
      color:#d2d2d2;
  
      right:0;
      background-color: transparent;
      &:hover{
          background-color: transparent;
          scale:1.1;
          color:#d2d2d2;
      }
  }
  }


  .buttonContainer {
    display: flex;
    gap: 0.5em;
    justify-content: flex-end;
    margin-top: 1em;
  }
}

.container{
    width:100% ;
    text-align: center;
    flex-direction: column;
    display: flex;
    position:relative;
    justify-content: center;
    padding:1em;
}

@media (max-width:768px) {

    .container{
        padding:1em;

    }
    
}
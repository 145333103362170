
/* backgrounds */
.contactBackgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1; 
}

.contactBackground {
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* filter: blur(10px); */
  opacity: 0.5;

}

/* sign2psech ontainer */
.sign2speechContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1em;
  gap: 1em;
  place-content: center;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.section1 {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;
  position: relative;
background-color: transparent;
  padding: 1em;
  border-radius: 25px;
  gap: 2em;
}

.translate-parent {
  width: 100%;
  display: flex;
  gap: 0;
  position: relative;
}

.translate-hand-container {
  margin-top: 3em;
  border: none;
  margin-right: 1em;
  position: relative;
  display: inline-block;
  backdrop-filter: blur(10px);
  background: rgba(24, 26, 29, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.8), 0 4px 12px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(34, 139, 34, 0.3);
}

.translate-default-hand {
  position: relative;
  z-index: 1;
  display: inline-block;
  /* opacity: 0.8;  */
  /* filter: blur(1px); */
  /* box-shadow: 0 0 10px rgba(0, 255, 255, 0.8), 0 0 30px rgba(0, 255, 255, 0.6), 
    0 0 50px rgba(0, 255, 255, 0.4);  */
  /* background: linear-gradient(
    45deg,
    rgba(0, 255, 255, 0.6),
    rgba(0, 255, 255, 0.2)
  ); */
  transform: scale(1.05) rotate(5deg);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.translate-default-hand:hover {
  transform: scale(1.1) rotate(0deg); 
  opacity: 1;
}

.messages-container {
  border: none;
  height: 100%;
  padding: 1em;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.speedContainer {
  position: absolute;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  top: 1em;
  color: #d2d2d2;
  z-index: 10;
  cursor: pointer;
  gap: 0.5em;

  > label {
    color: #000;
  }
}


.speed-options {
  cursor: pointer;
}

.section2 {
  border-radius: 15px;
  position: relative;
  border-radius: 15px;
  padding-bottom: 2em;
  display: flex;
  gap: 1em;
  margin-left: 2em;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
}

.info_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #d2d2d2;
  width: 400px;
}

.btnOpenHistory {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
}
.video_container {
  border-radius: 15px;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

video {
  border-radius: inherit;
  border: 1px solid green;

  transform: scaleX(-1);
}
canvas {
  top: 0;
  border-radius: inherit;
  left: 0;
  position: absolute;
  transform: scaleX(-1);
  border: 1px solid green;
}

.configuration_container {
  text-align: center;
  display: flex;
  gap: 0.2em;
  align-items: center;
  justify-content: center;
  margin: 0.5em;
  font-size: 20px;
  #select {
    cursor: pointer;
  }
}

.recognition-buttons-container {
  display: flex;
  gap: 0.3em;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.send_message {
  border: none;
  background-color: green;
  color: #fff;
  &:hover {
    background-color: green;
  }
}
.send-phrase-error {
  font-size: 10px;
  color: rgb(237, 70, 70);
}
.send-phrase-success {
  font-size: 10px;
  color: rgb(70, 237, 123);
}

/* informations */

#letter {
  margin-top: 1em;
  font-size: 50px;
  font-weight: 800;
  color: rgb(92, 128, 38);
  min-height: 80px;
}

#phrase {
  font-size: 12px;
  max-width: 400px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  padding: 0.5em 2em;
  margin-bottom: 1em;
  text-align: left;
  text-transform: lowercase;
  text-transform: capitalize;
  min-height: 300px;
}

#word {
  font-size: 20px;
  min-height: 30px;
  max-width: 300px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  border: 1px solid #d2d2d2;
  padding: 0.5em;
  margin-bottom: 1em;
}

#suggestionsContainer {
  min-height: 30px;
  max-height: 300px;
  overflow-y: auto;
  background-color: transparent;
  max-width: 300px;
  border: 1px solid #d2d2d2;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  padding: 0.5em;
  bottom: 0;
  position: absolute;
  margin: 1em 0;
  .suggestion {
    min-width: 50px;
    border-radius: 15px;
    background-color: greenyellow;
    backdrop-filter: blur(10px);
    color: #000;
    font-weight: 600;
    padding: 0.2em 0.5em;
    cursor: pointer;
    font-size: 10px;
    transition: 0.2s;
    &:hover {
      background-color: green;
      color:#fff;
      scale: 1.05;
    }
  }
}

/*
replies history
*/
.phrase_container {
  flex-direction: column;
  border-right: 1px solid #d2d2d2;
  width: 400px;
  margin-top: 3em;
  text-align: left;
  max-height: 500px;
  overflow-y: auto;
}
.phrase {
  max-width: 300px;
  font-size: 10px;
  border: 1px solid gray;
  border: 1px solid #d2d2d2;
  display: flex;
  gap: 0.5em;
  align-items: center;
  position: relative;
  padding: 4px 8px;
  margin-bottom: 0.2em;
}
.resetHistory {
  font-size: 10px;
  padding: 4px 8px;
  background-color: green;
  border:none;
  color:#fff;
  border-radius: 5px;
  &:hover{
  background-color: green;
  color:#fff;

  }
}

.btnResetPhrase {
  font-size: 10px;
  padding: 4px 8px;
  background-color: green;
  margin-top: 1em;
  border:none;
  border-radius: 5px;
  color:#fff;
  &:hover{
  background-color: green;
  color:#fff;

  }
}

.btnRetrieve {
  background-color: green;
  position: absolute;
  right: -1em;
  top: 0;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 10px;
  &:hover {
    background-color: greenyellow;
    color: #000;
  }
}
/*
previous messages
*/

/* previous messages from teachers */
.previous-messages-container {
  width: 100%;
  position: relative;
  display: flex;
  gap: 1em;
  max-height: 250px;
  height: 250px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.3);
  border-radius: 12px;
  overflow-y: auto;
  margin: 1em 0;
}

.no-message-span {
  color: gray;
  font-size: 10px;
  margin: 0;
  white-space: nowrap; /* Prevents text wrapping */
}

.previous-messages {
  scroll-behavior: smooth;
  .previous-messages-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.2em;
    font-weight: 600;
    font-size: 20px;
    padding: 0;
    margin: 0;
    .btnResetMessages {
      font-size: 10px;
      padding: 4px 8px;
    }
  }
  .messages-span {
    font-size: 10px;
    border: 1px solid gray;
    border: 1px solid #d2d2d2;
    display: flex;
    gap: 0.5em;
    align-items: center;
    position: relative;
    padding: 4px 8px;
    padding-right: 50px;
    margin-bottom: 0.2em;
    .messages-title {
      min-width: 80px;
      white-space: no-wrap;
    }
    .message-replay {
      background-color: green;
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      color: #fff;
      padding: 4px 8px;
      border-radius: 3px;
      font-size: 10px;
      &:hover {
        background-color: greenyellow;
        color: #000;
      }
    }
  }
}

.previous-messages::-webkit-scrollbar {
  width: 12px;
  background-color: #f0f0f0;
}

.previous-messages::-webkit-scrollbar-thumb {
  background-color: greenyellow;
  border-radius: 6px;
  border: 3px solid #f0f0f0;
}

.previous-messages::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.previous-messages::-webkit-scrollbar-track {
  background-color: #e0e0e0;
  border-radius: 6px;
}

.previous-messages::-webkit-scrollbar-corner {
  background-color: #e0e0e0;
}

.previous-messages::-webkit-scrollbar-button {
  background-color: #ccc;
  border-radius: 6px;
  width: 12px;
  height: 12px;
}

button {
  border-radius: 8px;
  border: 1px solid #d2d2d2;
  cursor: pointer;
  font-size: 12px;
  margin-bottom: 1em;
  transition: 0.2s;
  &:hover {
    background-color: transparent;
  }
}
.messagesTogglerBtn {
  position: relative;
  top: 1em;
  transform: translateY(-5px);
  right: 1em;
  white-space: nowrap;
  display: block;
  border-radius: 5px;
  border:none;
  background-color: green;
  color: #fff;
  &:hover {
    background-color: green;
    color: #fff;
  }
}
@media (max-width: 768px) {
  .video_container {
    border-radius: 15px;
    text-align: center;
    position: relative;
    box-shadow: none;
  }
  .section1 {
  background-color: #f8f4f4;
    border-radius: 0;
  }

  .section2 {
    border-radius: 15px;
    position: relative;
    border-radius: 15px;
    padding-bottom: 4em;
    display: flex;
    gap: 0;
    padding: 0;
    margin-left: 0;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .sign2speechContainer {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0;
    box-sizing: border-box;
    gap: 1em;
    place-content: center;
    font-family: Georgia, "Times New Roman", Times, serif;
  }
  .messagesTogglerBtn {
    position: fixed;
    top: 1em;
    right: 1em;
  border:none;
    display: block;
    background-color: green;
    color: #fff;
    &:hover {
      background-color: green;
      color: #fff;
    }
  }
  .messages-container {
    border: 1px solid gray;
    height: 100%;
    position: absolute;
    z-index: 10;
    left: 0;

    padding: 1em;
  }
  /* informations */

  #letter {
    margin-top: 4em;
    font-size: 25px;
    font-weight: 800;
    color: rgb(92, 128, 38);
    min-height: 80px;
  }

  #phrase {
    font-size: 12px;
    max-width: 400px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    padding: 0.5em 1em;
    margin-bottom: 1em;
    text-align: left;
    text-transform: lowercase;
    text-transform: capitalize;
    min-height: 300px;
  }

  #word {
    font-size: 14px;
    min-height: 30px;
    max-width: 200px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;
    border: 1px solid #d2d2d2;
    padding: 0.5em;
    margin-bottom: 1em;
  }
  .preview-message{
    border:1px solid #d2d2d2;
    padding:.2em;
  }

  #suggestionsContainer {
    min-height:0;
    max-height: 150px;
    overflow-y: auto;
    z-index: 20;
    background-color: transparent;
    min-width: 100vw;
    max-width: 100%;
    border: 1px solid #d2d2d2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.5em;
    padding: 0.5em;
    bottom: 0;
    left: 0;
    position: fixed;
    margin: 0 0;
    margin-bottom: 0;
    .suggestion {
      min-width: 50px;
      max-height: 20px;
      min-height: 20px;
      border-radius: 15px;
      background-color: greenyellow;
      backdrop-filter: blur(10px);
      color: #000;
      font-weight: 600;
      padding: 0.2em 0.5em;
      cursor: pointer;
      font-size: 10px;
      transition: 0.2s;
      &:hover {
        background-color: green;
        color: #fff;
        scale: 1.05;
      }
    }
  }

  .previous-messages {
    scroll-behavior: smooth;
    .previous-messages-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.2em;
      font-weight: 600;
      font-size: 14px;
      padding: 0;
      margin: 0;
      .btnResetMessages {
        font-size: 10px;
        padding: 4px 8px;
      }
    }
    .messages-span {
      font-size: 10px;
      border: 1px solid gray;
      border: 1px solid #d2d2d2;
      display: flex;
      gap: 0.5em;
      align-items: center;
      position: relative;
      padding: 4px 8px;
      padding-right: 50px;
      margin-bottom: 0.2em;
      .messages-title {
        min-width: 80px;
        white-space: no-wrap;
      }
      .message-replay {
        background-color: green;
        position: absolute;
        z-index: 2;
        right: 0;
        top: 0;
        color: #fff;
        padding: 4px 8px;
        border-radius: 3px;
        font-size: 10px;
        &:hover {
          background-color: greenyellow;
          color: #000;
        }
      }
    }
  }

  /*
replies history
*/
.phrase_container {
  flex-direction: column;
  border-right: 1px solid #d2d2d2;
  width: 200px;
  margin-top: 3em;
  text-align: left;
  max-height: 500px;
  overflow-y: auto;
  padding:.5em;
}
.phrase {
  max-width: 200px;
  font-size: 10px;
  border: 1px solid gray;
  border: 1px solid #d2d2d2;
  display: flex;
  gap: 0.5em;
  cursor: pointer;
  align-items: center;
  position: relative;
  padding: 4px 8px;
  cursor: pointer;
  margin-bottom: 0.2em;
  font-size: 10px;
  padding-right:2em;
}
.resetHistory {
  font-size: 10px;
  padding: 4px 8px;
}

.btnResetPhrase {
  font-size: 10px;
  padding: 4px 8px;
  margin-top: 1em;
}

.btnRetrieve {
  background-color: green;
  position: absolute;
  right: -1em;
  display: none;
  top: 0;
  color: #fff;
  padding: 4px 8px;
  border-radius: 3px;
  font-size: 10px;
  &:hover {
    background-color: greenyellow;
    color: #000;
  }
}
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

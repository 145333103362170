/* Styles for the glassmorphic background */
.forgotContainer {
  /* background: url("../../../../../assets/whity.jpg") no-repeat center center fixed; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: .5; */
  backdrop-filter: blur(5px);
  /* background-color: #242629; */
  height: 100vh;
  width: 100%;

  .inputContainer {
    margin: 0;
    padding: 0;
    > label {
      transform: translateY(12px);
      margin: 0;
      padding: 0;
      font-weight: 600;
    }
  }
}
.forgotPasswordTitleHeader{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding:.3em;
  border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #242629;
  margin-bottom: 1em;

}
.absoluteBackground {
  position: fixed;
  object-fit: cover;
  top: 0;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  /* filter: blur(10px); */
  opacity: 0.5;
}

.glassCard {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 2em;
  width: 300px;
  max-width: 90%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);

  /* Style for the inputs, button, and links */
  input[type="email"],
  input[type="text"] {
    width: 100%;
    padding: 0.5em;
    margin: 1em 0;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
  }
}

.forgotButtonContainer {
  display: flex;
  gap: 0.5em;
}

.getCodeButton,
.authenticateButton {
  width: 100%;
  padding: 0.6em;
  border-radius: 5px;
  border: none;
  background-color: green;
  max-height: 45px;
  min-height: 45px;
  margin: 0;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: greenyellow;
    color: #242629;
  }
}

.authenticateButton {
  font-size: 16px;
}

.link {
  color: #fff;
  font-size: 14px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
  background-color: transparent;
  margin-top: 1em;
  border-radius: 8px;
  max-height: 45px;
  transition: color 0.3s ease;
}

.link:hover {
  color: #000;
}

/* For the popup messages */
.popup {
  margin: 0.5em 0;
  color: #ff4d4d;
  font-size: 0.9em;
}

.container{
  /* padding:1em; */
  width:100%;
  animation: fadeIn 0.5s ease-in-out;
  .tableContainer {
    width: 100%;
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th, .table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .table tr {
    cursor: pointer;
    transition: .2s linear;
  }
  
  .table tr.viewedRow {
    background-color: #f0f0f0;
  }
  
  .table tr:hover {
    background-color: #e0e0e0;
  }
  
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
  }
  

.annParent{
  width:100%;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  gap:.3em;
 padding:0 1em;
 
.mainHeader{
  display:flex;
  align-items: center;
  width:100%;
  padding:.5em;
  gap:.5em;
  margin-bottom:.5em;
  border-bottom:1px solid rgb(226, 226, 226);
  input{
    width:20px;
    height:20px;
    cursor:pointer;
  }
  label{
    white-space: nowrap;
    font-size:14px;
  }
}
}


.deleteButton{
  font-size:20px;
  &:hover{
    color:#000;
    background-color: gray;
  }
}

.card{
  width:100%;
  /* border-bottom: 1px solid rgb(219, 219, 219); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor:pointer;
  position:relative;
  padding:.3em;
  transition: .2s;
}

.card:hover{
  border:1px solid #d2d2d2;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
  border-radius: 8px;
  padding:.5em;
}

.cardBody{
  display: flex;
  gap:1em;
  justify-content: flex-start;
  align-items: center;
  padding-right:5em;

}

.annType{
  font-weight: 800;
}

.annType, .annTitle, .annContent,.annMarkAsUnread,.created_at{
  font-size: 14px;
  width: 170px;
  display: block; /* Use block or inline-block instead of flex */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Displays ellipsis when text overflows */
  text-align: left;
}
.annMarkAsUnread{
  font-size: 25px;
  >p{
    &:hover{
      color:green;
      box-shadow: 0 4px 4px rgba(0,0,0,0.4);
    }
  }
 
}

.annExpiry{
  font-size:12px;
  width: 180px;
  white-space: nowrap; /* Prevents text from wrapping */

}

.buttonContainer{
  display:flex;
  gap:0;
  position:absolute;
  right:0;
}

@media  (max-width:768px) {
  .container{
    padding:0;
    .table th{
      font-size: 12px;
    }
    .table .hiddenTitle{
      display: none;
    }
    
  }
  
.annType, .annTitle, .annContent,.annMarkAsUnread {
  font-size: 14px;
  max-width: 70px;
  display: block; /* Use block or inline-block instead of flex */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Displays ellipsis when text overflows */
  text-align: left;
}
.annExpiry{
  display:none;
}
.annTitle{
  display: none;
}
.annContent{
  max-width: 130px;

}
.created_at{
  display: none;
}

.annMarkAsUnread{
  font-size: 30px;
  >p{
    &:hover{
      color:green;
      box-shadow: 0 4px 4px rgba(0,0,0,0.4);
    }
  }
 
}
}
.parent {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  padding: 1em 0;
  padding-bottom: 3em;
}

.parent p {
  font-size: 15px;
  width: 100%;
  text-align: left;
  padding: 1em;
}

.container {
  display: flex;
  height: auto;
  width: 100%;
  align-items: center;
  gap: 1em;
  justify-content: center;
  flex-wrap: wrap;
}
.card {
  /* padding:.5em; */
  border-radius: 12px;
  border: 1px solid #d2d2d2;
  background-color: white;
  min-height: 150px;
  min-width: 150px;
  display: grid;
  place-items: center;
  transition: 0.2s linear;
  margin: 0.5em 0;
  padding: 0.5em;
}
.card:hover {
  scale: 1.1;
}

.pendingContainer {
  transform: translateY(-50px);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  span {
    color: #000;
    font-size: 40px;
    font-weight: 800;
  }
  span.active {
    color: rgb(255, 83, 83);
    font-size: 40px;
    font-weight: 800;
  }
}


.absoluteBackground {
  position: fixed;
  object-fit: cover;
  top: 80px;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  z-index: -1;
  /* filter: blur(10px); */
  opacity: 0.5;
}

/* header footer */
.footer {
  padding: 1em;
  background-color: #242629;
  width: 100%;
  height: 70px;
}

.header {
  padding: 0.5em 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  background-color: #242629;
  text-align: left;
  top:0;
  left:0;
  position:sticky;
  > span {
    display: flex;
    gap: 1em;
    align-items: center;
    .title {
      color: #eaeaea;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 30px;
    }
    .logo {
      object-fit: cover;
      border-radius: 50%;
      width: 75px;
      height: 75px;
    }
  }
  .backButton {
    background-color: transparent;
    font-size: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;

    color: #000;
    width: 150px;
    margin: 1em;
    background-color: #eaeaea;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      scale: 1.03;
      background-color: #fff;
    }
  }
}

.icon {
  font-size: 50px;
}

/* buttons */
.button {
  margin-top: 1em;
  background-color: transparent;
  font-size: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-weight: 800;
  color: rgb(255, 83, 83);
  padding: 8px 16px;
  border: 1px solid rgba(0, 255, 0, 0.2); /* Light green border */
  background: rgba(255, 255, 255, 0.3);

  background-image: 
    linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px; 
  backdrop-filter: blur(10px);
  &:hover {
    background-color: #fff;
  }
}

.button:hover {
  scale: 1.05;
}
.cancel {
  width: 100%;
  padding: 1em;
}


@media (max-width:768px) {
  /* Header and Footer settings */
  .header,
  .footer {
    background-color: #242629;
    padding: .5em;
    width: 100%;
    overflow: hidden;
    > span {
      display: flex;
      gap: .5em;
      align-items: center;
      .title {
        color: #eaeaea;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        font-weight: 800;
        letter-spacing: 2px;
        font-size: 25px;
      }
      .logo {
        object-fit: cover;
        border-radius: 50%;
        /* border: 5px double gray; */
        width: 75px;
        height: 75px;
      }
    }

    .backButton {
      background-color: transparent;
      font-size: 14px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
  
      width:auto;
      margin: 0;
      border-radius: 8px;
      padding: 8px ;
      cursor: pointer;
      &:hover {
        scale: 1.03;
        background-color: #fff;
      }
    }
  }
  
}
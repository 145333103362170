.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .title {
    font-size: 2.5rem;
    color: #dc3545;
    margin-bottom: 1rem;
  }
  
  .message {
    font-size: 1.25rem;
    color: #6c757d;
    margin-bottom: 2rem;
  }
  
  .button {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:.5em;
    cursor: pointer;
    text-decoration: none;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
.mailParent{

    position:fixed;
    display: grid;
    place-content: center;
    width:100%;
    height:100vh;
    top:0;
    left:0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1005;

}
.mailContainer{
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width:100%;
    max-height:auto;
    overflow-y: auto;
    z-index: 60;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);

}
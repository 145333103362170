.popupContainer {
  display: flex;
  position: fixed;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  justify-content: center;
  align-items: center;
  transition: 1s linear;
  transform: perspective(1000px);
  right: 0;
  top:0;
  padding: .2em;
  overflow: hidden;
  border-radius: 5px 5px 5px 5px;

  z-index:99999;
  min-width: 200px;
  max-width: 100%;
  border:5px double gray;

}

.iconContainer {
  position: relative;
  flex: 1;
  transform-style: preserve-3d;
  display: grid;
  place-items: center;
  min-height: 50px;
  width: 50px;
}

.icon {
  position: absolute;
  font-size: 20px;
  transition: 1s linear;
  z-index: 4;
  transform: rotateX(0deg) rotateY(0deg);
}

.show {
  display:flex;
}

.hide{
  display:none;
}

.animated {
  animation: spin3d 1s ease-in-out;
}

.msgContainer {
  position: relative;
  flex: 2;
}
.msg {
  font-size: 14px;
  color:#242629;
  white-space: nowrap;
}

@keyframes spin3d {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }

  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@media (max-width: 760px) {
  .popupContainer {
    max-width: 100%;
    padding: 0.5em;
    border-radius: 5px 5px 5px 30px;
    min-width: 200px;
  }

  .iconContainer {
    min-height: 50px;
    width: 50px;
  }

  .icon {
    font-size: 30px;
    z-index: 4;
  }

  .show {
    display:flex;
  }

  .hide{
    display:none;
  }

  .animated {
    animation: spin3d 1s ease-in-out;
  }

  .msgContainer {
    position: relative;
    flex: 2;
  }
  .msg {
    font-size: 15px;
  }

  @keyframes spin3d {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }

    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }

    100% {
      transform: rotateX(360deg) rotateY(360deg);
    }
  }
}

.modalContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 1em;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.4);
  padding: 2em;
  border-radius: 15px;
  flex: 1;
  z-index: 2;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
  background: rgba(255, 255, 255, 0.3);
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px;
  backdrop-filter: blur(10px);
  height: auto;
  min-height: auto;
}
.modalContainer label {
  text-align: left;
  font-size: 12px;
  font-weight: 600;
  input[type="checkbox"] {
    max-width: 15px;
    text-align: left;
  }
}

.modalContainer input {
  border: 1px solid rgb(199, 199, 199);
  outline: none;
  border-radius: 12px;
}

.modalContainer input:focus {
  border: 1px solid #575555;
}
.title {
  width: 100%;
  text-align: left;
  border-radius: 5px;
  font-weight: 800;
  margin-bottom: 0.2em;
  font-size: 25px;
}

.sub {
  font-size: 12px;
  font-weight: normal;
  color: #242629;
  margin-bottom: 1em;
}

.headerContainer {
  width: 100%;
}

.headerContainer > label {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
  white-space: nowrap;
  justify-content: flex-start;
 
}
.wrongPassword {
  font-size: 8px;
  color: red;
}

.buttonsContainer {
  width: 100%;
  text-align: center;
}
.button {
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  border-radius: 5;
  font-weight: 600;
  background: green;
  color: #fff;
  cursor: pointer;
  transition: 0.2s linear;
  margin: 0.5em;
  &:hover {
    background-color: green;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes slideIn {
    0% {
      opacity: 0;
      transform: translateX(200px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .slideIn {
    animation: slideIn 0.6s ease-in-out;
  }
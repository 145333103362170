.parent{
    width:100%;
    box-sizing: border-box;
    height:auto;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height:100vh;
    justify-content: flex-start;
    padding:1em 1em;
    padding-bottom:3em;
}

@keyframes zoomInAnimation {
    0% {
      transform: scale(0.8);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  .zoomIn {
    animation: zoomInAnimation 0.6s ease-in-out;
  }
  

.parent p {
    font-size: 15px;
    width: 100%;
    text-align: left;
    padding: 1em;
  }
  

.card{
    /* padding:.5em; */
    border-radius: 12px;
    border:1px solid #d2d2d2;
    background-color: white;
    min-height: 150px;
    min-width:150px;
    display: grid;
    place-items:center;
    transition:.2s linear;
    margin: .5em 0;

    padding:.5em;

}
.card:hover{
    scale:1.1;
}
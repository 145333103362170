/* mono.module.css */

.grandParent {
  position: relative;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  border-top-left-radius: 5px;
  border: 1px solid rgb(200, 200, 200);
  border-right: none;
  border-top:none;
}

.closeIcon {
  cursor: pointer;
  font-size: 1.5rem;
  color: white; /* Adjust color as needed */
  transition: .2s linear;
}
.closeIcon:hover {
  scale: 1.1;
}

.header {
  margin: 0; 
}

.subHeader {
  display: none;
}

.title {
  font-size: 10px;
  margin: 0.5em;
  text-align: left;
}

.spans {
  display: flex;
}

.span {
  height: 25px;
  width: 10px;
}

.monoParent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  gap: 10px;
  padding: 1em;
  padding-top: none;
  border: 1px solid black;
  position: absolute;
  height: 50vh;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  border-bottom-left-radius:5px;
  border: 1px solid rgb(200, 200, 200);
  border-right: none;
  border-top:none;
border-bottom-right-radius: 5px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}
.monoParent::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
.monoContainer {
  cursor: pointer;
  transition: .2s linear;
}
.monoContainer:hover {
  scale: 1.05;
}
.monoContainer {
  text-align: left;
}

@media (max-width: 760px) {
  .monoParent {
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    gap: 20px;
    padding: 1m 1em;
  }
}

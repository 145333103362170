.header{
    height:auto;
    padding:.5em 1em;
    display: flex;
    align-items: center;
    justify-content:space-between;
    position:fixed;
    top:0;
    color:#fff;
    width:100%;
    background-color: #ffffff;
    background-color: #242629;

    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 2;

    .logoInfo{
        display: flex;
        align-items: center;
        gap:1em;
        .logo{ 
            border-radius: 50%;
            max-width:70px;
            min-width:70px;
            max-height:70px;
            object-fit: cover;
        }
        .title{
            font-size:30px;
            font-weight: 800;
            letter-spacing: 2px;
            color:#eaeaea;
        }
    }
    
}
.logo{
    font-size:35px;
    letter-spacing: 5px;
    font-weight: 800;
}
.toggler{
    padding:.5em;
    border-radius: 50px;
    border:2px solid gray;
    min-width: 100px;
    min-height: 35px;
    position:relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    transition: .2s linear;
    p{
        font-size:25px;
        position:absolute;
    }
}

.dark{
    right:5px;
}

.light{
    left:5px;
}
.buttonContainer{
    padding:0 1em;
    >button{
        margin:0;
    }
}
@media(max-width:768px){
    .header{
        height:auto;
        padding:1em;
        display: flex;
        align-items: center;
        justify-content:space-between;
        position:fixed;
        top:0;
        color:#fff;
        width:100%;
        background-color: #ffffff;
        background-color: #242629;
    
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        z-index: 2;
    
        .logoInfo{
            display: flex;
            align-items: center;
            gap:.5em;
            .logo{
                width:50px;
                min-width:50px;
                min-height:50px;
                height:50px;
                object-fit: cover;
            }
            .title{
                font-size:25px;
                font-weight: 800;
                letter-spacing: 2px;
                color:#eaeaea;
            }
        }
        
    }
}
:root{
    --size:100px;
}

.defaultContainer{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    position:relative;
    gap:.5em;
    min-height: 50vh;
    .loadingImage{
        height: var(--size);
        width: var(--size);
        object-fit: cover;
        max-height:  var(--size);
        max-width:  var(--size);
        border-radius: 50%;
        /* display: none; */
    }
}

.defaultContainer span:first-child{
    font-size:30px;
    color:#242629;
    
}
.defaultContainer span:last-child{
    font-size: 15px;
    color:#242629;
    
}
.container {
  animation: fadeIn 0.5s ease-in-out;
  .topBanner {
    border-radius: 12px;
    margin: 0 2em;
    height: 50vh;
    position: relative;
    display: grid;
    margin-bottom: 1em;
    .topBannerTitle{
      left:1em;
      width: 100%;
float: left;
height:100%;
display: flex;
align-items: flex-end;
justify-content: flex-start
;
padding:1em;
font-size: 40px;
font-weight: 800;
text-align: left;
    }
    .rightBanner {
      right: 2.5em;
      top: -5px;
      position: absolute;
      width: 200px;
      height: 80%;
      border: 5px double gray;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
      background: #fff;
      border-radius: 5px 5px 50% 50%;
      display: grid;
      place-content: center;
      >span{
        font-size: 80px;
        color:gray;
      }
    }
    .rightBanner::after {
      content: "";
      background: #fff;

      right: 210px;
      top: -5px;

      position: absolute;
      width: 15px;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);

      height: 60%;
      border: 5px double gray;
      border-radius: 5px 5px 10px 10px;
    }
    .rightBanner::before {
      content: "";
      right: 250px;
      background: #fff;

      top: -5px;

      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);

      position: absolute;
      width: 15px;
      height: 40%;
      border: 5px double gray;

      border-radius: 5px 5px 10px 10px;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}
.materialsToggler {
  display: none;
}

.modulesContainer {
  width: 100%;
  display: flex;
  gap: 1em;
  padding: 0 1em 0 1em;
  /*
  * left panel
  */
  .leftPanel {
    flex: 1;
    min-height: 100vh;
    overflow-y: auto;
    border-radius: 12px;
    background-color: #e6e6e6;
    /* padding:0 1em; */
    margin-left: 1em;
    .leftHeader {
      width: 100%;
      display: flex;
      font-weight: 800;
      font-size: 20px;
      justify-content: space-between;
      gap: 0.5em;
      padding: 1em;
      > span {
        color: gray;
        font-size: 20px;
        > button {
          color: red;
          float: right;
          margin-left: 1em;
          /* font-weight: 600; */
        }
      }
      > input {
        border-radius: 8px;
        border: none;
        outline: none;
        border: 1px solid rgb(200, 200, 200);
        padding: 8px 16px;
        &:focus {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
      }
    }
    .leftMain {
      margin-top: 0.5em;
      display: grid;
      padding: 1em;
      gap: 0.2em;
      .searchMaterials {
        border-radius: 8px;
        border: none;
        outline: none;
        border: 1px solid rgb(200, 200, 200);
        padding: 8px 16px;
        &:focus {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
      }
    }

    .leftMat {
      cursor: pointer;
      padding: 0.5em;
      border: 1px solid gray;
      background-color: green;
      color: #fff;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      transition: 0.2s;
      position: relative;
      display: grid;
      place-content: center;
      > .leftDelete {
        position: absolute;
        height: 100%;
        right: 0;
        border-radius: 25px 10px 10px 25px;
        border: 5px double gray;
        background-color: #fff;
        color: gray;
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
        }
      }
    }
    .leftMat:hover {
      scale: 1.03;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    }
  }
  /*
  * right panel
  */
  .rightPanel {
    flex: 2;
    max-height: auto;

    background-color: #ffffff;
    border-radius: 12px;
    overflow-y: auto;
    /* padding:0 0; */
    scroll-behavior: smooth;
    .rightHeader {
      width: 100%;
      font-size: 20px;
      color: gray;
      display: flex;
      font-weight: 800;
      justify-content: space-between;
      padding: 1em;
    }
    .rightMain {
      margin-top: 0.5em;
      padding: 0 1em;
      padding-bottom: 4em;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      .card {
        width: 100%;
        display: flex;
        gap: 0.5em;
        align-items: center;
        margin: 0.5em 0;
        min-height: 160px;
        padding: 1em 0;
        position: relative;
        border-radius: 0;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        padding: 1em;
        .video_container {
          /* transform: scaleX(-1) !important; */
          z-index: 25;
          video {
            width: 300px;
            height: 200px;
            object-fit: cover;
            max-height: 200px;
            float: right;
            border: none;
            border-radius: 15px;
            transform: scaleX(-1) !important;
          }
        }

        .infoContainer {
          position: relative;
          width: 300px;
          display: flex;
          gap: 0.5em;
          > img {
            height: auto;
            object-fit: cover;
            width: 170px;
            height: 170px;
            border-radius: 15px;

            border: 1px solid gray;
          }
          .rightDescription {
            color: gray;
            overflow-y: auto;
            max-height: 170px;
            text-align: left;
            min-width: 250px;
            background-color: #fff;
            padding: 1em;
            min-height: 150px;
            font-size: 10px;
            font-size: 16px;
            border-radius: 15px;
          }
        }

        .content {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          width: 100%;
          align-items: flex-start;
        }
        .rightImage {
          position: absolute;
          top: 10px;
          z-index: 6;
          right: 10px;
          font-size: 20px;
          cursor: pointer;
          color: #fff;
        }
        .banner {
          width: 180px;
          position: absolute;
          top: -4px;
          display: grid;
          place-content: center;
          background-color: #fff !important;

          right: 40px;
          height: 200px;
          border-radius: 5px 0 50% 50%;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
          border: 5px double gray;

          .bannerTitle {
            font-weight: 800;
            font-size: 20px;
            color: gray;
            max-width: 180px;
            white-space: wrap;
          }

          /* &::before {
            content: "";
            width: 15px;
            position: absolute;
            top: -4px;
            place-content: center;
            background-color: #fff !important;

            left: -40px;
            height: 125px;
            border-radius: 0 0 15px 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            border: 5px double gray;
          } */

          /* &::after {
            content: "";
            width: 15px;
            position: absolute;
            top: -4px;
            place-content: center;
            background-color: #fff !important;

            left: -75px;
            height: 75px;
            border-radius: 0 0 15px 15px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            border: 5px double gray;
          } */
        }
        .rightButtons {
          position: absolute;
          right: -2px;
          bottom: -2px;
          display: flex;
          align-items: center;
          gap: 0.5em;
          padding: 0 0.5em;
          max-height: 35px;
          border-radius: 15px 5px 5px 5px;
          button {
            font-size: 20px;
            background-color: #fff;
            background-color: transparent;
            padding: 4px 8px;
            transform: translateY(7px);
            color: gray;
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }

  .rightPanel {
    .rightMain {
      .card {
        .videoTitle {
          display: none;
          font-size: 20px;
          color: #d2d2d2;
          font-weight: 800;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .topBanner {
      border-radius: 12px;
      margin: 0 1em;
      height: 30vh;
      .topBannerTitle{
        left:1em;
        width: 100%;
  height:100%;
  padding:.5em 1em;
  font-size: 30px;
  font-weight: 800;
  text-align: left;
      }
      .rightBanner {
        right: 2em;
        top: -5px;
        position: absolute;
        width: 200px;
        height: 65%;
        border: 5px double gray;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        background: #fff;
        border-radius: 5px 5px 50% 50%;
        display: grid;
        place-content: center;
        >span{
          font-size: 60px;
          color:gray;
        }
      }
    }
  }
  .materialsToggler {
    display: block;
  }

  .leftPanel {
    flex: 1;
    z-index: 100;
    margin-top:1em;
    max-height: 70vh;
    min-height: 70vh;
    overflow-y: auto;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #d1d1d1;
    position: absolute;
    width: calc(100% - 7%);
    left: 0;
    .leftHeader {
      width: 100%;
      display: flex;
      font-weight: 800;
      font-size: 20px;
      justify-content: space-between;
      gap: 0.5em;
      padding: 1em;
      > span {
        color: gray;
        font-size: 20px;
      }
    }
    .leftMain {
      margin-top: 0.5em;
      display: grid;
      gap: 0.2em;
      .searchMaterials {
        border-radius: 8px;
        border: none;
        outline: none;
        border: 1px solid rgb(200, 200, 200);
        padding: 8px 16px;
        &:focus {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
      }
    }

    .leftMat {
      cursor: pointer;
      padding: 0.5em;
      border: 1px solid gray;
      background-color: green;
      color: #fff;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      transition: 0.2s;
      position: relative;
      display: grid;
      place-content: center;
      > .leftDelete {
        position: absolute;
        height: 100%;
        right: 0;
        border-radius: 25px 10px 10px 25px;
        border: 5px double gray;
        background-color: #fff;
        color: gray;
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
        }
      }
    }
    .leftMat:hover {
      scale: 1.03;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    }
  }

  .modulesContainer {
    .rightPanel {
      flex: 2;
      max-height: auto;
      padding: 1em 0;
      padding-bottom: 4em;

      background-color: #ffffff;
      border-radius: 12px;
      overflow-y: auto;
      scroll-behavior: smooth;
      .rightMain {
        .card {
          .videoTitle {
            display: block;
            font-size: 20px;
            color: #d2d2d2;
            font-weight: 800;
          }
        }
      }
      .card {
        flex-direction: column;
        border-radius: 10px !important;

        .banner {
          display: none !important;
        }
      }
      .rightHeader {
        width: 100%;
        font-size: 20px;
        color: gray;
        display: flex;
        font-weight: 800;
        justify-content: space-between;
        padding: 1em;
      }
    }
  }

  .rightTitle {
    font-weight: 800;
    display: block;
    font-size: 16px;
    color: gray;
  }
  .infoContainer {
    background-color: #fff;
    display: none !important;
    z-index: 5;
    .rightDescription {
      z-index: 5;

      background-color: #fff;
      width: calc(100% - 300px) !important;
    }
  }

  .video_container {
    /* transform: scaleX(-1) !important; */
    display: flex;
    flex-direction: column;
    video {
      transform: scaleX(-1) !important;
      max-width: calc(100%);
    }
  }

  .rightButtons {
    position: relative !important;
    width: 100%;
    padding-top: 1em;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    gap: 0.5em;
    max-height: 35px;
    border-radius: 15px 5px 5px 5px;
    button {
      font-size: 20px;
      background-color: #fff;

      padding: 4px 8px;
      color: gray;
      border: none;
      margin-top: 1em;
      transform: translateX(10px);
      transform: translateY(0);

      &:hover {
        color: #fff;
      }
    }
  }
}

.sendMailContainer {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
  z-index: 1005;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom:.5em;
  >span{
      font-weight:800;
      color:gray;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap:.5em;
      font-size:20px;
  }
}

.searchContainer{
  >input{
    max-height: 40px;
    font-size:16px;
    border-radius: 15px;
    border:1px solid #d2d2d2;
  }
}

.closeButton {
  cursor: pointer;
  font-weight: 600;
  color: #ff4d4d;
}

.mailForm {
  display: flex;
  padding:0;
  flex-direction: column;
}

.inputGroup {
  margin-bottom: 15px;
  text-align: left;
  >label{
      font-size:14px;
      font-weight: 800;
      color:gray;
  }
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
}

.inputGroup input,
.inputGroup textarea {
  width: 100%;
  padding: .5em;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.recipientList {
  margin-bottom: 15px;
  font-size:12px;
  position:absolute;
  width:100%;
  min-height:100%;
  max-height: 300px;
  overflow-y: auto;

  padding:1em;
  padding-bottom: 5em;;
  top:60px;
  left:0;
  background-color: #fff;
}
.notification {
  margin: 10px 0;
  padding: 10px;
  background-color: #e0f7fa; /* Light cyan background */
  color: #00796b; /* Darker teal text */
  border: 1px solid #00796b; /* Teal border */
  border-radius: 5px;
}

.recipientHeader{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  border-bottom:1px solid gray;
  padding:.5em;
  gap:1em;
  margin-bottom:.5em;
  >input{
      max-width:15px;
      max-height:15px;
  }
}
.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

/* Style for the button to toggle recipient list visibility */
.toggleRecipientsButton {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  max-width:200px;
  min-width: 100px;
  width:130px;
  font-size:12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover{
    background-color: green;
    color:#fff;
  }
}

.checkboxContainer {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.submitButton {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  z-index: 5;
  font-weight: 600;
  font-size: 16px;
  margin:0;
  cursor: pointer;
  &:hover{
    background-color: green;
    color:#fff;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.parent {
  width: 100%;
  box-sizing: border-box;
  min-height: 500px;
  overflow: auto;
  padding:1em;
  padding-bottom: 3em;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    > span {
      display: flex;
      align-items: center;
      gap: 0.5em;
      color: gray;
      font-size: 20px;
      font-weight: 800;
      > p {
        background-color: transparent;
        font-size: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        font-weight: 800;
        color: rgb(15, 137, 37);
        border-radius: 8px;
        background-color: green;
        padding: 8px 16px;
        color: #fff;
      }
    }
  }

  .modalButton {
    background-color: transparent;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(255, 83, 83);
    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
}

.modalContainer{
  width:100%;
  max-height: 500px;
  overflow-y: hidden;
  padding:1em;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

.notif {
  color: green;
  font-size: 16px;
  width: 100%;
  text-align: right;
}

.cardContainer {
  width: 100%;
  margin: 1em 0;
  min-height: 300px;
  overflow-y: auto;
}
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  padding: 0 0.5em;
  padding-bottom: 0.5em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  > .info {
    display: flex;
    font-size: 12px;
    gap: 1em;
  }
  > .buttons {
    display: flex;
    gap: 1em;
    align-items: center;

    span {
      font-size: 12px;
      background-color: green;
      color: #fff;
      border: 1px solid gray;
    }
    span:nth-of-type(2) {
      font-size: 25px;
      padding: 0;
      background-color: transparent;
      color: green;
      border: none;
    }
  }
}

@media (max-width: 768px) {
  .parent {
    width: 100%;
    box-sizing: border-box;
    min-height: 100vh;
    overflow: auto;
    padding-bottom: 3em;
    .header{
      > span{
        font-size: 16px;
      }
    }
  }

  .card {
    font-size: 10px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0.5em 0;
    align-items: center;
    position: relative;
    padding: 0.5em;
    text-align: left;
    border-bottom: 1px solid #d4d4d4;
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .name {
    font-size: 12px;
    min-width: 150px;
  }
  .email {
    font-size: 10px;
  }
  .buttons {
    width: 100%;
    display: flex;
    gap: 0.5em;
    justify-content: flex-end;
  }

  .buttons span:last-child,
  .buttons span:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d1d1d1;
  }
  .buttons span:first-child {
    font-size: 12px;
  }

  .buttons span:last-child {
    border-radius: 5px;
    font-size: 25px;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #888;
  }
  .icon {
    font-size: 25px;
  }
}

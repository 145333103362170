.parent {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding:1em 0;
}
.left {
  font-size: 25px;
  /* padding:1em; */
  font-weight: 800;
  color:gray;
}
.right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  /* padding:1em; */
  >.icon{
    display: grid;
    place-content:center;
    cursor:pointer ;
    color:green;
    font-size: 50px;
    transition: .2s linear;
    &:hover{
        scale: 1.04;
    }
  }
  input{
    border:1px solid gray;
  }

}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
@media (max-width: 760px) {
  .parent {
    justify-content: flex-end;
  }
  .left {
    font-size: 0.5em;
    display: none;
  }
  .right {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}

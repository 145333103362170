.userInformation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .userImage {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .info {
    margin-bottom: 20px;
    text-transform: capitalize;
  }

  .info h2 {
    margin: 0 0 10px;
    font-size: 1.5rem;
    color: #333;
  }

  .info p {
    margin: 5px 0;
    font-size: 1rem;
    text-align: left;
    text-transform: normal;
    color: #555;
  }

  a {
    background: #d3eb38;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }

  a:hover {
    background: #d3eb38;

  }
}

@media (max-width: 768px) {
  .userInformation {
    .card {
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      padding: 20px;
      width:100%;
      max-width: 1124px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

 .card{
    box-sizing: border-box;
    display: grid;
    place-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    min-width:300px;
    position:relative;
    min-height:300px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
 }
.header,.main,.footer{
    width:100%;
    text-align: center;
    padding:1em;
}
 .header{
    border:1px solid #d1d1d1
 }
.main{
    text-align: left;
    font-size:15px;
}
.footer{
    display: flex;
    gap:1em;
    justify-content: center;
    align-items: center;
}
.checkStudentsContainer{
  width:100%;
  padding:1em;
}

.selectContainer{
  display: flex;
  margin-top:1em;
  gap:.5em;
  width:100%;
  select{
    color:gray;
    background-color: #fff;
    border:1px solid #d2d2d2;
    border-radius: 8px;
    max-width: 200px;
    padding: 8px 16px;
    background-color: #fff;
    max-height: 40px;
  
    color: gray;
    font-weight: 800;
    border-radius: 8px;
  }
  option{
    color:gray;
  }
  >span{ 
    >input{
      border:1px solid rgb(200, 200, 200);
      outline:none;
      border-radius: 8px;
      color:gray;
      
    }
  }
}

.parent {
  display: flex;
  width: 100%;
  background-color: #fff;

  box-sizing: border-box;
  align-items: center;
  height: auto;
  overflow-y: auto;
  padding: 0;
  /* border-bottom: 1px solid #d1d1d1; */
  justify-content: space-between;
  > button {
    border: 1px solid gray;
    padding: 1em;
  }
}

.button{
  display: flex;
  gap:.5em;
  align-items: center;
  font-size:16px;
  background-color: green;
  color:#fff;
  padding: 8px 16px;
  font-weight: 800;
  height:45px;

  border-radius: 8px;
}
.button:hover{
  background-color: green;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  color:#fff;
}
.filter {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.searchContainer{
  display: flex;
  align-items:center;
  margin:1em;
  >.searchIcon{
    font-size:25px;
    color:gray;
    cursor: pointer;
  }
}
.search {
  width: 100%;
  padding: 8px 16px;
  border-radius: 15px;
  border: 1px solid #eaeaea;
  font-size: 16px;
}
.search:focus {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

  outline: none;
}

@media (max-width: 768px) {
  .parent {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0.5em;
    gap: 0.5em;
    margin-bottom: 0.5em;
  }
  .filter {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  .search {
    width: 100%;
    border-radius: 5px;
    max-height: 40px;
    border: 1px solid #eaeaea;
  }
  .search:focus {
    outline: none;
  }

  .selectContainer{
    display: flex;
    gap:.5em;
    padding:0;
    width:100%;
    select{
      color:gray;
      background-color: #fff;
      border:1px solid #d2d2d2;
      border-radius: 8px;
      max-width: 60px;
      padding:0 !important;
      background-color: #fff;
      font-size: 10px !important;
      max-height: 40px;
    
      color: gray;
      font-weight: 800;
      border-radius: 8px;
    }
    option{
      color:gray;
      font-size: 10px !important;
    }
    >span{ 
      >input{
        border:1px solid rgb(200, 200, 200);
        outline:none;
        border-radius: 8px;
        color:gray;
        
      }
    }
  }
}

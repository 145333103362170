.parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom:1em; */
  gap:1em;
  .header{
    padding:1em 0;
    >span{
      font-weight: 800;
      font-size: 20px;
      color:gray;
    }
  }
  >span{
    display: flex;
    gap:.5em;
    align-items: center;
  }
}

.compose,.filter{
  display: flex;
  gap:.5em;
  align-items: center;
  font-size:16px;
  background-color: green;
  color:#fff;
  padding: 8px 16px;
  font-weight: 800;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.filter{
  background-color: transparent;
  border:1px solid gray;
  color:#000;
}

.compose:hover{
  background-color: green;
color:#fff;
}
.searchContainer {
  display: flex;
  align-items: center;
  position: relative;
  gap:1em;
  width:100%;
}

.searchIcon {
  cursor: pointer;
  font-size: 25px;
  color:gray;
  padding:.5em;
  border-radius: 15px;
  background-color: transparent;
}

.search {
  width: 0;
  outline:none;
  font-size:16px;
  opacity: 0;
  border: none;
  outline: none;
  transition: width 0.4s ease, opacity 0.3s ease, border-color 0.3s ease;
  padding:8px 16px;
  border-bottom:1px solid gray;

}



.search.expanded {
  width:200px;
  opacity: 1;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
  border-radius: 15px;
}

.search:focus {
  border-bottom: 2px solid #242629; /* Focus color */
  outline:none;
  border:none;

}

.dashboardContainer {
  display: grid;
  grid-template-areas:
    "header header header"
    "sidebar main main";
  grid-template-rows: auto 1fr; 
  grid-template-columns: auto 1fr; /* Adjust as needed for your sidebar width */
  height: 100vh; /* Full viewport height */
}
 
@media (max-width: 768px) {
  .dashboardContainer {
    /* grid-template-columns: 60px 1fr;  */
    grid-template-columns: 0 1fr; /* Adjust as needed for your sidebar width */

  }
}

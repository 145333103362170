.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  z-index: 3;
  background-color: #f8f9fa;
}

.header {
  padding: 0.5em 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  background-color: #242629;
  text-align: left;
  top: 0;
  left: 0;
  position: sticky;
  > span {
    display: flex;
    gap: 1em;
    margin: 0;
    align-items: center;
    .title {
      color: #eaeaea;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 30px;
      margin: 0;
    }
    .logo {
      object-fit: cover;
      border-radius: 50%;
      width: 75px;
      height: 75px;
    }
  }
}

.absoluteBackground {
  position: fixed;
  object-fit: cover;
  top: 80px;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  z-index: 1;
  /* filter: blur(10px); */
  opacity: 0.5;
}

.title {
  font-size: 2.5rem;
  color: #dc3545;
  margin-bottom: 1rem;
  z-index: 3;
}

.message {
  font-size: 1.25rem;
  color: #2c2f32;
  margin-bottom: 2rem;
  z-index: 3;
}

.button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  gap: 0.5em;

  background: rgba(255, 255, 255, 0.3);

  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px;
  backdrop-filter: blur(10px);
}

.button:hover {
  background: rgba(10, 255, 10, 0.3);
}

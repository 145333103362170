.popupContainer {
  display: flex;
  gap:1em;

  border:3px double gray;
  max-height: 40px;
  transform: perspective(1000px);

  font-size:16px;
  color:#242629;
  border-radius: 5px 5px 5px 5px;
  min-width: 200px;
  max-width: auto;
  border:5px double gray;
  z-index:99999;
  transition: 1s linear;
  overflow: hidden;

  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);

  right: 0;
  top:0;
  padding: 0.5em;
  
}

.show {
  display:flex;
}

.hide{
  display:none;
}

.animated {
  animation: spin3d 1s ease-in-out;
}

.msgContainer {
  position: relative;
  flex: 2;
}
.msg {
  font-size: 15px;
  white-space: nowrap;
}

@keyframes spin3d {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: rotateX(180deg) rotateY(180deg);
  }

  100% {
    transform: rotateX(360deg) rotateY(360deg);
  }
}

@media (max-width: 768px) {
  .popupContainer {
    max-width: 100%;


  }

  .iconContainer {
    position: relative;
    flex: 1;
    transform-style: preserve-3d;
    display: grid;
    place-items: center;
    min-height: 50px;
    width: 50px;
  }

  .icon {
    position: absolute;
    font-size: 30px;
    transition: 1s linear;
    z-index: 4;
    transform: rotateX(0deg) rotateY(0deg);
  }

  .show {
    display:flex;
  }

  .hide{
    display:none;
  }

  .animated {
    animation: spin3d 1s ease-in-out;
  }

  .msgContainer {
    position: relative;
    flex: 2;
  }
  .msg {
    font-size: 15px;
  }

  @keyframes spin3d {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }

    50% {
      transform: rotateX(180deg) rotateY(180deg);
    }

    100% {
      transform: rotateX(360deg) rotateY(360deg);
    }
  }
}

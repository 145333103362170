.searchParent {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row-reverse;
  /* padding: 2em 1em; */
  margin-bottom:1em;
  margin-top:1em;

  > input {
    max-width: 300px;
    height: 40px;
  }

  .button {
    transform: translateY(10px);
    height: 40px;
  }
  .title {
    font-size: 25px;
    font-weight: 800;
    color:gray;
    /* margin-left: 1em; */
  }
}

.button {
  font-size: 20px;
  height: 100%;
  &:hover {
    background-color: transparent;
  }
}

@media (max-width: 760px) {
  .searchParent {
    top: 0;
    justify-content: flex-end;

    padding: 0;
    padding-top:1em;

    > input {
      max-width:auto;
      display: block;
      height: 40px;
    }

    .title {
      font-weight: 800;
      font-size: 22px;
      border-radius: 15px;
      padding: 0.2em;
      margin-left: 1em;
    }
  }
}

/* view modal */
.container {
    box-sizing: border-box;
    text-align: right;
    border-radius: 25px;
    width: 100%;
    animation: fadeIn 0.5s ease-in-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
  .addClassworkForm {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    gap: .5em;
    input:nth-of-type(1) {
      /* background-color: rgb(192, 192, 192); */
      max-width: auto;
      min-width: 150px;
    }
  
    textarea {
      padding: 0.5em;
      max-height: 250px;
      min-height:200px;
      outline: none;
      border: 1px solid gray;
      font-size: 16px;
      color: gray;
      border: 1px solid rgb(197, 197, 197);
      border:none;
    }
    
    select {
      padding: 0.5em;
      border: 1px solid rgb(197, 197, 197);
      outline: none;
    }

    input{
      border:1px solid #eaeaea
    }
  }
  
  .fileInputWrapper{
    .hiddenInput{
      display:none;
    }
  }

  .selectContainer{
    display: flex;
    gap:.5em;
  }
  
  .expires {
    width: 150px; /* Adjust this value to control the width */
    padding: 0.5em; /* Optional: Adjust padding */
    font-size: 0.875rem;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .expires:focus {
    border-color: #0056b3; /* Optional: Focus state styling */
  }
  .lastSection {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    margin-bottom: 1em;
    gap: 0.5em;

  }

  .buttons{
width: 100%;
text-align: right;

  }

  .submitButton{
    color:#fff;
    background-color: green;
    padding:8px 16px;
    width:100%;
    display: flex;
    font-size: 16px;
    align-items: center;
    justify-content: center;
    gap:.5em;
    font-weight: 800;
    &:hover{
      background-color: green;
    }
  }
  .colorContainer{
    display: flex;
    gap:.5em;
  }
  .colorPreview {
    width: 100px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid gray;
  }
  
  .header {
    font-size: 20px;
    padding: .5em .5em;
    font-weight: 800;
    width: 100%;
  
    color: gray;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    border-radius: 8px 8px 0 0;
    align-items: center;
    margin-bottom:1em;
    justify-content: space-between;
    >span{
      padding:.5em;
    }
    > button {
      background-color: transparent;
      font-size: 16px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;

      margin:.5em;
  color:rgb(255, 83, 83);
  /* padding:8px 16px; */
      &:hover{
        background-color: #fff;
      }
    }
  }
  
  .clipButton {
    cursor: pointer;
    font-size: 25px;
  
    background-color: transparent;
    border: none;
    display: grid;
    place-content: center;
    color: gray;
  
    transition: color 0.3s ease;
  }
  
  .clipButton:hover {
    color: #0056b3;
  }
  
  .fileInputWrapper {
    display: inline-block;
  }
  
  
.contactBackgroundContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }
  
  .contactBackground {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* filter: blur(8px); */
  opacity: 0.5;

  }
  
  .aboutContainer {
    position: relative;
    z-index: 10;
    max-width: 800px;
    margin: 0 auto;
    padding: 2em;
    padding-bottom:4em;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    .aboutHeader {
        position: relative;
        text-align: center;
        margin-bottom: 1.5em;
      }
      
      .headerBackground {
        width: 100%;
        border-radius: 10px;
        opacity: 0.2;
      }
      
      .aboutTitle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5em;
        font-weight: bold;
        color: #333;
      }
      
      h2 {
        font-size: 1.8em;
        color: #333;
        margin-top: 1em;
      }
      
      p {
        color: #555;
        line-height: 1.6;
        margin-top: 0.5em;
      }
      
      .ourTeamSection {
        text-align: center;
        margin-top: 2em;
      }
      
      .teamTitle {
        font-size: 1.8em;
        color: #333;
        margin-bottom: 1em;
      }
      
      .teamImages {
        display: flex;
        justify-content: center;
        gap: 1.5em;
        margin-top: 1em;
      }
      
      .teamMemberContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      
      .teamMember {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #ddd;
        transition: transform 0.3s;
      }
      
      .teamMember:hover {
        transform: scale(1.1);
      }
      
      .teamName {
        font-size: 1em;
        color: #333;
        font-weight: bold;
        margin-top: 0.5em;
      }
      
      .teamRole {
        font-size: 0.9em;
        color: #666;
        margin-top: 0.2em;
      }
      
      .contactLink{
        color: #0066cc;
        z-index: 30;
        cursor: pointer;
        text-decoration: underline;
      }
  }
  
  
  
  @media (max-width: 768px) {
    .aboutTitle {
      font-size: 2em;
    }
    .teamImages {
      flex-direction: column;
      gap: 1em;
    }
  }
  
.toolsParent {
  min-height: auto;
  height: 100%;
}

.toolsBody {
  display: flex;
  margin: 0.5em 0;
  padding: 1.5em 10em;
  gap: 1em;
}
.toolsContainer {
  width: calc(100%);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

@media (max-width: 800px) {
  .toolsParent {
    padding: 0;
    margin: 0;
  }
  .toolsBody {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 1em;
    gap: 1em;
  }
  .toolsContainer {
    width: calc(100%);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5em;
  }
}

.header {
  width: 100%;
  height: 80px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  border: none;
  border-top: none;
  border-left: none;
  border-right: none;
  /* border: 1px solid gray; */
}

.left {
  font-size: 30px;
  /* border: 1px solid gray; */

  font-weight: bold;
  white-space: nowrap; /* Prevent title from wrapping */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* gap:.5em; */
  > span {
    font-weight: 800;
    text-transform: uppercase;
  }
  img {
    object-fit: contain;
    border-radius: 50%;
    max-width: 70px;
    min-width: 70px;
    max-height: 70px;
  }
}

.right {
  display: flex;
  align-items: center; /* Center actions vertically */
  justify-content: center;
  gap: 14px;
  /* border: 1px solid gray; */

  height: 100%;
  span {
    color: gray;
    /* font-size: 30px; */
    transform: translateY(4px);
    cursor: pointer;
    padding: 0;
    margin: 0;
   
  }
  span:nth-of-type(1){
    font-size: 30px;
    transform: translateY(6px);
    &:hover{
      color:greenyellow;
    }
  }
  span:nth-of-type(3) {
    font-size: 16px;
    font-weight: 800;
    min-width: 100px;
    text-transform: capitalize;
  }
  img {
    border: 4px double gray;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    margin-right: 0.8em;

    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
  }
}

.actionButton {
  background: none;
  border: none;
  font-size: 1em;
  cursor: pointer;
  margin-left: 1em;
  transition: color 0.3s ease;
}

.actionButton:hover {
  color: #3f51b5;
}

.menuDropdown {
  background-color: #242629;
  position: absolute;
  right: 0;
  color: black;
  text-align: left;
  top: 80px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

  span,
  a {
    color: gray;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    padding: 1em;
    gap: 0.5em;
    cursor: pointer;
    transition: 0.2s linear;
    label {
      cursor: pointer;
    }
  }

  span:hover,
  a:hover {
    scale: 1.05;
    color: #fff;
  }

  span:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .header {
    width: 100%;
    height: 80px;
  }

  .left {
    font-weight: bold;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.2em;
    padding: 0 0.2em;
    > span {
      font-weight: 800;
      font-size: 25px;
    }
    img {
      border-radius: 50%;
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
    }
  }

  .right {
    padding: 0.5em;
    gap: 0.5em;
    span {
      color: gray;
      font-size: 30px;
      cursor: pointer;
    }
    span:nth-of-type(3) {
      display: none;
    }
    img {
      border: 4px double gray;
      border-radius: 50%;
      min-width: 60px;
      min-height: 60px;
      max-width: 60px;
      max-height: 60px;
      transform: translateY(5px);
    }
  }

  .menuDropdown {
    background-color: #242629;
    position: absolute;
    right: 0;
    color: black;
    text-align: left;
    top: 80px;

    span,
    a {
      color: #fff;
      display: flex;
      align-items: center;
      gap: 0.5em;
      justify-content: flex-start;
      font-size: 14px;
      cursor: pointer;
      transition: 0.2s linear;
      padding: 0.5;
      label {
        cursor: pointer;
      }
    }
  }
}

.container {
  display: flex;
  height: 100vh;
  width: 100%;
  color: white;
  overflow: hidden;
  border: 1px solid black;
}

.loginCard {
  display: flex; 
  width: 100%;
  overflow: hidden;
}

.formSection {
  position: relative;
overflow-y: auto;
height:100h;
  width: 30vw;
  float: left;
  text-align: left;
  padding: 5em 2em;
}

/* Customize the scrollbar */
.formSection::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

.formSection::-webkit-scrollbar-track {
  background:transparent; /* color of the scrollbar track */
}

.formSection::-webkit-scrollbar-thumb {
  background-color: #d2d2d2; /* color of the scrollbar thumb */
  border: 2px solid transparent; /* adds padding around the thumb */
}

.formSection::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* color of the scrollbar thumb on hover */
}

.form {
  position: relative;
}

.logo {
  width: 50px;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 2rem;
}

.inputGroup {
  width: 100%;
  margin-bottom: 1rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem;
  border: 1px solid gray;
}

.input:focus {
  border: 1px solid gray;
  outline: none;
}
/* profile */
.profile{
    width:100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap:.5em;
    flex-direction: row-reverse;
    margin:1em 0;
}
.profile > input{
   flex: 1;
}
.profile > .image{
    width:150px;
    height:150px;
    border-radius: 50%;
    border:1px solid #d2d2d2;
    background-size: cover;
    object-fit: cover;
    flex: 1;
}
/* personal name */
.name {
    display: flex;
    gap:.5em;
    width:100%;
}
.name input{
    flex:1;
    
}
/* dropdown strand year */

.dropdown {
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding-bottom:1em;
}
.dropdown span:first-child,
.dropdown span:last-child {
  display: flex;
  text-align: left;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  flex: 1;
}

/* account */

.account{
    border-top: 1px solid #d2d2d2;
    width:100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
    margin:1em 0;
    padding-top:1em;
}
.account > input{
    margin-top:.5em;
}

/* right */
.illustrationSection {
  width: 75vw;
}

.illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.illustration img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.button {
  width: 100%;
  padding: 0.75rem;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.2s linear;
}

.button:hover {
  scale: 1.02;
}

.link {
  color: #ffc107;
  text-decoration: none;
  font-size: 0.9rem; /* Adjust font size for the "Forgot password?" link */
}

.link:hover {
  text-decoration: underline;
}

/* Add this to login.module.css */

.backButton {
  position: absolute;
  top: 1em;
  left: 1em;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  background-color: var(
    --button-background
  ); /* You can use your global style variable */
  color: var(--button-text); /* You can use your global style variable */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.2s linear;
  text-decoration: none; /* Make it look like a button even if it's a link */
  display: flex;
  align-items: center;
  justify-content: center;
  gap:.5em;
  white-space: nowrap;
}

.backButton:hover {
  scale: 1.02;
}

.backButtonIcon {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .loginCard {
    flex-direction: column;
    padding: 1rem;
    border-radius: 12px;
  }
  .container {
    display: flex;
    align-items: center;
  }

  .loginCard {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  .formSection {
    width: 100%;
    text-align: center;
    border-radius: 12px;
  }

  .illustrationSection {
    display: none;
  }

  .inputGroup {
    margin-bottom: 0.75rem;
  }

  .input,
  .button {
    padding: 0.5rem;
  }
}

.container {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  border-radius: 25px 0 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.form {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em 1em;
  span:nth-of-type(1) {
    display: flex;
    align-items: stretch;
    gap: 0.2em;
    margin:0;
    padding:0;
    .randomCodeButton {
      cursor: pointer;
      background-color: gray;
      height: 45px;
      margin:0;
      color: #fff;

    }
  }
  input {
    border-bottom: 1px solid rgb(189, 189, 189);
  }
  span:nth-of-type(2) {
    display: flex;
    align-items: stretch;
    gap: 0.3em;

    > div {
      /* padding: 1em; */
      border-radius: 8px;
      width: 100px;
    }
  }
  .buttons {
    grid-column: span 3;
    width: 100%;
    display: flex;
    justify-content: center;
    margin:0;
    padding:0;
    align-items: center;
    button {
      padding: 8px 16px;
      width:100%;
      font-size: 16px;
      background-color: green;
      color: #fff;
      gap: 0.5em;
      display: flex;
    font-weight: 800;
    font-size: 16px;
      margin:0;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
  }
}



.header {
  font-size: 20px;
  color: gray;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-transform: uppercase;
  /* padding: 0.5em; */
  text-align: left;
  >span{
    padding:1em;
  }
  > button {
    background-color: transparent;
    font-size: 16px;
    /* margin:1em; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
color:rgb(255, 83, 83);
    margin:1em;
padding:8px 16px;
  }
}

.container{
  display: flex;
  flex-direction: column;
animation: fadeIn 0.5s ease-in-out;
  border-radius: 8px;
  padding:1em;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
animation: fadeIn 0.5s ease-in-out;
}



.cardWrapper{
  display: flex;
  flex-wrap: wrap;
  padding:1em 0;
  grid-template-columns: repeat(auto-fit,minmax(200px ,1fr));
  gap:1em;
}

.card{
  position:relative;
  border: 1px solid #d2d2d2;
  padding:2em;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px 50px 50px 8px;
  cursor: pointer;
  transition:.2s;
  max-width:250px;
  width:250px;
} 
.card:hover{
  /* scale: 1.04; */
    box-shadow: 0 4px 8px rgba(0,0,0,0.4);
}
.kebab{
  position:absolute;
  bottom:3px;
  font-size:25px;
  left:18px;
  transition:.2s;
  border-radius: 50%;
  display: grid;
  place-content: center;
  background-color: #fff;
  border:5px double gray;
}
.kebab:hover{
  scale:1.05;

}
.rankButton{
  background-color: transparent;
  font-size: 25px;
  transform: translateY(-3px);
  &:hover{
    background-color: transparent;
    scale: 1.04;
    text-shadow: 0 4px 8px rgba(0,0,0,0.4);
  }
}
.cardMenu{
  position:absolute;
  background-color: transparent;
  display: flex;
bottom:-13px;
left:70px;
  gap:.5em;
  button{
    background-color: transparent;
    color:gray;
    font-size:16px;
    padding:0;
    padding:8px 8px 0 8px;
    color:#d2d2d2;

  }
  button:hover{
    background-color: #fff;
    color:#000;
  }
}
.title{
  position:relative;
  font-weight: 800;
color:#d2d2d2;

}
.startBanner{
  border-top: 10px double #242629;
position:absolute;
box-shadow: 0 4px 8px rgba(0,0,0,0.4);
left:10px;
width:50px;
height:100%;
top:0;
border-radius: 0 0 50px 50px;
background-color: #fff;
}

.endBanner{
  position:absolute;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
  right:0;
  width:100px;
  height:5px;
  bottom:-15%;
  border-radius: 50px;
}

@media (max-width:768px){

}
.viewContainer {
    width: 100%;
    padding: 2em;
    background-color: #f9f9f9; /* Light background */
    border-radius: 8px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.4);
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 30px auto;
    max-width: 700px; /* Limit the width for better readability */
    text-align: left; /* Left-align everything */
    box-sizing: border-box;
    line-height: 1.6;
    color: #333; /* Dark text color */
}
.buttonContainer{
    width: 100%;
    text-align: right;
    >.closeButton{
        background-color: transparent;
        font-size: 16px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        font-weight: 800;
    color:rgb(255, 83, 83);
    padding:8px 16px;
        &:hover{
          background-color: #fff;
        }
    }
}
.firstSection >     h2 {
    font-size: 1.75rem;
    color: #2d3e50;
    font-weight: bold;
    margin-bottom: 1em;
}

.section {
    margin-bottom: 1.5em;
    padding-top: 1em;
    border-top: 1px solid #e0e0e0;
    > h3 {
        font-size: 1.1rem;
        color: #2d3e50;
        margin-bottom: 0.75em;
    }
    span {
        font-size: 1rem;
        color: #555;
    }
    .content {
        font-size: 1rem;
        color: #444;
        white-space: pre-wrap; /* Preserve line breaks */
        margin-top: 0.5em;
        padding-left: 0.5em; /* Slight indentation for readability */
    }
}

.attachment {
    color: #ffffff;
    background-color: #007bff;
    padding: 0.6em 1.2em;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s;
}

.attachment:hover {
    background-color: #0056b3;
}


@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
.fadeIn {
animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
    .viewContainer {
        padding: 1.5em;
        max-width: 100%;
    }

    .firstSection h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1rem;
    }

    span {
        font-size: 0.9rem;
    }

    .content {
        font-size: 0.95rem;
    }

    .attachment {
        padding: 0.5em 1em;
        font-size: 0.95rem;
    }
}

/* modal conatiner */
.modalContainer {
  width: 100%;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;
  overflow-y: auto;
  padding: 2em 2em;
  /* padding-top: 3em; */
}

/* stud info */
.infoContainer {
  width: 100%;
  box-sizing: border-box;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
}

.studInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 300px;
  text-align: left;
  padding: 1em 0;
  font-size: 12px;
}

.studImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  min-height: 160px;
}

.label {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0.3em 0;
}

.image {
  object-fit: cover;
  border-radius: 15px;
  width: 150px;
  height: 150px;
  border: 1px solid #888;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 0.5em;
  align-items: stretch;
  justify-content: flex-end;
}

.buttons button {
  font-size: 25px;
}

.buttons button:nth-child(3) {
  font-size: 15px;
}

/* email sending */
.emailParent {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.emailParent > form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 0.5em;
  text-align: left;
  padding: 0.5em;
  border-radius: 15px;
}
.emailParent > form span {
  display: flex;
  align-items: center;
}

.emailParent > form span > label {
  font-size: 12px;
  width:100%;
  text-align: left;
}

/* This targets the second span inside the form */
.emailParent > form span:nth-of-type(2),
.emailParent > form span:nth-of-type(3) {
  display: flex;
  flex-direction: column;
}

/* If you want to target specific elements inside the second span, like the textarea */
.emailParent > form span:nth-of-type(2) textarea {
  /* Add your styling here */
  width: 100%; /* Example */
  height: 100px; /* Example */
}

.button,
.modalButton,
.rightModalButton,
.buttomModalButton {
  padding: 6px 12px;
  border-radius: 5px;
  transition: 0.2s linear;
  cursor: pointer;
  color: #383232;
  font-size: 12px;
  border: none;
  display: flex;
    align-items: center;
    justify-content: center;
    gap:.5em;
    white-space: nowarp;
}
.button:hover {
  transform: scale(1.1);
    background-color: transparent;
}

.icon {
  font-size: 30px;
  cursor: pointer;
}

.search {
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0.5em;
  display: none;
}
.search:focus {
  outline: none;
}

.link {
  color: black;
}

.header {
  padding: 0.5em;
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-weight: 800;
  color: #383232;
  border-bottom: 1px solid #afafaf;
}

.modalButton {
  position: absolute;
  right: 1em;
  top: 1em;
  font-size:15px;
}

.rightModalButtonContainer {
  position: absolute;
  right: 1em;
  top: 1em;
  gap: 0.5em;
  display: flex;
  align-items: center;
}

.buttomModalButton {
  position: fixed;
  bottom: 5em;
  right: 1em;
} 

.title {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #cecece;
  padding: 0.5em;
}

@media (max-width: 760px) {
  .button,
  .modalButton,
  .rightModalButton,
  .buttomModalButton {
    padding: 6px 12px;
    border-radius: 5px;
    transition: 0.2s linear;
    cursor: pointer;
    color: #383232;
    font-size: 12px;
    border: none;
  }
  .button:hover {
    transform: scale(1.1);
  }
  
  .icon {
    font-size: 30px;
    cursor: pointer;
  }
  
  .search {
    width: 100%;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 0.5em;
    display: none;
  }
  .search:focus {
    outline: none;
  }
  
  .link {
    color: black;
  }
  
  .header {
    padding: 0.5em;
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-weight: 800;
    color: #383232;
    border-bottom: 1px solid #afafaf;
  }
  
  .modalButton {
    position: absolute;
    right: 1em;
    top: 1em;
    font-size:15px;
  }
  
  .rightModalButtonContainer {
    position: absolute;
    right: 1em;
    top: 1em;
    gap: 0.5em;
    display: flex;
    align-items: center;
  }
  
  .buttomModalButton {
    position: fixed;
    bottom: 5em;
    right: 1em;
  } 
  
  .title {
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #cecece;
    padding: 0.5em;
  }
  


}

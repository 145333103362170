 


  /* Background Styling */
.contactBackgroundContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }
  
  .contactBackground {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* filter: blur(10px); */
    /* opacity: 0.7; */
  opacity: 0.5;

  }
  
  /* Main Container */
  .termsContainer {
    position: relative;
    z-index: 10;
    max-width: 800px;
    margin: 0 auto;
    padding: 2em;
    padding-bottom:4em;

    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    line-height: 1.6;
    padding-bottom:2em;
    .termsHeader {
      position: relative;
      text-align: center;
      margin-bottom: 1.5em;
    }
    
    .headerBackground {
      width: 100%;
      border-radius: 10px;
      opacity: 0.2;
    }
    
    .aboutTitle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5em;
      font-weight: bold;
      color: #333;
    }
  }
  
  /* Header Styling */
  .termsContainer h1 {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 1.5em;
  }
  
  .termsContainer p {
    color: #555;
    font-size: 1em;
    margin: 1em 0;
  }
  
  .termsContainer h2 {
    font-size: 1.5em;
    color: #333;
    margin-top: 1.5em;
    margin-bottom: 0.75em;
  }
  
  /* List Styling */
  .termsContainer ul {
    list-style-type: square;
    padding-left: 1.5em;
    color: #555;
    margin-top: 0.5em;
  }
  
  .termsContainer ul li {
    margin: 0.5em 0;
  }
  
  /* Link Styling */
  .termsContainer a {
    color: #0066cc;
    text-decoration: underline;
  }
  
  .termsContainer a:hover {
    color: #004da1;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .aboutTitle {
      font-size: 2em;
    }
    .termsContainer {
      padding: 1.5em;
    }
  
    .termsContainer h1 {
      font-size: 2em;
    }
  
    .termsContainer h2 {
      font-size: 1.3em;
    }
  
    .termsContainer p, .termsContainer ul li {
      font-size: 0.95em;
    }
  }
  
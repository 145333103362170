.homeContainer{
    width: 100%;
    padding:0;

    .modalContainer{
        margin-top:1em;
        max-width: 100%;
        box-sizing: border-box;
        /* overflow-x: hidden; */
    }
    .homeCardContainer{
        margin:0;
        margin-top:1em;
        gap:1em;
        max-height: 200px;
        padding-bottom:1em;
        display: grid;
        /* margin-top:20px; */

        grid-template-columns: 1fr 1fr 1fr 1fr;
        .onlineTeachers{
            background: rgba(190,10,10,0.1);
            backdrop-filter: blur(10px);
        }
        .onlineStudents{
            background: rgba(190,150,10,0.1);
        }
        .verificationRequests{
            background: rgba(10,190,10,0.1);
        }
        .collaborationRequests{
            background:rgba(50,210,190,0.1);
        }
        .card{
            border:1px solid #d2d2d2;
            position:relative;
            display: grid;
    margin-bottom: 1em;;
    font-weight: 800;
    font-size: 50px;

            color:#000;
            border-radius: 15px;
            cursor: pointer;
            transition: .2s linear;
            min-height:200px;
            &:hover{
                box-shadow: 0 4px 8px rgba(10,10,10,0.5);
            }
            .top,.mid,.bot{
                width:100%;
                position: relative;
                display: flex;
                padding:0 .5em;
            }
            .top{
                justify-content: flex-start;
                align-items: center;
                .topIcon{
                    font-size:25px;
                }
            }
            .mid{
                justify-content: flex-start;
                gap:.2em;
                .title{
                    text-align: left;
                    font-size:18px;
                    /* white-space: nowrap; */
                }
                
            }
            .bot{
                justify-content: flex-start;
                gap:.2em;
                .number{
                    font-size:20px;
                }
                .onlineIndicator{
                    border-radius: 50%;
                    width:15px;
                    height:15px;
                    color:greenyellow;
                    background-color: greenyellow;
                }
            }
        }
    }
}

@media (max-width:768px){
    .homeContainer{
        /* padding-bottom:6em; */
        padding:0 !important;
        .homeCardContainer{
        grid-template-columns: 1fr 1fr  ;
        max-height: 40px;
        /* overflow-x: hidden; */
        margin-top:20px;

        gap:.2em;
            .card{
              padding:1em;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap:.2em;
              min-height:50px;
              max-height:50px;

              margin-bottom: 0;;

                .top,.mid,.bot{
                }
                .top{
                    .topIcon{
                        font-size:15px;
                    }
                }
                .mid{
                    justify-content: flex-start;
                    .title{
                        font-size: 12px;
                        text-align: left;
                    }
                    
                }
                .bot{
                    .number{
                    }
                    .onlineIndicator{
                    }
                }
            }
        }
    }
    
    
}

@keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeIn {
    animation: fadeIn 0.6s ease-in-out;
  }
  
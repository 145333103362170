.buttons{
    width:50vw;
    display: flex;
    gap:1em;
    justify-content: flex-end;
    width: 100%;
    margin:1em 0;
    align-items: center;
animation: fadeIn 0.5s ease-in-out;

    button{
        font-size:14px;
        display: flex;
        align-items: center;
        border:1px solid gray;
        gap:.5em;
        font-weight: 600;
        color:gray;
        background: #242629;
    }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width:768px){
  .buttons{
    margin-top:2em;
    top:2em;
  }
}



* {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

.parent {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background: transparent;
  position: relative;
  overflow-y: auto;
  padding: 5em;
  display: flex;
  gap: 5em;
  align-items: flex-start;
  justify-content: center;
}

.header {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
  background: #383232;
  padding: 0.5em;
  margin-bottom: 0.5em;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
}

.buttonContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

/*
    right section or panel
*/
.roomsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  flex: 1;
}

.greetingContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-width: 50%;
  flex: 1;
  gap: 0.5em;
  color: #242629;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  .trademark {
    letter-spacing: 5px;
    font-weight: 800;
    font-size: 12px;
    text-transform: uppercase;
  }
  .welcome {
    font-size: 50px;
    font-weight: 800;
  }
  .description {
    font-size: 25px;
    font-weight: 600;
  }
  .goal {
    font-size: 12px;
  }
  .note {
    font-size: 12px;
    /* display: none; */
  }
  .buttonContainer {
    display: flex;
    margin-top: 0.5em;
    gap: 0.5em;
    .joinRoomBtn {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      background: green;
      border: none;
      color: #fff;
      gap: 0.5em;
    }
    .createRoomBtn {
      display: flex;
      align-items: center;
      background: #d2d2d2;
      font-size: 12px;
      border: none;
      font-weight: 600;
      gap: 0.5em;
    }
  }
}

.gamifiedContainer {
  position: relative;
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px;
  backdrop-filter: blur(10px);
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  .gamifiedTutorialBtn {
    font-size: 30px;
    border-radius: 15px;
    z-index: 500;
    padding: 12px 24px;
    font-weight: 700;
    color: #ffffff;
    background: linear-gradient(45deg, green, yellowgreen);

    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    animation: pulse 2s infinite;
  }

  .gamifiedTutorialBtn:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
  }
}

.tutorialContainer {
  background: rgba(255, 255, 255, 0.3);
  background-size: 20px 20px;
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  backdrop-filter: blur(10px);
  position: relative;
  padding: 1em;
  border-radius: 15px;
  max-width: 100%;
  margin: auto auto;
  width: 100%;
  height: auto;
.signifyNavigator{
  display: inline;
  align-items: center;
  display: flex;
  color:#db3d0d;
  float:right;
  font-size: 25px;
  font-weight: 800;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
  .description{
    font-size:14px;
    text-align: left;
  }

  .guideSign2Speech {
    padding: 1em 0;
    min-height: 700vh;
    height:auto;

  }
  .guideSpeech2Sign {
    transform: translateY();
    padding: 1em 0;
    min-height: 700vh;
    height:auto;

  }
  .TutorialTableHeader{
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    scroll-behavior: smooth;
  }
  .TutorialTableParent{
    width:calc(100% - 35px);
    float: right;
    padding: 1em;
    transform:translateY(-6em);
  }

  .guideDefault{
    padding: 1em 0;
    min-height: 700vh;
    height:auto;
    .SystemInformationHeader{
      font-weight: 800;
      font-size:25px;
text-transform:uppercase;
scroll-behavior: smooth;
    }

    .signTutorialParent{
      padding:1em;
      border:1px solid #d1d1d1;
      width:calc(100%);
      float:right;
      z-index: -1;
      .description{
        font-size: 14px;
      }
      .imagesContainer{
        display: grid;
        grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
        gap:1rem;
        align-items: center;
        padding-top:1em;
        .imagesWrapper{
          display: grid;
          place-content: center;
          gap:.5em;
          .signImage{
            object-fit: contain;
            width:100%;
            min-height:250px;
            max-height:250px;
            border-radius: 8px;
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
          }
          .signTitle{
            font-size:1.6rem;
            color:green;
            text-align: center;
          }
        }
      }
    }
  }
  .sidebarNavigations {
    width: 30px;
    margin-top: 1em;
    padding: 0 0 0 0;
    height: auto;
    border-radius: 8px;
    position: sticky;
    left: 0;
    top: 1em;
    z-index: 100000;
    /* transform: translateY(50%); */
    display: grid;
    border: 1px solid #d1d1d1;
    gap:.2em;
    > a {
      background: #fff;
      border: none;
      border-bottom:1px solid #d1d1d1;
      transition: .2s linear;
      font-size: 14px;
    z-index: 100000;
cursor: pointer;
      &:hover{
        scale:1.04;
      }

    }
  }

  .dataTableHeader{
    width:100%;
    margin:1em auto;
   >h3{
      width:70%;
      text-align: left;
      margin:3em auto 1em auto;
    }
    >img{
      object-fit: cover;
      width:70%;
      border-radius: 15px;
      margin:1em auto;
    }
  }
  .dataTableBody{
    margin:1em auto;
    min-width: 70%;
  }
}

.title {
  background-clip: text;
  font-weight: 800;
  text-align: center;
  -webkit-background-clip: text;
}

.main {
  display: grid;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 2em;
}

.ioPanel {
  position: absolute;
  background-color: #ffffff;
  min-width: 150px;
  min-height: 80px;
  max-height: 80px;
  overflow-y: auto;
  scroll-behavior: smooth;
  bottom: 0;
  right: 0;
  border-top-left-radius: 15px;
  padding: 0.5em;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 2;
}

.ioPanel span {
  color: black;
  font-size: 12px;
  display: block;
}

.chatPanel {
  background-color: black;
  max-width: 100%;
  height: auto;
  min-height: 67vh;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.3);
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
  border-radius: 15px;
}

.chatPanelTop {
  padding: 0.5em;
  max-height: 300px;
  border-radius: 15px;
  overflow-y: auto;
  scrollbar-width: hidden;
}

.chatPanelTop::-webkit-scrollbar {
  width: 12px;
}

.chatPanelTop::-webkit-scrollbar-button {
  color: white;
  background-color: #1a7421;
}
.chatPanelTop::-webkit-scrollbar-thumb {
  color: white;
  background-color: #1a7421;
  border-radius: 8px;
}
.chatPanelTop::-webkit-scrollbar-track {
  background-color: transparent;
}

.chatBox {
  display: flex;
  gap: 0.5em;
}
.chatBox > div:first-of-type {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  object-fit: cover;
  border: 1px solid black;
}
.chatBox {
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}
.chatBox p:first-child {
  font-size: 10px;
}
.chatBox p:last-child {
  font-size: 10px;
}

.chatPanel > .chatPanelBottom {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0.5em 0;
  justify-content: center;
}

.chatPanel > .chatPanelBottom > input {
  border-radius: 8px;
  padding: 4px 8px;
  min-width: 100px;
  margin: 0 0.5em;
  max-width: 300px;
  outline: none;
  border: 1px solid gray;
}

.chatPanel > .chatPanelBottom > button {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-size: 15px;
  cursor: pointer;
  margin: 0 0.5em;
  border-radius: 8px;
  border: 1px solid gray;
}

@media (max-width: 768px) {
  .parent {
    padding: 1em;
    display: grid;
    /* flex-direction: column; */
    gap: .5em;
    padding-bottom: 5em;

    .greetingContainer {
      width: 100%;
      flex: 1;
      gap: 0.5em;
      color: #242629;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      .trademark {
        letter-spacing: 5px;
        font-weight: 800;
        font-size: 12px;
      }
      .welcome {
        font-size: 30px;
        font-weight: 800;
      }
      .description {
        font-size: 20px;
        font-weight: 600;
      }
      .goal {
        font-size: 12px;
      }
      .note {
        font-size: 12px;
      }
    }
  }

  .tutorialContainer {
    padding: .5em;
    border-radius: 15px;
    max-width: 100%;
    margin: auto auto;
    width: 100%;
    height: auto;
    .description{
      text-align: left;
      font-size: 12px;
    }
    .guideSign2Speech {
      padding: .5em 0;
      min-height: auto;
    }
    .guideSpeech2Sign {
      padding: .5em 0;
      min-height: auto;
      height:auto;
  
    }
    .TutorialTableHeader{
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
      scroll-behavior: smooth;
    }
    .TutorialTableParent{
      width:calc(100%);
      float: none;
      padding: .5em;
      transform:translateY(0em);
    }

    .guideDefault{
      padding: .5em 0;
      min-height: auto;
      height:auto;
      .SystemInformationHeader{
        font-weight: 600;
        font-size:18px;
  text-transform:uppercase;
  scroll-behavior: smooth;
      }
  
      .signTutorialParent{
        padding:.5em;
        border:1px solid #d1d1d1;
        width:calc(100%);
        float:none;
        .description{
          font-size: 12px;
        }
        .imagesContainer{
          display: grid;
          grid-template-columns: repeat(auto-fit,minmax(50px,1fr));
          gap:.5rem;
          align-items: center;
          padding-top:.5em;
          .imagesWrapper{
            display: grid;
            place-content: center;
            gap:.5em;
            .signImage{
              width:100%;
              min-height:130px;
              max-height:250px;
            }
            .signTitle{
              font-size:15px;
              color:green;
              text-align: center;
            }
          }
        }
      }
    }
    .sidebarNavigations {
      width: 30px;
      margin-top: 1em;
      height: auto;
      border-radius: 8px;
      position: sticky;
      right: 0;
      top: 1em;
      z-index: 100000;
      display: flex;
      border: 1px solid #d1d1d1;
      gap:.2em;
      > a {
        background: #fff;
        border: none;
        border-bottom:1px solid #d1d1d1;
        transition: .2s linear;
        font-size: 12px;
      z-index: 100000;
      padding:.5em;

  cursor: pointer;
        &:hover{
          scale:1.04;
        }
  
      }
    }
  
    .dataTableHeader{
      width:100%;
      margin:1em auto;
     >h3{
        width:100%;
        text-align: left;
        margin:1em auto 1em auto;
      }
      >img{
        object-fit: cover;
        width:100%;
        margin:1em auto;
      }
    }
    .dataTableBody{
      margin:1em auto;
      min-width: 70%;
    }
  }
  
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideIn {
  animation: slideIn 0.6s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
}
.pulse {
  animation: pulse 0.6s ease-in-out;
}

@keyframes popupFade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes popupMove {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50px);
  }
}

.container {
  display: flex;
  height: 100vh;
  width:100%;
  color: white;
  overflow: hidden;
  border:1px solid black;
}

.loginCard {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.formSection{
  position:relative;

  width:30vw;
float:left;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
}

.header{
  display: flex;
  flex-direction: column;
  padding:3em 3em 0 3em;
  justify-content: flex-start;
  align-items: flex-start;
}
.loginForm {
  position:relative;
  display: flex;
  flex-direction: column;
  padding:0;
  margin:0;
  padding:2em 3em;
}

.illustrationSection {
  width:75vw;

}

.illustration {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width:100%;
}

.illustration img {
  object-fit: cover;
  width:100%;
  height:100vh;
  box-shadow: 0 8px 16px rgba(0,0,0,0.1);

}
.logo {
  width: 50px;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 1rem;
  margin-bottom: 2rem;
  white-space: nowrap;
}

.inputGroup {
  width: 100%;
  margin-bottom: 1rem;

  >p{
    color:red;
    font-size:12px;
    width:100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:.5em;
  }
}


.input {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 1rem;
  border:1px solid gray;
}

.input:focus {
  border:1px solid gray;
  outline:none;
}


.button {
  width: 100%;
  padding: 0.75rem;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
  transition: .2s linear;
}

.button:hover {
  scale:1.02;
}

.forgot {
  color: #ffc107;
  text-decoration: none;
  font-size: 15px; /* Adjust font size for the "Forgot password?" link */
  
}

.forgot:hover {
  text-decoration: underline;
}
.rememberMe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5em;

  .checkRemember {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5em; /* Space between checkbox and text */
      white-space: nowrap; /* Prevents the text from wrapping */

    .checkbox {
      margin-right: 0.5em; /* Adjust the spacing if necessary */
    }

   
  }

  .forgot {
    margin-left: auto;
    font-size: 14px;
    color:#ffc107;
  }
}





.toggler {
  position: absolute;
  top: -2em;
right:7em;
}

/* Add this to login.module.css */

.backButton,.suButton {
  position: absolute;
  bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.75rem;
  gap:.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: .2s linear;
  text-decoration: none; /* Make it look like a button even if it's a link */
}
.backButton{
  left:1em;
}
.suButton{
  right: 1em;
}

.buttons{
  display: flex;
  gap:1em;
}

.backButton:hover {
  scale: 1.02;
}

.backButtonIcon {
  font-size:25px;
  padding:0;
  margin:0;
}


@media (max-width: 768px) {
  .loginCard {
    flex-direction: column;
    padding: 1rem;
    border-radius: 12px;

  }
  .container {
    display: flex;
    align-items: center;
  }
  
  .loginCard {
    display: flex;
    width: 100%;
    overflow: hidden;
  }
  .subtitle {
    display: none;
  }
  .formSection{
    width:100%;
  text-align: center;
  border-radius: 12px;
padding: 3em 2em;

  }

  .illustrationSection {
    display: none;
  }

  .inputGroup {
    margin-bottom: 0.75rem;
  }

  .input, .button {
    padding: 0.5rem;
  }
}

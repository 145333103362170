.parent {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  padding: 0 1em;
  padding-bottom: 3em;
  > button {
    border: 1px solid gray;
    padding: 1em;
  }
  >p{
    color:gray;
    padding:0 .5em;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
animation: fadeIn 0.5s ease-in-out;
}

.header{
  font-weight:800;
  font-size:20px;
  color:gray;
  width:100%;
  display: flex;
  align-items: center;
  /* letter-spacing: 2px; */
  padding:.5em;
  justify-content: space-between;
}

.strandContainer{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:.5em;
  box-sizing: border-box;
  width:100%;
  padding:1em;
}


.col{
  padding:1em;
  background-color: transparent;
  border:1px solid #eaeaea;
  border-radius: 5px;
  width:100%;
  box-sizing: border-box;
  cursor: pointer;
  transition:.2s; 
  position: relative;

}

.card{
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 25px;
  transition:.2s;
}
.card:hover{
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  scale:1.04;
}
.cardBody{
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width:100%;

  border-radius:0 25px 25px 0;
  border:1px solid gray;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  position:relative;
  .icon{
      position:absolute;
      top:0;
      right:0;
      padding:1em;
      border-radius: 0 25px 0 25px ;
      display: flex;
      border:1px solid rgb(200, 200, 200);
      align-items: center;
      gap:.5em;
      justify-content: center;
      background-color: greenyellow;
      font-size: 12px;

  }

  .icon:hover{
      box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
}

.cardImg{
  width:calc(100%);
  height:200px;
  border-radius: 25px 0 0 25px;
}
.cardTitle,.cardText{
  font-size:30px;
  font-weight: 800;
  text-shadow: 0 4px 8px rgba(0,0,0,0.1);
  letter-spacing: 5px;
  color:#242629;
} 
.cardText{
  margin:.5em;
  border:1px double gray;
  border-radius: 15px ;
  color:gray;
  padding:.3em;
}


.icon{
  color:black;
}

.buttonContainer{
  display: flex;
  justify-content: flex-end;
  gap:.5em;
  margin-top:1em;
}




@media (max-width: 768px) {
  .parent {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 100vh;
    justify-content: flex-start;
    padding: 1em 0;
    padding-bottom: 3em;
  }
  .strandContainer{
    grid-template-columns: 1fr;
  }
  
  .card:hover {
    scale: 1.1;
  }
}

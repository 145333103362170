.parent {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5em;
    width: 100%;
    border-bottom:1px solid #d2d2d2;
  }
  .left {
    font-size: 25px;
    /* padding:1em; */
    font-weight: 800;
    color:gray;
    text-transform:uppercase;
  }

  .search{
  border:1px solid #d2d2d2 !important;
  padding:8px 16px;
  border-radius:5px;
  }

  .icon{
    font-size:25px;
    background-color: #eaeaea;
    padding:8px 16px;
    border-radius: 8px;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: .5em; */
    /* padding:1em 0; */
    padding-bottom:1em;
    >.icon{
      height:40px;
      font-size:25px;
      display: grid;
      place-content:center;
      cursor:pointer ;
      transition: .2s linear;
      :hover{
          scale: 1.02;
      }
    }
    .search{
      border:1px solid gray;
      height:40px;

    }
  
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  @media (max-width: 760px) {
    .parent {
      justify-content: flex-end;
    }
    .left {
      font-size: 0.5em;
      display: none;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
    .buttonContainer {
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
  }
  
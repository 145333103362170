

  /* Background Styling */
.contactBackgroundContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
  }
  
  .contactBackground {
    object-fit: cover;
    width: 100%;
    height: 100%;
    /* filter: blur(8px); */
  opacity: 0.5;

  }
  
  /* Main Container */
  .privacyContainer {
    position: relative;
    z-index: 10;
    max-width: 800px;
    margin: 0 auto;
    padding: 2em;
    padding-bottom:4em;

    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    .privacyHeader {
      position: relative;
      text-align: center;
      margin-bottom: 1.5em;
    }
    
    .headerBackground {
      width: 100%;
      border-radius: 10px;
      opacity: 0.2;
    }
    
    .aboutTitle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 2.5em;
      font-weight: bold;
      color: #333;
    }
  }
  
  /* Header Styling */
  .privacyContainer h1 {
    font-size: 2.5em;
    color: #333;
    text-align: center;
    margin-bottom: 1em;
  }
  
  .privacyContainer p {
    color: #555;
    line-height: 1.6;
    font-size: 1em;
    margin: 0.5em 0;
  }
  
  .privacyContainer h2 {
    font-size: 1.5em;
    color: #333;
    margin-top: 1.5em;
  }
  
  /* List Styling */
  .privacyContainer ul {
    list-style-type: disc;
    padding-left: 1.5em;
    color: #555;
    margin-top: 0.5em;
  }
  
  .privacyContainer ul li {
    margin: 0.5em 0;
  }
  
  .privacyContainer ul li strong {
    color: #333;
    font-weight: bold;
  }
  
  /* Link Styling */
  .contactLink {
    color: #0066cc;
    text-decoration: underline;
  }
  
  .contactLink:hover {
    color: #004da1;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacyContainer {
      padding: 1.5em;
    }
  
    .privacyContainer h1 {
      font-size: 2em;
    }
  
    .privacyContainer h2 {
      font-size: 1.3em;
    }
  
    .privacyContainer p, .privacyContainer ul li {
      font-size: 0.95em;
    }
  }
  
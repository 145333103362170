.container {
    box-sizing: border-box;
    text-align: right;
    border-radius: 25px;
    width: 100%;
    animation: fadeIn 0.5s ease-in-out;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  


  .form {
    display: flex;
    flex-direction: column;
    padding: 0 1em;
    gap: .5em;
    input:nth-of-type(1) {
      background-color: #fff;
      max-width: auto;
      min-width: 150px;
      max-width: 200px;
    }
  
    textarea {
      padding: 0.5em;
      max-height: 250px;
      min-height:200px;
      outline: none;
      border: 1px solid gray;
      font-size: 16px;
      color: gray;
      border: 1px solid rgb(197, 197, 197);
      border:none;
    }
    
    select {
      padding: 0.5em;
      border: 1px solid rgb(197, 197, 197);
      outline: none;
    }
  
    .buttons {
      width: 100%;
      display: flex;
      align-items: stretch;
      button {
        background-color: green;
        display: flex;
        gap: 0.5em;
        height:100%;
        align-items: center;
        justify-content: center;
        color: #fff;
        width: 100%;
    font-weight: 800;
    font-size: 16px;

      }
    }
  }
  
  .fileInputWrapper{
    .hiddenInput{
      display:none;
    }
  }

  .selectContainer{
    display: flex;
    gap:.5em;
  }
  
  .expires {
    width: 150px; 
    padding: 0.5em;
    font-size: 12px;
    color: #333;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .expires:focus {
    border-color: #0056b3; /* Optional: Focus state styling */
  }
  .lastSection {
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 1em 0;
    gap: 0.5em;
  }
  
  .colorPreview {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid gray;
  }
  
  .header {
    font-size: 20px;
    padding: .5em;
    font-weight: 800;
    width: 100%;
    color: gray;text-transform: uppercase;
    text-align: left;
    width: 100%;
    display: flex;
    border-radius: 8px 8px 0 0;
    align-items: center;
    margin-bottom:1em;
    justify-content: space-between;
    >span{
    padding: 0 .5em;
    }
    > button {
      background-color: transparent;
      font-size: 16px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
  color:rgb(255, 83, 83);
  padding:8px 16px;
      &:hover{
        background-color: #fff;
      }}
  }
  
  .clipButton {
    cursor: pointer;
    font-size: 30px;
    transform: translateY(8px);
  
    background-color: transparent;
    border: none;
    display: grid;
    place-content: center;
    color: gray;
  
    transition: color 0.3s ease;
  }
  
  .clipButton:hover {
    color: #0056b3;
  }
  
  .fileInputWrapper {
    display: inline-block;
  }
  
  
.container{
  padding:1em;
  width:100%;
  animation: fadeIn 0.5s ease-in-out;
}



.annParent{
  width:100%;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  gap:.3em;
 /* padding:0 1em; */
 max-height: 80vh;
 overflow-y: auto;
 
.mainHeader{
  display:flex;
  align-items: center;
  width:100%;
  /* padding:.5em; */
  gap:1em;
  margin-bottom:.5em;
  border-bottom:1px solid rgb(226, 226, 226);
  input{
    width:20px;
    height:20px;
    cursor:pointer;
  }
  label{
    white-space: nowrap;
    font-size:14px;
  }
}
}


.deleteButton{
  font-size:20px;
  padding:0;
  background-color: transparent;
  color:red;
  border:none;
  transform: translateY(10px);
  margin-left:.5em;
  &:hover{
    color:#000;
  background-color: transparent;
  color:rgb(254, 91, 91);

  }
}

.card{
  width:100%;
  /* border-bottom: 1px solid rgb(219, 219, 219); */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor:pointer;
  position:relative;
  padding:.2em;
  transition: .2s;
}

.card:hover{
  border:1px solid #d2d2d2;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
  border-radius: 8px;
  padding:.5em;
}

.cardBody{
  display: flex;
  gap:1em;
  justify-content: flex-start;
  align-items: center;
  padding-right:5em;

}

.annCheck{
  margin:0;
  width:20px;
  max-width: 50px;
  height:20px;
  cursor: pointer;
  padding:0;
  transform: translateX(-18px);
}

.annType{
  font-weight: 800;

}

.annType, .annTitle, .annContent {
  font-size: 12px;
  width: 170px;
  display: block; /* Use block or inline-block instead of flex */
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides the overflowing text */
  text-overflow: ellipsis; /* Displays ellipsis when text overflows */
  text-align: left;
}

.annExpiry{
  font-size:12px;
  width: 180px;
  white-space: nowrap;

}

.buttonContainer{
  display:flex;
  gap:0;
  position:absolute;
  right:0;
  >button{
    font-size:12px;
    transform: translateY(3px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
  }

  
@media  (max-width:768px) {

  .container{
  animation: fadeIn 0.5s ease-in-out;
}

.annParent{
  justify-content: flex-start;
  background: #fff;
 padding:0 0;
 padding-bottom:4em;
 
.mainHeader{
  display:flex;
  align-items: center;
  width:100%;
  padding:.5em;
  gap:.5em;
  margin-bottom:.5em;
  border-bottom:1px solid rgb(226, 226, 226);
  input{
    width:20px;
    height:20px;
    cursor:pointer;
  }
  label{
    white-space: nowrap;
    font-size:14px;
  }
}
}


.deleteButton{
  font-size:20px;
  padding:0;
  &:hover{
    color:#000;
    background-color: gray;
  }
}

.card{
  width:100%;
  border-bottom: 1px solid rgb(219, 219, 219);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor:pointer;
  position:relative;
  padding:.2em;
  transition: .2s;
}

.card:hover{
  border:1px solid #d2d2d2;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
  border-radius: 8px;
  padding:.5em;
}

.cardBody{
  display: flex;
  gap:1em;
  justify-content: flex-start;
  align-items: center;
  padding-right:5em;

}
.annCheck{
  margin:0;
  width:20px;
  max-width: 50px;
  height:20px;
  cursor: pointer;
  padding:0;
  transform: translateX(-10px);
}


.annType{
  font-weight: 600;
}

.annType, .annTitle, .annContent {
  font-size: 12px;
  width: 80px;
  display: block; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  text-align: left;
}

.annExpiry{
  font-size:12px;
  width: 80px;
  display: none;
  white-space: nowrap; 

}

.buttonContainer{
  display:flex;
  gap:0;
  position:absolute;
  right:0;
}
}
/* backgrounds */
.contactBackgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.searchInput {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  max-width: 150px;
}
.roomRow{
  &:hover{
    background-color: transparent;
  }
}

.contactBackground {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  /* filter: blur(10px); */
}

.button {
  padding: 8px 16px;
  font-size: 20px;
  border: none;
  background: linear-gradient(to right, aqua, #2edd65);
  border: 1px solid #383232;
  border-radius: 5px 20px 5px 20px;
  cursor: pointer;
  transition: 0.2s linear;
  margin: 0.5em;
}

.button:hover {
  scale: 1.05;
}

.backButton,
.joinButton {
  margin: 0.5em;
  font-size: 15px;
  padding: 4px 8px;
  border: 1px solid #d1d1d1;
  border-radius: 12px;
  transition: 0.2s linear;
  cursor: pointer;
}

.backButton:hover,
.joinButton:hover,
.button:hover {
  scale: 1.05;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card {
  border-radius: 15px;
  padding: 1em;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card th {
  min-width: 100px;
  font-size: 15px;
}

.btnSign2SpeechLeaveRoom {
  display: grid;
  place-items: center;
  z-index: 30;
  position: fixed;
  bottom: 1em;
  right: 1em;
  > span {
    color: rgb(142, 17, 17);
    font-size: 40px;
    line-height: 0.4em;
    cursor: pointer;
    transition: 0.2s linear;

    &:hover {
      scale: 1.1;
      text-shadow: 0 8px 16px rgba(178, 0, 0, 0.8);
    }
    > p {
      font-size: 12px;
    }
  }
}

.sign2speechLeaveRoom {
  display: grid;
  place-items: center;
  z-index: 30;
  position: fixed;
  bottom: 1em;
  left: 1em;
  > span {
    color: rgb(131, 19, 19);
    font-size: 40px;
    line-height: 0.4em;
    cursor: pointer;
    transition: 0.2s linear;

    &:hover {
      scale: 1.1;
      text-shadow: 0 8px 16px rgba(178, 0, 0, 0.8);
    }
    > p {
      font-size: 12px;
    }
  }
}


@media (max-width:768px){
  
.btnSign2SpeechLeaveRoom {
  display: grid;
  place-items: center;
  z-index: 30;
  position: fixed;
  bottom: 4em;
  z-index: 3;
  right: 1em;
  > span {
    font-size: 40px;
    line-height: 0.4em;
    cursor: pointer;
    transition: 0.2s linear;

    &:hover {
      scale: 1.1;
      text-shadow: 0 8px 16px rgba(178, 0, 0, 0.8);
    }
    > p {
      font-size: 12px;
    }
  }
}
}
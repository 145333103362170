/* Parent container settings */
.outer {
  height: 100vh;
  transform: translateY(-30px);
  width: 100%;
  overflow: hidden;
  display: grid;
  place-items: center;
}

/* Container settings */
.inner {
  position: relative;
  width: 300px;
  width:100vw;
  padding:1em;
  height: auto;
  overflow: hidden;
  display: grid;
  place-items: center;
  overflow-x: auto;

}

/* Slider settings */
.slider {
  width: calc(300px * var(--quantity)); /* Updated width calculation */
  height: auto;
  gap:.5em;
  display: flex;
  transition: transform 0.5s ease-in-out; /* Smooth transition for sliding */
}

/* Card settings */
/* Glassmorphism card */
.item {
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
  padding: 1em;
  font-weight: 800;
  letter-spacing: 2px;
  font-size: 20px;
  color: #006400; /* Dark green text */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2); /* Soft shadow */
  border: 1px solid rgba(0, 255, 0, 0.2); /* Light green border */
  background: rgba(255, 255, 255, 0.3);

  background-image: 
    linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 1px),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 1px);
  background-size: 20px 20px; 
  backdrop-filter: blur(10px);

}

/* Image settings */
.img {
  width: 100%;
  height: 200px;
  max-height: 200px;
  object-fit: contain;
  margin-bottom: 1em;
}

/* Button settings */
.item button {
  width: 100%;
  padding: 0.5em;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1em;
  font-weight: 800;
  font-size: 15px;
}

.item button:hover {
  background-color: #45a049;
}

/* Header and Footer settings */
.header,
.footer {
  background-color: #242629;
  width: 100%;
  overflow: hidden;

}
.header {
  padding: 0.5em 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;
  background-color: #242629;
  text-align: left;
  position:sticky;
  top:0;
  left:0;
  > span {
    display: flex;
    gap: 1em;
    align-items: center;
    .title {
      color: #eaeaea;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 30px;
    }
    .logo {
      object-fit: cover;
      border-radius: 50%;
      /* border: 5px double gray; */
      width: 75px;
      height: 75px;
    }
  }
  .backButton {
    background-color: transparent;
    font-size: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;

    color: #000;
    width: 150px;
    margin: 1em;
    background-color: #eaeaea;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
      scale: 1.03;
      background-color: #fff;
    }
  }
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
}

.note {
  background-color: transparent;
  font-size: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-weight: 800;
  position: absolute;
  top: 100px;
  left: 10px;
  width: 150px;
  margin: 1em;
  border-radius: 8px;
  padding: 8px 4px;
  color:#fff;
  background-color: rgb(8, 96, 8);

  cursor: pointer;
  &:hover {
    scale: 1.03;
  }
}

/* Buttons for sliding */
.buttons {
  display: flex;
  gap: 0.5em;
  transform: translateY(-80px);
  > button {
    background-color: transparent;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(32, 128, 17);
    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
}
.absoluteBackground {
  position: fixed;
  object-fit: cover;
  top: 80px;
  left: 0;
  height: 100vh;
  min-height: 100vh;
  min-width: 100vw;
  width: 100%;
  z-index: -1;
  /* filter: blur(10px); */
  opacity: 0.5;
}
@media (max-width: 768px) {
  /* Parent container settings */
  .outer {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: grid;
    place-items: center;
  }

  /* Container settings */
  .inner {
    position: relative;
    width: 300px;
    height: auto;
    display: grid;
    /* place-items: center; */
  }

  /* Slider settings */
  .slider {
    width: 100%; /* Updated width calculation */
    height: auto;
    display: flex;
    overflow: auto;
    flex-direction: column;
    transition: transform 0.5s ease-in-out; /* Smooth transition for sliding */
  }

  /* Card settings */
  .item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    /* overflow: hidden; */
    padding: 1em;
  }

  /* Image settings */
  .img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-bottom: 1em;
  }

  /* Button settings */
  .item button {
    width: 100%;
    padding: 0.5em;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
  }

  .item button:hover {
    background-color: #45a049;
  }

  /* Header and Footer settings */
  .header,
  .footer {
    background-color: #242629;
    padding: .5em;
    width: 100%;
    overflow: hidden;
    > span {
      display: flex;
      gap: .5em;
      align-items: center;
      .title {
        color: #eaeaea;
        text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        font-weight: 800;
        letter-spacing: 2px;
        font-size: 25px;
      }
      .logo {
        object-fit: cover;
        border-radius: 50%;
        /* border: 5px double gray; */
        width: 75px;
        height: 75px;
      }
    }

    .backButton {
      background-color: transparent;
      font-size: 14px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
  
      width:auto;
      margin: 0;
      background-color: #eaeaea;
      border-radius: 8px;
      padding: 8px ;
      cursor: pointer;
      &:hover {
        scale: 1.03;
        background-color: #fff;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .note {
    font-weight: 800;
    font-size: 15px;
    width: 100%;
    text-align: left;
    padding: 0.5em;
  }

}

.joinRoomContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: flex-start;
padding:1em;
  .joinRoomHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    > span {
      color: gray;
      font-size: 25px;

      text-transform: uppercase;
      font-weight: 800;
      text-align: left;
    }
    .closeButton {
      background-color: transparent;
      font-size: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
      color: rgb(255, 83, 83);
      width: 150px;
      border-radius: 8px;
      padding: 8px;
      cursor: pointer;
      &:hover {
        scale: 1.03;
        background-color: #fff;
      }
    }
  }

  /* join room sub headffer */
  .joinRoomSubHeader {
    display: flex;
    gap: 0.5em;

    > p {
      padding-right: 1em;
      border-right: 1px solid #d2d2d2;
    }
  }
  /* join room  main*/
  .joinRoomMain {
    width: 100%;
    box-sizing: border-box;
    >input{
        padding:.5em;
        font-size: 20px;
    }
    .invalidCode,.successCode, .failedCode{
      width:100%;
      padding:.5em 0;
      text-align: left;
    }

    .invalidCode{
      color:gold;
    }
    .successCode{
      color:green;
    }
    .failedCode{
      color:maroon;
    }
  }
  .joinRoomSubmitButton {
    padding: 8px 16px;
    font-size: 20px;
    width:100%;
    margin:0;
    margin-top: 1em;

    gap: 0.5em;
    color: #fff;
    display: inline-block;
    font-weight: 800;
    vertical-align: center;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

.contactHeader {
  margin-top: 1em;
  display: none;
  position: relative;
  > h2 {
    font-size: 50px;
    font-weight: 800;
    color: #242629;
    text-align: left;
  }
}

.contactContainer {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  width: 100vw;
  padding: 1em;
  padding-top: 2em;
  padding-bottom: 4em;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

/* backgrounds */
.contactBackgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1;
}

.contactBackground {
  object-fit: cover;
  width: 100%;
  height: 100%;
  /* filter: blur(10px); */
  opacity: 0.5;
}

/* left  */
.contactInformations {
  flex: 1;
  .contactTemplateImage {
    object-fit: cover;
    height: 500px;
  }
}

/* form */
.contactForm {
  text-align: left;
  flex: 1;
  padding: 1em 3em;
  gap: 0.5em;
  border-radius: 25px;
  > h4 {
    font-size: 40px;
    font-weight: 800;
    color: green;
    margin-bottom: 0;
  }
  > p {
    margin-bottom: 0.5em;
  }

  .submitButton {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
    color: #fff;
    background-color: green;
    cursor: pointer;
  }
}

.successMessage {
  color: green;
  font-weight: bold;
  margin: 0.5em 0;
  text-shadow: 0 4px 8px rgba(4, 255, 4, 0.3);
  text-align: center;
}

.errorMessage {
  color: #dc3545;
  font-weight: bold;
  margin: 0.5em 0;
  text-align: center;
}

.formGroup {
  > label {
    color: #242629;
  }
  .email {
    max-width: 300px;
    width: 300px;
  }
  > textarea {
    border: 1px solid rgb(197, 197, 197);
    width: 100%;
    outline: 1px solid rgb(233, 233, 233);
    min-height: 100px;
    padding: 1em;
    border-radius: 8px;
  }
}

@media (max-width: 768px) {
  .contactContainer {
    padding: 1em;
  }
  /* left  */
  .contactInformations {
    flex: 1;
    display: none;
  }

  /* form */
  .contactForm {
    padding: 1em 2em;
    > h4 {
      font-size: 40px;
    }

    .submitButton {
      font-size: 16px;
    }
  }
}

.checkStudentsContainer{
  width:100%;
  padding:1em;
}

.selectContainer{
  display: flex;
  gap:.5em;
  width:100%;
  select{
    color:gray;
    background-color: #fff;
    border:1px solid rgb(192, 192, 192);
    border-radius: 8px;
    max-width: 200px;
    max-height: 45px;
    padding: 8px 16px;
    height:45px;
    min-height: 45px;
    background-color: #fff;
  
    color: gray;
    font-weight: 800;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); */
  }
  option{
    color:gray;
  }
  >span{ 
    >input{
      border:1px solid rgb(200, 200, 200);
      outline:none;
      border-radius: 8px;
      color:gray;
      
    }
  }
  .averageButton{
    padding:8px 16px;
    font-weight: 800;
    letter-spacing: 2px;
    font-size: 16px;
    border:1px solid #d2d2d2;
    background-color: green;
    height:45px;
    color:#fff;
    &:hover{
      scale:1.1;
      box-shadow: 0 0 8px rgba(0,0,0, 0.4);
    }
  }
}

.parent {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  height: auto;
  overflow-y: auto;
  padding:1em 0;
  justify-content: space-between;
  > button {
    border: 1px solid gray;
    padding: 1em;
  }
}

.button{
  display: flex;
  gap:.5em;
  align-items: center;
  font-size:16px;
  background-color: green;
  color:#fff;
  padding: 8px 16px;
  font-weight: 800;
  height:45px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.button:hover{
  background-color: green;
  color:#fff;
}
.filter {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.searchContainer{
  display: flex;
  align-items:center;
  margin:1em;
  >.searchIcon{
    font-size:25px;
    color:gray;
    cursor: pointer;
  }
}
.search {
  border-radius: 25px;
  border: 1px solid #d2d2d2;
  padding:0 .5em;
  font-size: 16px;
  max-height: 45px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

}
.search:focus {
  outline: none;
}

@media (max-width: 768px) {
  .parent {
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0;
    gap: 0.5em;
    margin-bottom: 0.5em;
    background-color: #fff;
  }
  .filter {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  .search {
    width: 100%;
    padding: 0.5em;
    height:40px;
    border-radius: 5px;
    border: 1px solid #eaeaea;
  }
  .search:focus {
    outline: none;
  }

  .selectContainer{
    display: flex;
    gap:.5em;
    width:100%;
    select{
      max-width: 100px;
      padding: 0;
      max-height:40px;
    }
  

    .averageButton{
      padding:8px 16px;
      font-weight: 800;
      letter-spacing: 2px;
      font-size: 16px;
      border:1px solid #d2d2d2;
      background-color: green;
      color:#fff;
      &:hover{
        scale:1.1;
        box-shadow: 0 0 8px rgba(0,0,0, 0.4);
      }
    }
  }
}

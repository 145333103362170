/* settings.module.css */
.settingContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .buttonContainer {
    display: flex;
    gap: 15px; /* Space between buttons */
    justify-content: center; /* Center the buttons horizontally */
    margin-top: 30px;
  }
  
  .mercy,
  .codeBomber {
    padding: 12px 24px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px); /* Glassmorphism blur effect */
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Button hover effects */
  .mercy:hover,
  .codeBomber:hover {
    transform: scale(1.05); /* Slightly grow the button */
    background-color: rgba(255, 255, 255, 0.3); /* Darker background on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    color:green;
  }

  .codeBomber:hover{
    color:red;
  }
  
  .mercy {
    background-color: #4CAF50;
  }
  
  .codeBomber {
    background-color: #f44336;
  }
  
  .confirmationModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    z-index: 100;
    align-items: center;
  }
  
  .modalContent {
    background-color: rgba(255, 255, 255, 0.9); /* Light background with transparency */
    padding: 30px;
    border-radius: 12px;
    text-align: center;
    width: 80%;
    max-width: 500px;
    backdrop-filter: blur(10px); /* Glassmorphism effect */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }
  
  .modalActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .confirmButton,
  .cancelButton {
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease;
    backdrop-filter: blur(10px); /* Glassmorphism blur effect */
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .confirmButton {
    background-color: #f44336;
  }
  
  .cancelButton {
    background-color: #4CAF50;
  }
  
  .confirmButton:hover,
  .cancelButton:hover {
    transform: scale(1.05); /* Slightly grow the button */
    background-color: rgba(255, 255, 255, 0.3); /* Darker background on hover */
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    color:#000
  }
  
  .loader {
    margin-top: 20px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .error {
    margin-top: 20px;
    color: red;
    font-size: 16px;
  }
  
.parent{
    width:100%;
    box-sizing: border-box;
    height:auto;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height:100vh;
    justify-content: flex-start;
    padding:.5em 0;
  padding-bottom:3em;

}

.container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:.5em;
}

.card{
    border-radius: 12px;
    border:1px solid #d2d2d2;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap:1.5em;
    padding:1em;
    .main{
      color:rgb(108, 201, 125);
      font-size:20px;
    }
}

.buttons{
  display: flex;
width:100%;
font-size:12px;
justify-content: flex-end;
gap:.5em;
.infoButton{
  font-weight: 600;
}
}

.button{
  font-size:15px;
}

@media (max-width:760px) {
  .container{
    width:100%;
    display: grid;
    grid-template-columns: 1fr;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

.button{
    padding:8px 16px;
    border:none;
    border-radius: 5px;
    transition:.2s linear;
    font-size:15px;
    cursor: pointer;
    border:1px solid #d2d2d2;
    background-color: white;
}

.button:hover{ 
    scale:1.05;
}

.header {
    padding: 1em;
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-weight: 800;
    color: #383232;
    border-bottom: 1px solid #afafaf;
    margin-bottom:1em;
    position:relative;
  }
 
/* modal conatiner */
.modalContainer {
  width: 100%;
  text-align: center;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;
  overflow-y: auto;
}

/* stud info */
.infoContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
}

.header {
  color: gray;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* padding: 0.5em; */
  >span{
    padding:1em;
  }
  .modalButton {
    background-color: transparent;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(255, 83, 83);
    padding: 8px 16px;
    margin:1em;
    &:hover {
      background-color: #fff;
    }
  }
}
.studInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 300px;
  text-align: left;
  text-transform: capitalize;
  padding: 1em 2em;
  font-size: 12px;
  .teacherEmail{
    text-transform: none;
  }
}

.studImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  min-height: 160px;
}

.label {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0.3em 0;
  color: gray;
}

.image {
  object-fit: cover;
  border-radius: 15px;
  width: 150px;
  height: 150px;
  border: 1px solid #888;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 0.5em;
  justify-content: flex-end;
  /* padding:0 .5em; */
  .emailButton {
    background-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(23, 125, 42);
    padding: 8px 16px;
    margin:0;
    margin:0 1em;
    font-size: 20px !important;

    &:hover {
      background-color: #fff;
    }
  }
  .notifButton {
    background-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(23, 125, 42);
    font-size: 20px !important;

    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
  .dropButton {
    background-color: transparent;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    font-size: 16px !important;
    display: flex;
    margin:1em;
    align-items: center;
    justify-content: center;
    gap:.5em;


    color: rgb(255, 83, 83);
    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
}

.buttons button {
  font-size: 25px;
}

.buttons button:nth-child(3) {
  font-size: 15px;
}

/* email sending */
.emailParent {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.biInputGroup {
  display: flex;
  gap: 0.3em;
  align-items: center;
  justify-content: flex-start;
  > label {
    max-width: 50px;
    color: gray;
  }
}
.emailParent > form {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 100%;
  height: auto;
  gap: 0.5em;
  text-align: left;
  padding: 0.5em;
  border-radius: 15px;
}
.emailParent > form span {
  display: flex;
  align-items: center;
}

.emailParent > form span > label {
  font-size: 12px;
  color: gray;
  width: 100%;
  text-align: left;
}

.emailParent > form span:nth-of-type(2),
.emailParent > form span:nth-of-type(3) {
  display: flex;
  flex-direction: column;
}

.emailParent > form span textarea {
  margin-top: 0.5em;
  width: 100%;
  height: 200px;
  border: 1px solid #eaeaea;
  padding: 0.5em;
}

/* mail */
.submitButton {
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  font-size: 16px;
  background-color: green;
  color: #fff;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@media (max-width: 768px) {
  /* modal conatiner */
  .modalContainer {
    width: 100%;
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    background-color: #fff;
    min-height: 100vh;
    overflow-y: auto;
    padding: 2em 0.5em;
    padding-top: 3em;
  }
}

 .uploadContentContainer {
    width: 100%;
    padding: 1em;
    position: relative;
    overflow-y: auto; /* Enables scrolling when content exceeds height */
    max-height: 600px; /* Limits height to 600px */
    overflow-x: hidden;
    animation: fadeIn 0.5s ease-in-out;
    .header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-weight: 800;
      font-size: 20px;
      /* letter-spacing: 1.5px; */
      /* padding-bottom: 1em; */
      text-transform: uppercase;
      color:gray;
    }
  
    .backButton {
      padding: 8px 16px;
      right: 0;
      background-color: #fff;
     
color:rgb(255, 83, 83);
      font-weight: 800;
      border-radius: 8px;
      display: grid;
      font-size: 16px;
      place-content: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      gap:.5em;
    }
  
    .colorContainer {
      display: flex;
      align-items: stretch;
      width: 100%;
      gap: 0.3em;
  
      > select {
        width: 50%;
      }
  
      .colorPreview {
        width: 50px;
        height: auto;
        border-radius: 10px;
      }
    }
  
    * {
      font-size: 10px;
    }
  
    .form {
      padding:0;
      > input:first-of-type {
        max-width: 50%;
      }
  
      > textarea {
        border: 1px solid #d2d2d2;
        padding: 0.5em;
        max-width: 100%;
        max-height: 200px;
        /* margin: 1em 0; */
        min-height: 150px;
        overflow-y: auto; /* Allows scrolling in the textarea if content exceeds height */
      }
  
      .customVideo,
      .customImage {
        display: flex;
        gap: 0.5em;
        align-items: center;
        padding: 1em;
        background-color: gray;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
  
        &:hover {
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        }
      }
  
      .video,
      .image,.file {
        display: none;
      }
  
      .selectContainer {
        display: flex;
        gap: 0.5em;
        .notif{
            font-size:8px;
            color:green;
        }
      }
  
      .buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        /* padding: 1em; */
  
        > .submitButton {
          padding: 8px 16px;
          background-color: green;
          color: #fff;
          font-weight: 800;
          border-radius: 8px;
          display: grid;
          font-size: 16px;
          margin:0;
          width:100%;
          place-content: center;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        }
      }
    }
  }

  .previews{
    position:absolute;
    gap:1em;
    box-sizing: border-box;
    padding:1em 0;
    margin-top:2em;
    width:100%;
    text-align: center;

    >video{
        object-fit: cover;
        height:200px;
        transform:translateX(-17px);
        width:calc(100%-100px);
        max-width: 100%;
        margin-bottom:1em;
        max-height: 200px;
        min-width: calc(100% - 3em);
        /* border:5px double gray; */
        border-radius: 12px;
    }
    >img{
        object-fit: cover;
        height:auto;
        width:100%;
        max-width: 100%;
        transform:translateX(-17px);

        max-height: 250px;

    }
    >iframe{
        outline:none;
        /* border:5px double gray; */
        width: auto;
        max-width: 100%;
        transform:translateX(-17px);
        
        height:auto;
        min-height: 400px;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  
.container{
  width:100%;
}

button:hover{
  scale:1.02;
  background-color: transparent;;
}

.firstSection{
  display:flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  padding:1em;
  /* border:1px solid gray; */
  .title{
    font-size:20px;
    font-weight: 800;

    color:gray;
    text-transform: uppercase;
  }
  .closeButton{
    font-size: 16px;
    display: flex;
    align-items: center;
    gap:.5em;
    border:none;
    &:hover{
      background: transparent;
    }
  }
}

.secondSection{
  /* padding:1em; */
  width:100%;
  border-radius:  20px 20px  20px 20px;
}

form{
  display: flex;
  flex-direction: column;
  gap:1em;
  padding:0 1em;
justify-content: center;
  .profile{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row-reverse;
    width: 100%;
    text-align: left;
    gap:1em;
    >img{
      width:100px;
      height:100px;
      object-fit: cover;
    }
  }
  >.name{
    display: grid;
    gap:1em;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width:100%;
    justify-content: center;
    white-space: no-wrap;
    align-items: center;
    
    flex-wrap:wrap;
    .input{
      padding:1.5em;
  border:1px solid gray;

    }
  }
  >.button{
    border:1px solid gray;
    padding:1em;
    border:none;
    font-size: 16px;
    font-weight: 600;
  }
}
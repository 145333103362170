.parent {
}

.filterContainer {
  /* margin: 1em 0; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.filterContainer label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.filterContainer select {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}

.filterContainer select:focus {
  border-color: #242629;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.table {
  width: 100%;
  border-collapse: collapse;
  margin: 1em 0;
  font-size: 16px;
  background-color: #ffffff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.table th,
.table td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 12px;
}

.table th {
  background-color: #242629;

  color: #d2d2d2;
  text-transform: uppercase;
}

.table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table tr:hover {
  background-color: #f1f1f1;
}

.table td {
  vertical-align: middle;
}

.table td .buttons {
  display: flex;
  gap: 0.5em;
}

.buttons button {
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buttons button:first-child {
  background-color: green;
  font-weight: 600;
  color: white;
}

.buttons button:first-child:hover {
  background-color: #0056b3;
}

.buttons button:last-child {
  background-color: #e63946;
  color: white;
}

.buttons button:last-child:hover {
  background-color: #b02935;
}

@media (max-width: 768px) {
  .table {
    font-size: 14px;
  }

  .buttons button {
    padding: 6px 10px;
    font-size: 12px;
  }

  .filterContainer select {
    font-size: 12px;
    padding: 6px 8px;
  }
}

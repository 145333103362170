/* default button styles */
.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5em;
  gap:.5em;
  white-space: nowrap;
  align-items: center;
}

button,.submitButton,.backButton {
  padding: 6px 12px;
  border-radius: 5px;
  transition: 0.2s linear; 
  cursor: pointer;
  color: #383232;
  font-size: 16px;
  border: none;
  &:hover{
    color:#242629;
  }
}

.backButton{
  display: flex;
  gap:.5em;
  align-items: center;
  justify-content: center;
}

.submitButton{
  background:green;
  color:#fff;
  font-weight: 600;
  &:hover{
    background-color: green;
    color:#fff;
  }
}

.success{
  color:green;
  font-size:16px;
  width: 100%;
  text-align: right;
  padding:1em;
}
/* default header style */
.header {
  /* padding: 0.5em; */
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 800;
  color: #383232;
  padding:0 1em;
  /* border-bottom: 1px solid #afafaf; */
  margin: 1em 0;
}

/* default parent or container style */
.parent {
  background: #fff;
  /* padding: 0 2em; */
  border-radius: 8px;
  width: 100%;
  /* max-width: 600px; */
  box-sizing: border-box;
  overflow: auto;
  max-height: 90vh;
}

/* forms deafult layout */
.form {
  display: flex;
  flex-direction: column;
  gap: 1em;

}

/* input default styles */
.select {
  appearance: none;
  background: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path d="M4 6l4 4 4-4H4z" fill="%23333"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem auto;

}

.biInputGroup,
.triInputGroup {
  display: flex;
  gap: 1rem;
}

.biInputGroup input,
.triInputGroup input {
  flex: 1;
  border: 1px solid gray;
  font-size: 16px;

} 

input[type="text"], 
input[type="email"],
input[type="password"],
input[type="file"],
select,
input[type="color"],
input[type="date"],
input[type="checkbox"],
input[type="radio"],textarea {
  font-size: 16px !important;
  padding: 0.75rem;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid gray;

}

.file {
  padding: 0.5em;
  font-size: 12px;
}

.textarea {
  padding: 1em;
  border: 1px solid gray;
  font-size: 12px;
  max-width: 100%;
  max-height: 200px;
}

/* individual container with label */
.cLabel,
.rLabel {
  display: flex;
  font-size: 10px;
  gap: 0.5em;
  /* padding: 0.5em; */
  min-width: 100%;

}
.cLabel {
  flex-direction: column;
  text-align: left;
  font-size:16px;
}
.rLabel {
  justify-content: flex-start;
  align-items: center;
}

/* preview layout and styling */
.previews {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height:auto;
  box-sizing: border-box;
}

.imgPreview {
  max-width: 100%;
  height: auto;
}

.vidPreview {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
/* default button styles */
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.5em;
}

button,.submitButton,.backButton {
  padding: 6px 12px;
  border-radius: 5px;
  transition: 0.2s linear; 
  cursor: pointer;
  color: #383232;
  font-size: 15px;
  border: none;
}

button:hover {
  background-color: #5ef59d;
}

/* default header style */
.header {
  padding: 0.5em;
  width: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: 800;
  color: #383232;
  border-bottom: 1px solid #afafaf;
  margin: 1em 0;
}

/* default parent or container style */
.parent {
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  border: 1px solid #e4e4e4;
}

/* forms deafult layout */
.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* input default styles */
.select {
  appearance: none;
  background: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path d="M4 6l4 4 4-4H4z" fill="%23333"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem auto;
}

.biInputGroup,
.triInputGroup {
  display: flex;
  gap: 1rem;
}

.biInputGroup input,
.triInputGroup input {
  flex: 1;
  min-width: 150px;
} 

input[type="text"], 
input[type="email"],
input[type="password"],
input[type="file"],
select,
input[type="color"],
input[type="date"],
input[type="checkbox"],
input[type="radio"] {
  font-size: 12px;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.file {
  padding: 0.5em;
  font-size: 12px;
}

.textarea {
  padding: 1em;
  border: 1px solid #ccc;
  font-size: 12px;
}

/* individual container with label */
.cLabel,
.rLabel {
  display: flex;
  font-size: 10px;
  gap: 0.5em;
  padding: 0.5em;
}
.cLabel {
  flex-direction: column;
  text-align: left;
}
.rLabel {
  justify-content: flex-start;
  align-items: center;
}

/* preview layout and styling */
.previews {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height:auto;
  box-sizing: border-box;
}

.imgPreview {
  max-width: 100%;
  height: auto;
}

.vidPreview {
  max-width: 100%;
  height: auto;
}
}

.button {
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.2s linear;
  cursor:pointer;
  border:none;
  background-color: #d2d2d2;
  max-width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.button:hover {
  scale: 1.05;
  /* border:1px solid #9d9d9d; */
  background-color: #d2d2d2;
color:#000;
}


@media (max-width:768px) {
  .button {
    padding: 8px 16px;
  border:none;
    border-radius: 5px;
    font-size:12px;
    /* white-space: nowrap; */
    transition: 0.2s linear;
    cursor:pointer;
  }
}
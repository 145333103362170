.footer {
  height: 60px;
  position: fixed;
  z-index: 30;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
}

.footerBanner {
  position: absolute;
  left: 0;
  display: flex;
  place-content: center;
  align-items: center;
  bottom: 16px;
  gap: 0.5em;
  border: none;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.5);
  z-index: 5;
  width: 100px;
  height: 30px;
  border-radius: 0 25px 25px 0;
  transition: 0.3s;
  background-color: green;
  &:hover {
    scale: 1.05;
    font-weight: 600;
  }

  .footerBannerImg {
    max-width: 150px;
    max-height: 80px;
    object-fit: cover;

    cursor: pointer;
    display: none;
  }

  .footerBannerTitle {
    position: absolute;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    font-size: 14px;
    cursor: pointer;
    > span {
      padding: 0;
      transform: translateY(1.5px);
    }
  }
}

.footerMenuContainerbox {
  margin-left: 120px;
  display: flex;
  gap: .5em;
  position: relative;
  padding:.3em;
  opacity: 0;
  transform: translateX(-200px);
  background: linear-gradient(to right,transparent,#fff,#fff,#fff);
  border-radius: 0 15px 15px 0;

  a {
    font-size: 12px;
    transition: 0.5s;
    color: green;
    white-space: nowrap;
    border-radius: 12px;
    padding: 0 0.5em;

    &:hover {
      color: #242629;
      font-weight: 600;
      text-decoration: underline;

      text-shadow: 0 8px 16px rgba(0 204, 10, 0.5);
    }
  }
}

.footer .footerRight {
  font-size: 14px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .footer .footerRight {
    font-size: 10px;
    display: none;
  }

  .footerBanner {
    left: 0;
    bottom: 40px;
    border: none;
    box-shadow: none;
    z-index: 5;
    width: 100px;
    height: 30px;
    background-color: green;
    .footerBannerTitle {
      color: #fff;
      font-size: 14px;
      > span {
        padding: 0;
        transform: translateY(1.5px);
      }
    }
  }

  .footerMenuContainerbox {
    margin-left: 0;
    display: flex;
    width: 100%;
    padding: 0.5em;
    bottom: 0;
    left: 0;
    margin: 0;
    gap: 0.3em;
    position: fixed;
    bottom: 0;
    opacity: 0;
    background-color: #fff;
    background: #fff;
  border-radius: 0 ;

    a {
      font-size: 10px;
      white-space: nowrap;
    }
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
}

.slideIn {
  animation: slideIn 0.6s forwards;
  opacity: 1;
}

.slideOut {
  animation: slideOut 0.6s forwards;
  opacity: 0;
}

.toolContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 25px;
  max-height: 200px;
  padding: 3%;
  transition: 0.2s linear;
  cursor: pointer;
  position: relative;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);

}

.toolContainer:hover {
  scale: 1.1;
}

.toolContainer .toolImage {
  border-radius: 50%;
}

.toolContainer > .toolTitle {
  font-size: 15px;
  margin: 5px 0;
}

.toolContainer .descriptionParent {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0%;
  padding: 20px;
  border-radius: 15px;
  transition: 0.2s linear;
  display: none;
}

.toolContainer .descriptionContainer > .toolTitle {
  font-size: 15px;
}
.toolContainer .descriptionContainer .toolParagraph {
  font-size: 12px;
}

.toolContainer:hover .descriptionParent {
  height: 100%;
  display: block;
}

.parent {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  overflow-y: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  /* padding: 1em 0; */
  padding-bottom: 3em;
}

.parent p {
  font-size: 15px;
  width: 100%;
  text-align: left;
  padding: 1em;
}

.container {
  display: grid;
  width: 100%;
  gap: 1em;
  grid-template-columns: 1fr 1fr;
  /* padding:0 1.5em; */
}

.card {
  border-radius: 12px;
  border: 1px solid #d2d2d2;
  background-color: white;
  min-height: 150px;
  min-width: 150px;
  display: grid;
  place-items: center;
  transition: 0.2s linear;
  margin: 0.5em 0;
  padding: 0.5em;
  position: relative;
}

.header {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > .icon {
    font-size: 30px;
    color: green;
    cursor: pointer;
  }
}

.icon {
  font-size: 25px;
  cursor: pointer;
}

.main {
  width: 100%;
}

.content {
  font-size: 25px;
  font-weight: 800;
  /* letter-spacing: 3px; */
  color:gray;

  /* text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.imgContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  max-height: 150px;
  position: relative;
  border-radius: inherit;
}
.image {
  min-width: 100%;
  max-width: 100%;
  min-height: 200px;
  max-height: 200px;
  width: auto;
  height: auto;
  object-fit: cover;
}

/* dropdown */
.teachers {
  position: absolute;
  inset: 55px 0 0 0;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: white;
  .addCollab {
    color: green;
    font-size: 20px;
  }
  .removeCollab {
    color: red;
    font-size: 20px;
  }
  .save{
    font-size:14px;
    background-color: green;
    color:#fff;
    &:hover{
      background-color: green;
    }
  }
}
.teachers::-webkit-scrollbar {
  width: 12px;
}

.teachers::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.teachers::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.teachers::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.teachers::-webkit-scrollbar-button {
  width: 12px;
  height: 12px;
}

.teachers::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
  border-radius: 15px;
}

.teacher {
  display: flex;
  font-size: 12px;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  padding:0 1em 0 0;

}

.teachers > .top {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  height: 50px;
  top: 0;
  position: sticky;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding: 1.5em 1em;
}

.teachers > .top > span {
  font-weight: 700;
  font-size: 18px;
}

.teachers > .top > .buttons {
  display: flex;
  gap: 0.5em;
  align-items: flex-end;
}

.teachers > .bot {
  display: flex;
  inset: 0 0 0 0;
  position: absolute;
  font-size: 15px;
  text-align: left;
}

/* collaborator panel */
.teachers > .bot > .collaborators {
  gap: 0.5em;
  padding: 0.5em 1em;
  font-size: 18px;
  position: absolute;
  inset: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.teacher button {
  font-size: 15px;
  color:#242629;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #eaeaea;
  border:none !important;
}

/* !collaborator panel */
.notCollaborators {
  padding: 0.5em 1em;
  position: absolute;
  background-color: white;
  inset: 50px 0 0 0;
  text-align: left;
  width: 100%;
}

.notCollaborators > .teacher {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: flex-start;
  > button {
    border: 1px solid gray;
    padding: 1dm;
  }
  > input[type="checkbox"] {
    transform: scale(1.5);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.notCollaborators > .teacher > .botLeft {
  min-width: 150px;
  width: 100%;
  font-size: 14px;
}


.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  width: 100%;
  button {
    background-color: transparent;
    margin:0;
    &:hover {
      scale: 1.05;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      background-color: transparent;
    }
  }
}

@media (max-width: 760px) {
  .container {
    grid-template-columns: 1fr;
  }
  .parent {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 100vh;
    justify-content: flex-start;
    padding: 1em 0;
    padding-bottom: 3em;
  }

  .parent p {
    font-size: 15px;
    width: 100%;
    text-align: left;
    padding: 1em;
  }

  .container {
    display: grid;
    width: 100%;
    gap: 0.5em;
    grid-template-columns: 1fr;
  }

  .card {
    border-radius: 12px;
    border: 1px solid gray;
    background-color: white;
    min-height: 150px;
    min-width: 150px;
    display: grid;
    place-items: center;
    transition: 0.2s linear;
    margin: 0.5em 0;
    padding: 0.5em;
    position: relative;
  }

  .header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .main {
    width: 100%;
  }

  .content {
    font-size: 18px;
    font-weight: 800;
    color:gray;
  }

  .imgContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    max-height: 150px;
    position: relative;
    border-radius: inherit;
  }
  .image {
    min-width: 100%;
    max-width: 100%;
    min-height: 200px;
    max-height: 200px;
    width: auto;
    height: auto;
    object-fit: cover;
  }

  /* dropdown */
  .teachers {
    position: absolute;
    inset: 55px 0 0 0;
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
  }
  .teachers::-webkit-scrollbar {
    width: 12px;
  }

  .teachers::-webkit-scrollbar-track {
    background: #f1f1f1;

    border-radius: 10px;
  }

  .teachers::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 3px solid #f1f1f1;
  }

  .teachers::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .teachers::-webkit-scrollbar-button {
    width: 12px;
    height: 12px;
  }

  .teachers::-webkit-scrollbar-corner {
    background-color: #f1f1f1;
    border-radius: 15px;
  }

  .teacher {
    display: flex;
    font-size: 15px;
    align-items: center;
    gap: 0.5em;
    width: 100%;
  }

  .teachers > .top {
    border-bottom: 1px solid #383232;
    width: 100%;
    height: 30px;
    top: 0;
    position: sticky;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    padding: 1.5em 1em;
  }

  .teachers > .top > span {
    font-weight: 700;
    font-size: 18px;
    color:gray;
  }

  .teachers > .top > .buttons {
    display: flex;
    gap: 0.5em;
    align-items: flex-end;
  }

  .teachers > .bot {
    display: flex;
    inset: 0 0 0 0;
    position: absolute;
    font-size: 15px;
    text-align: left;
    
  }

  /* collaborator panel */
  .teachers > .bot > .collaborators {
    gap: 0.5em;
    padding: 0.5em 1em;
    font-size: 15px;
    position: absolute;
    inset: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .teacher {
    .info{
      border:none;
      background-color: #fff;
    font-size: 15px;
      
    }
  }

  /* !collaborator panel */
  .notCollaborators {
    padding: 0.5em 1em;
    position: absolute;
    background-color: white;
    inset: 50px 0 0 0;
    text-align: left;
    width: 100%;
  }

  .notCollaborators > .teacher {
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: flex-start;
  }

  .notCollaborators > .teacher > .botLeft {
    min-width: 150px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5em;
    padding: 0.5em;
    width: 100%;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

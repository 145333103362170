/* speech2sign.css */

/* backgrounds */
.contactBackgroundContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: -1; 
}

.contactBackground {
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: 0.5;

  /* filter: blur(10px); */
}

/* speech2sign container */
.translate-grandparent {
  width: 100%;
  display: grid;
  place-content: center;
  padding: 0 4em;
}

.translate-logo-container {
  text-align: center;
  border: 1px solid white;
  padding:.5em;
  border-radius: 8px;
  display:none;
}
.translate-logo-container .translateLogo {
  font-weight: 800;
  font-size: 25px;
}

.speech2sign-translate-parent{
  display: flex;
  align-items: center;
  gap:10em;
  width: 100%;
}

.translate-hand-container {
  border-radius: 12px;
  width: 100%;
  margin-top: 0;
  display: flex;
  background: rgba(24, 26, 29, 1); 
  backdrop-filter: blur(10px);
  align-items: center;
  justify-content: center;
  padding: 2em;
  min-width: 250px;
  min-height:250px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  border: 1px solid rgba(255, 255, 255, 0.3); 
}


.translate-default-hand {
  width: 250px;
  height: 250px;
  min-width: 250px;
  min-height:250px;
}

.translate-hand-image {
  width: 250px;
  height: 250px;
  min-width: 250px;
  min-height:250px;
  object-fit: contain;
}

.hand-animation {
  animation: fadeIn 1s ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.translate-text-container {
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--bg-color);
  padding:1em;
  width:400px;
margin-top: 3em;

}

.translate-text-container textarea {
  border-radius: 8px;
  /* resize: none; */
  overflow: hidden;
  min-height: 50px;
  outline: none;
  border: none;
  padding: 10px;
  font-size: 14px;
  width: 100%;
  overflow-y: auto;

}
.speech2sign-translate-text-parent{
  display: flex;
  flex-direction: column;
}
 .speedContainer {
  display: flex;
  gap:.2em;
  align-items: center;
  justify-content: center;
}

.btnChatboxToggler{
  margin:0;
  position:relative;
  background-color: green;
  color:#fff;
  display: flex;
  align-items: center;
  flex-direction: row !important;
  white-space: nowrap;
  gap:.5em;
  border:none;
  border-radius: 5px !important;
  font-weight: 600;
  &:hover{
    background-color: green;
    color:#fff;
    scale: 1.05;
  }
  .replyNotifierIcon{
    position:absolute;
    width:20px;
    display: grid;
    place-content: center;
    place-items: center;
    align-items: center;
    justify-content: center;
    height:20px;
    border-radius: 50%;
    border: 5px double green;
    color:greenyellow;
    background-color: greenyellow;
    right:-.5em;
    top:-.5em;
  }
}

.translate-buttons-container button:hover,
.translate-goback:hover {
  scale: 1.1;
}

.buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.speed-options {
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.buttons-container{
  display: flex;
  gap:.5em;
}
.buttons-container button{
  padding:8px 16px;
  border-radius: 12px;
  outline: none;
  border: none;;
  background-color: greenyellow;
  color:#000;
  cursor: pointer;
  transition:.2s linear;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.buttons-container button:hover{
scale:1.1;
background-color: greenyellow;

}

/* previous messages from teachers */
.histories-messages-container{
  width:100%;
  position: relative;
  display: flex;
  gap:1em;
  max-height:250px;
  height:250px;
  backdrop-filter:blur(10px);
  background:rgba(255,255,255,0.3);
  border-radius: 12px;
  overflow-y: auto;
  margin:1em 0;
  scrollbar-width: 15px;
}
.histories-messages-container::-webkit-scrollbar{
  display: block;
  width:15px;
}
.previous-messages, .previous-replies{
  background:rgba(255,255,255,0.6);
  background: transparent;
  padding:0;
  margin-bottom: .5em;
  margin:.5em;
  max-height:500px;
  flex:1;
  gap:2em;
  overflow-y: auto;
  text-align: left;
  >.previous-span-container{
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr; */
    flex-wrap: wrap;
    gap:.5em;
    padding:0 .5em;
   

  }
}

/* chatbox */
.chatbox-replies-container{
  position:fixed;
  z-index: 10;
  top:0;
  left:0;
  width: 100%;
  height:100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.previous-replies{
  position: fixed;
  min-width: 50%;
  max-width: 600px;
  background-color: #fff;
  left:0;
  min-height: 100vh;
  z-index: 5;
  padding:1em; 
  margin:0; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4), 0 4px 16px rgba(0, 0, 0, 0.2);
  top:0;
}

.history-box{
  cursor: pointer;
  transition: .2s;
    padding:.5em;
    font-size: 10px;
    border:1px solid #000;
  border-radius: 5px;
  &:hover{
    scale:1.02;
    box-shadow:0 4px 8px rgba(0, 0, 0, 0.2);
  }
}
.history-header{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 800;
  font-size: 20px;
  position: relative;
  color: #242629;
  padding:.3em;
  gap:1rem;
  .historyResetButton{
    color:#fff;
    background-color: green;
    font-weight: 600;

  }
  >span{
    display: flex;
    gap:1em;
    >button{
      background-color: green;
      color:#fff;
    font-weight: 600;

    }
  }
  >.btnClose{
    position: absolute;
    color:#fff;
    font-weight: 600;
    background-color: green;
    top:0;
    right:0;
  }
}

.recognized-message{
  background:rgba(255,255,255,0.6);
  border-radius: inherit;
  padding:0;
  margin-bottom: .5em;
  margin:.5em;
  max-height:auto;
  flex:1;
  text-align: left;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideRight {
  animation: slideIn 0.6s ease-in-out;
}



.previous-messages > span{
  display: flex;
  align-items: center;
  gap:.5em;
  padding:.5em;
  font-size:12px;
}

/*
chat box
*/

.chat-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 1em;
  display: flex;
  flex-direction: column;
  background-color: #f0f2f5;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 500px;
  overflow-y: auto;
  margin-top:1em;

}

.user-reply-container {
  margin-bottom: 1em;
  max-height: 500px;
  /* overflow-y: auto; */
  
}

.user-name {
  font-weight: bold;
  color: green;
  font-size: 16px;
  border-radius: 5px;
  padding:.5em;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 0.5em;
  &:hover{
   text-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
  }
  .newReplyIndicator{
    font-size: 10px;
    color:red;
    position: absolute;
    white-space: nowrap;
    top:0;
    z-index: 20;
    right:-3em;
  }
}



.user-messages {
  display: block;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5em;
  overflow-y: auto;
  max-height: 500px;
}

.message-bubble {
  margin-left:2em;
  padding: 4px 8px;
  border-radius: 18px;
  max-width: 70%;
  font-size: 12px;
  word-wrap: break-word;
  text-transform: lowercase;
  position: relative;
  display: block;
margin-bottom:3px;
}

.current-user {
  align-self: flex-end;
  background-color: green;
  color: #fff;
  border-bottom-right-radius: 2px;
  margin-left: auto;
}

.other-user {
  align-self: flex-start;
  background-color: #e4e6eb;
  color: #050505;
  border-bottom-left-radius: 2px;
  margin-right: auto;
}

.message-bubble::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.current-user::before {
  border-width: 0 8px 8px 0;
  border-color: transparent green transparent transparent;
  right: -8px;
  top: 10px;
}

.other-user::before {
  border-width: 8px 8px 0 0;
  border-color: #e4e6eb transparent transparent transparent;
  left: -8px;
  top: 10px;
}

@media (max-width: 768px) {
  .chat-container {
    max-width: 100%;
    padding: 0.5em;
  }

  .message-bubble {
    font-size: 12px;
    padding: 8px 12px;
  }
}



@media (max-width: 768px) {
  .speech2sign-translate-parent{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap:0em;
    width: 100%;
  }
  .speech2sign-translate-text-parent{
    margin-top:0;
  }
  .translate-parent {
    grid-template-columns: 1fr;
    gap: 5em;
  }
  .translate-grandparent {
    padding-top: 0em;
    gap:1em;
  }
  .previous-replies{
    position: fixed;
    min-width: 100%;
    max-width: 600px;
    background-color: #fff;
    top:0;
  }
  .chat-container {
    max-width:350px;
  
  }
  .translate-hand-container {
    margin-top: 0em;
  }
  .translate-default-hand {
    width: 150px;
    height: 150px;
  }

  .translate-text-container textarea {
    font-size: 16px;
    padding: 8px;
  }

  .buttons-container {
    flex-direction: row;
    gap: 10px;
  }
  .hidden{
    display:none;
  }


}

.contentContainer {
  padding: 0.5em;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  .contentHeader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom:1em;
    .title{
        font-size: 20px;
        color: gray;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0.5em;
        text-align: left;
    }
    .closeButton {
      background-color: transparent;
      font-size: 16px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      font-weight: 800;
      color: rgb(255, 83, 83);

      padding: 8px 16px;
    }
  }
  .main{
    display: flex;
    flex-direction: column;
    gap:.5em;
  }
} 

.toggler {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.toggleButton {
    padding: 0.5em 1em;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    margin: 0 0.5em;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.toggleButton:hover {
    background-color: #e0e0e0;
}

.active {
    background-color: #c0c0c0;
    font-weight: bold;
}
.main{
  transform: scaleX(-1)
}
.media {
    max-width: 100%;
    transform: scaleX(-1); 
    border-radius: 10px;
    min-height:280px;
}

.description {
    margin-top: 1em;
    color: #666;
    transform: scaleX(-1); 

    text-align: left;
}



@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

/* stud info */
.infoContainer { 
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;
 
}

.header {
  color: gray;
  font-weight: 800;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  .modalButton {
    background-color: transparent;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(255, 83, 83);
    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
}
.studInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 300px;
  text-align: left;
  padding: 1em 1em;
  font-size: 12px;
  text-transform: capitalize;
  .studentEmail{
    text-transform: none;
  }
}

.studImage {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1em 0;
  min-height: 160px;
}

.label {
  font-size: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0.3em 0;
  color: gray;
}

.image {
  object-fit: cover;
  border-radius: 15px;
  width: 150px;
  height: 150px;
  border: 1px solid #888;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 0.5em;
  padding: 0 .5em;
  justify-content: flex-end;
  .emailButton {
    background-color: transparent;
    font-size: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(23, 125, 42);
    padding: 8px 16px;
    font-size: 20px;

    &:hover {
      background-color: #fff;
    }
  }
  .notifButton {
    background-color: transparent;
    font-size: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(23, 125, 42);

    padding: 8px 16px;
    &:hover {
      background-color: #fff;
    }
  }
  .dropButton {
    background-color: transparent;
    font-size: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:.5em;
    color: rgb(255, 83, 83);
    padding: 8px 16px;
    font-size: 20px;
    &:hover {
      background-color: #fff;
    }
  }
}

.buttons button {
  font-size: 25px;
}

.buttons button:nth-child(3) {
  font-size: 15px;
}


/* email sending */
.emailParent {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.emailParent > form {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 0.5em;
  text-align: left;
  padding: 0.5em;
  border-radius: 15px;
}
.emailParent > form span {
  display: flex;
  align-items: center;
}

.emailParent > form span > label {
  font-size: 12px;
  width:100%;
  text-align: left;
}

/* This targets the second span inside the form */
.emailParent > form span:nth-of-type(2),
.emailParent > form span:nth-of-type(3) {
  display: flex;
  flex-direction: column;
}

/* If you want to target specific elements inside the second span, like the textarea */
.emailParent > form span:nth-of-type(2) textarea {
  /* Add your styling here */
  width: 100%; /* Example */
  height: 100px; /* Example */
}

.welcomeLoginForm {
  padding: 0.5em 2em;
  .loginWelcome {
    text-align: left;
    font-size: 30px;
    letter-spacing: 2px;
    color:#242629;
    font-weight: 800;
  }
  .loginInstruction {
    text-align: left;
    font-size: 16px;
    transform: translateY(-10px);
  }

  .loginRegisterNow{
    &:hover{
      text-decoration: underline;
    }
  }

  .loginInputsContainer{
    width:100%;
    box-sizing: border-box;
    display:flex;
    padding:0;
    margin:0;
    gap:.5em;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    label{
        width:calc(100% );
        text-align: left;
        transform:translateY(5px);
        font-weight:600;
        color:#242629;
    }
    input{
        border:1px solid #d2d2d2;
        border-radius: 8px;
        outline:none;
        width:calc(100% );
        position:relative;
    }
    >a{
        color:green;
        width: 100%;
        text-align: right;
        transition: .2s linear;
        font-size: 14px;
        &:hover{
            text-decoration: underline;
        }
    }
  }
  .errorMessage {
    color: red;
    width:100%;
    text-align: left;
    transform: translateY(-48px);
    font-size: 14px;
    margin-top: 10px;
  }
  
}

.choicesButtons {
  display: flex;
  gap: 1em;
  padding: 1em;
  justify-content: center;
  button {
    background-color: green;
    font-size: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 600;
    width: 150px;
    color: #fff;
    /* margin: 1em; */
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    padding: 8px;
    border:none;
    cursor: pointer;
    &:hover {
      scale: 1.04;
      background-color: green;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .choicesButtons {
    display: flex;
    gap: 1em;
    padding: 1em;
    justify-content: center;
    button {
      background-color: green;

      font-size: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
      width: 150px;
      margin: 0;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5em;
      padding: 8px;
      cursor: pointer;
   
    }
  }
}

.container{
  display: flex;
  flex-direction: column;
animation: fadeIn 0.5s ease-in-out;
  border-radius: 8px;
  left:0;
  padding:0;
  margin:0;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
animation: fadeIn 0.5s ease-in-out;
}

.cardWrapper{
  display: flex;
  flex-wrap: wrap;
  /* padding:1em; */
  grid-template-columns: repeat(auto-fit,minmax(200px ,1fr));
  gap:1em;
}

.card{
  position:relative;
  border: 1px solid rgb(198, 198, 198);
  padding:2em;
  display: flex;
  justify-content: flex-end;
  border-radius: 8px 50px 50px 8px;
  cursor: pointer;
  transition:.2s;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.4); */
  max-width:250px;
  background-color: #fff;
  width:250px;
} 
.card:hover{
  /* scale: 1.04; */
    box-shadow: 0 4px 8px rgba(0,0,0,0.4);
}
.kebab{
  position:absolute;
  bottom:-10px;
  font-size:25px;
  left:23px;
  transition:.2s;
  border-radius: 50%;
  display: grid;
  place-content: center;
  background-color: #fff;
  border:5px double gray;
  color:#000;
}
.kebab:hover{
  /* scale:1.2; */

}

.cardMenu{
  position:absolute;
  background-color: #fff;
  display: flex;
bottom:3px;
  right:50px;
  button{
    background-color: transparent;
    color:gray;
    font-size:16px;
    padding:0;
    padding:0 8px;
  }
  button:hover{
    background-color: #fff;
    color:#000;
  }
}
.title{
  position:relative;
  font-weight: 800;
color:#fff;
max-width: 150px;


}
.startBanner{
  border-top: 10px double gray;
position:absolute;
box-shadow: 0 8px 16px rgba(0,0,0,0.4);
left:10px;
width:80px;
height:90%;
color:#d2d2d2;
background-color: #fff;
top:0;
border-radius: 0 0 50px 50px;
display: grid;
place-content: center;
}
.score{
  transform: translateY(-15px);
  color:#000;
  font-weight: 800;
  width:100px;
  font-size: 16px;
}
.endBanner{
  position:absolute;
  box-shadow: 0 4px 8px rgba(0,0,0,0.4);
  right:0;
  width:100px;
  height:5px;
  bottom:-15%;
  border-radius: 50px;
}

@media (max-width:768px) {
  .cardWrapper{
    padding:0;
  }
  
}

/* backgrounds */
.contactBackgroundContainer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -1; 
  }
  
  .contactBackground {
    object-fit: cover;
    width: 100%;
    height: 100%;
  opacity: 0.5;

    /* filter: blur(10px); */
  }
.parent {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em;
  margin-bottom: 0.5em;
  width: 100%;
}
.right {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding:1em 0;
  padding-bottom:0;
  position: relative;
}

.backButton {
  padding: 8px 16px;
  background-color: #fff;
  /* border:1px solid gray; */
  color: gray;
  font-weight: 800;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.button,.add {
  padding: 8px 16px;
  background-color: #fff;
  /* border:1px solid gray; */
  color: gray;
  font-weight: 800;
  border-radius: 8px;
  display: grid;
  place-content: center;
  font-size: 25px;
}
.button:hover {
  background-color: #fff;
  color: gray;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

}

.add {
    background-color: green;
    color: #fff;

    &:hover {
        background-color: darkgreen;
        color: lightgray;
    }
}

.menuButtons {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom:-100px;
  right:0;
  align-items: center;
  gap: 0.5em;
  z-index: 100;
  background-color: #fff;
  padding: 0.5em 0 0.5em 0.5em;

  button {
    padding: 8px 16px;
    background-color: #fff;
    color: gray;
    font-weight: 800;
    border-radius: 8px;
    white-space: nowrap;
    font-size:12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    &:hover{
      background-color: #fff;
    }
  }
}
.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.search {
  padding: 8px 16px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
}
/* .hidden{
    display: none;
} */
@media (max-width: 760px) {
  .parent {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1em;
    margin-bottom: 0.5em;
    width: 100%;
  z-index: 100;

  }
  .button,.add {
    padding: 8px 16px;
    background-color: green;
    color:#fff;
    font-weight: 800;
    border-radius: 8px;
    display: grid;
    place-content: center;
    font-size: 25px;
    margin-top:1em;
  }
  .search {

    max-width: 150px;
  }

  
  .backButton {
    padding: 8px 16px;
    background-color: #fff;
    margin:0;
    /* border:1px solid gray; */
    color: gray;
    font-weight: 800;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }
  .left {
    font-size: 0.5em;
    font-weight: 800;
  }
  .right {
    display: flex;
    align-items: center;
    gap: 0.5em;
    .add{
      transform: translateY(-7px);

    }
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .menuButtons {
    bottom:-85px;
    right:0;
    align-items: center;
    background-color: #fff;
    gap: 0;
    z-index: 1000;
    padding: 0.5em 0 0.5 0.5;
    button {
      padding: 8px 16px;
      background-color: #fff;
      border:1px solid gray;
      color: gray;
      font-weight: 800;
      border-radius: 8px;
      font-size:10px;
      color:#fff;
      background-color: green;
      box-shadow: 0;
    }
  }
}

/* ========================
* student not joined classes
* ========================= */
.studentClassesContainer {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  > .avalableClassHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    padding: 1em 0;
    .avalableClassTitle {
      font-size: 20px;
      font-weight: 800;
      color: gray;
      display: flex;
      align-items: center;
      gap: 0.5em;
    }
    .avalableClassButtons {
      display: flex;
      gap: 0.5em;
      > input {
        border-radius: 15px;
        outline: none;
        border: 1px solid #d2d2d2;
        padding: 0.5em;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      }
      button {
        display: flex;
        gap: 0.5em;
        align-items: center;
        padding: 8px 16px;
      }
    }
  }
  .availableClassWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    .classCard {
      min-width: 250px;
      min-height: 250px;
      border: 1px solid #d2d2d2;
      text-align: left;
      font-size: 14px;
      border-radius: 15px;
      transition: 0.2s;
      position: relative;
      padding-bottom: 5em;
      /* first section */
      .classCardBanner {
        width: 130px;
        height: 130px;
        position: absolute;
        top: -5px;
        z-index: 2;
        right: -5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        border: 5px double gray;
        border-radius: 5px 15px 60px 60px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 0.5em;
        justify-content: center;
        font-size: 30px;
        color: #d2d2d2;
      }
      .menuToggler {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 25px;
        cursor: pointer;
        z-index: 3;
      }
      .classCardTitle {
        font-size: 30px;
        max-width: 80%;
        overflow: hidden;
        color: #d2d2d2;
        font-weight: 800;
      }
      .classCardDesc {
        color: #d2d2d2;
        text-transform: uppercase;
        font-size: 12px;

        > b {
          text-decoration: underline;
        }
      }
      .classCardFirstSection {
        position: relative;
        padding: 1em;
        border-bottom: 5px double gray;
        padding-bottom: 6em;
        border-radius: inherit;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
        /* teacher info */
        .classCardTeacherInfo {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 1em;

          gap: 0.5em;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          color: #d2d2d2;
          .classCardTeacherImage {
            object-fit: cover;
            min-width: 60px;
            min-height: 60px;
            max-width: 60px;
            max-height: 60px;
            border-radius: 50%;
            border: 5px double gray;
            left: 0;
          }
        }
      }

      .classCardButtons {
        width: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: flex-end;
        right: 10px;
        bottom: 10px;
        > button {
          color: #fff;
          font-weight: 800;
          display: flex;
          align-items: center;
          gap: 0.5em;
          background-color: green;
          padding: 8px 16px;
          transition: 0.2s;
          &:hover {
            scale: 1.1;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

.menuOptions {
  position: absolute;
  top: 30px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.menuOptions button {
  display: block;
  width: 100%;
  padding: 5px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideIn {
  animation: slideIn 0.6s ease-in-out;
}
.fadeIn {
  animation: fadeIn 0.6s ease-in-out;
}

@media (max-width: 768px) {
 /* ========================
* student not joined classes
* ========================= */
.studentClassesContainer {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  > .avalableClassHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;
    padding: 1em 0;
    .avalableClassTitle {
      font-size: 14px;
      font-weight: 800;
      color: gray;
      align-items: center;
      gap: 0.5em;
    }
    .avalableClassButtons {
      display: flex;
      gap: 0.5em;
      > input {
        border-radius: 15px;
        outline: none;
        border: 1px solid #d2d2d2;
        padding: 0.5em;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
      }
      button {
        display: flex;
        gap: 0.5em;
        align-items: center;
        padding: 8px 16px;
      }
    }
  }
  .availableClassWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1em;
    .classCard {
      min-width: 250px;
      min-height: 250px;
      border: 1px solid #d2d2d2;
      text-align: left;
      font-size: 14px;
      border-radius: 15px;
      transition: 0.2s;
      position: relative;
      padding-bottom: 5em;
      /* first section */
      .classCardBanner {
        width: 130px;
        height: 130px;
        position: absolute;
        top: -5px;
        z-index: 2;
        right: -5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        border: 5px double gray;
        border-radius: 5px 15px 60px 60px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 0.5em;
        justify-content: center;
        font-size: 30px;
        color: #d2d2d2;
      }
      .menuToggler {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 25px;
        cursor: pointer;
        z-index: 3;
      }
      .classCardTitle {
        font-size: 30px;
        max-width: 80%;
        overflow: hidden;
        color: #d2d2d2;
        font-weight: 800;
      }
      .classCardDesc {
        color: #d2d2d2;
        text-transform: uppercase;
        font-size: 12px;

        > b {
          text-decoration: underline;
        }
      }
      .classCardFirstSection {
        position: relative;
        padding: 1em;
        border-bottom: 5px double gray;
        padding-bottom: 6em;
        border-radius: inherit;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
        /* teacher info */
        .classCardTeacherInfo {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 1em;

          gap: 0.5em;
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          color: #d2d2d2;
          .classCardTeacherImage {
            object-fit: cover;
            min-width: 60px;
            min-height: 60px;
            max-width: 60px;
            max-height: 60px;
            border-radius: 50%;
            border: 5px double gray;
            left: 0;
          }
        }
      }

      .classCardButtons {
        width: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        justify-content: flex-end;
        right: 10px;
        bottom: 10px;
        > button {
          color: #fff;
          font-weight: 800;
          display: flex;
          align-items: center;
          gap: 0.5em;
          background-color: green;
          padding: 8px 16px;
          transition: 0.2s;
          &:hover {
            scale: 1.1;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }
}

}

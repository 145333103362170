.parent {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1em; */
  /* margin-bottom: 0.5em; */
  width: 100%;
  padding:1em 2em;
}

.backButton {
  padding: 8px 16px;
  background-color: #fff;
  /* border:1px solid gray; */
  color: gray;
  font-weight: 800;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.right {
  gap: 0.5em;
  margin:0;
  padding:0;
}
.button {
  padding: 8px 16px;
  background-color: #fff;
  font-weight: 800;
  border-radius: 8px;
  margin:1em 0;
  display: grid;
  place-content: center;
  font-size: 25px;
  background-color: green;
  color: #fff;
}
.button:hover {
  background-color: green;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);

  color: #fff;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.hidden {
  display: none;
}
@media (max-width: 760px) {
  .parent {
    position: relative;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5em;
    margin-bottom: 0.5em;
    width: 100%;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
  .buttonContainer {
    display: flex;
    align-items: center;
    gap: 0.5em;

  }

  .button{
  margin-top:1em;

  }
}

/* Container with glassmorphism effect */
.changeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .form{
    padding:0;
  }
}

.changePasswordContainer {
  background: transparent;
  border-radius: 15px;
  /* padding: 2em; */
  width: 350px;
  max-width: 90%;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* backdrop-filter: blur(10px); */
  /* -webkit-backdrop-filter: blur(10px); */
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  text-align: center;
}

/* Styling for the form title and button */
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding:.3em;
  border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #242629;
  margin-bottom: 1em;
}

/* Input fields styling */
.inputGroup {
  margin-bottom: 1.5em;
  line-height: 1em;
  gap:.5em;

}

.input {
  width: 100%;
  margin:0;
  padding: 0.6em;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 16px;
}

/* Button styling */
.changeButtonsContainer{
  display: flex;
  gap:.5em;
  align-items: center;
  justify-content: center;
  padding:0;
  margin:0;
  .backArrow{
    font-size:16px;
    border:none;
    background-color: transparent;
    color:#fff;
  }
  .changeButton{
    font-size: 16px;
    border:none;
    background-color: greenyellow;
    color:#242629;

  }
}


.changeButton:hover{
  background-color: greenyellow;
}
.backArrow:hover {
  color:#000;
}
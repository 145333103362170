.container {
  box-sizing: border-box;
  text-align: right;
  border-radius: 25px;
  animation: fadeIn 0.5s ease-in-out;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.participantsWrapper {
    display: flex;
    /* padding: 0 1em; */
    gap: .5em;
  }

.header {
  font-size: 20px;
  font-weight: 800;
  width: 100%;
  color: gray;
  text-align: left;
  width: 100%;
  display: flex;
  text-transform: uppercase;
  border-radius: 8px 8px 0 0;
  align-items: center;
  justify-content: space-between;
  > span {
    padding: 1em;
  }
  > button {
    background-color: transparent;
    font-size: 16px;
    margin: 1em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 800;
    color: rgb(255, 83, 83);
    padding: 8px 16px;
  }
}
@media (max-width: 768px) {
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

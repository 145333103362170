.sidebarList {
  text-decoration: none;
  list-style: none;
  transition: 0.2s linear;
  cursor: pointer;
  border-right-style: none;
  position:relative;
  display: flex;
  flex-wrap: wrap;
  padding:0;
}

.sidebarList:hover {
  scale: 1.1;
}

.sidebarList  .sidebarButton {
  border: none;
  text-align: left;
  cursor:pointer;
  padding:.5em;
  border-radius: 10px;
  font-size: 10px;
}

@media (max-width:768px){
  .sidebarList {

    padding:.2em;
  }

  .sidebarList  .sidebarButton {
    border: none;
    text-align: left;
    width: 100%;
    cursor:pointer;
    padding:.5em;
    border-radius: 10px;
  }
  
}

.container {
  margin:0;
  padding:0;
  padding:2em;
  animation: fadeIn 0.5s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  box-sizing: border-box;
  width: 100%;
  padding: 1em 0;
  margin:0;
  padding-bottom:4em;
}

.cardContainer {
  /* padding: 1em; */
  background-color: transparent;
  border-radius: 5px;
  margin:0;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
}
.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 25px;
  margin:0;
  padding:0;
  transition: 0.2s;
  border: 1px solid rgb(200, 200, 200);
}
.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  scale: 1.04;
}
.cardBody {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 100%;

  border-radius: 0 25px 25px 0;
  border: 1px solid #d2d2d2;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  .icon {
    position: absolute;
    top: -4px;
    right: -3px;
    padding: 0.5em 0.5em 0.5em 1em;
    border-radius: 5px 25px 5px 25px;
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    font-weight: 800;
    border: 5px double gray;
    color: #fff;
background-color: #242629;
    font-size: 12px;
    transition: 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .icon:hover {
    scale: 1.04;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    background-color: #242629;
    color:#fff;
  }
}

.cardImg {
  width: calc(100%);
  height: 200px;
  border-radius: 25px 0 0 25px;
}

.cardTitle,
.cardText {
  font-size: 30px;
  font-weight: 800;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  letter-spacing: 5px;
  transform:translateY(10px)
}

.cardTitle {
  font-weight: 800;
  text-transform: capitalize;
  padding: 12px 15px;
  border-radius: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;

  transition: all 0.2s ease-in-out;
  text-shadow: none;
  color: gray;
  background:rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

/* .cardTitle:hover {
  color: #000;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.6);
} */
.cardText {
  margin: 0.5em;
  border: 1px double #eaeaea;
  border-radius: 15px;
  padding: 0.3em;
  background-color: #f1f1f1;
  color: gray;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  margin-top: 1em;
}
@media (max-width: 760px) {
  .parent {
    grid-template-columns: 1fr;
    gap:0;
  }
  .cardContainer {
    padding: 1em;
    background-color: transparent;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.2s;
    position: relative;
  }
  .card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-radius: 25px;
    transition: 0.2s;
    border: 1px solid rgb(200, 200, 200);
  }
  .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    scale: 1.04;
  }
  .cardBody {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    width: 100%;
  
    border-radius: 0 25px 25px 0;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .icon {
      position: absolute;
      top: -4px;
      right: -3px;
      padding: 0.5em 0.5em 0.5em 1em;
      border-radius: 5px 25px 5px 25px;
      display: flex;
      align-items: center;
      gap: 0.5em;
      justify-content: center;
      font-weight: 800;
      border: 5px double gray;
      color: #fff;
  background-color: #242629;
      font-size: 12px;
      transition: 0.2s;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
  
    .icon:hover {
      scale: 1.04;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    }
  }
  
  .cardImg {
    width: calc(100%);
    height: 200px;
    border-radius: 25px 0 0 25px;
  }
  
  .cardTitle,
  .cardText {
    font-size: 20px;
    font-weight: 800;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    letter-spacing: 5px;
    transform:translateY(-10px)
  }
  
  .cardTitle {
    font-weight: 800;
    text-transform: capitalize;
    padding: .5em;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc;
  
    transition: all 0.2s ease-in-out;
    text-shadow: none;
    color: gray;
    background:rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
  }
  
  .cardTitle:hover {
    color: #000;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    text-shadow: 2px 2px 10px rgba(255, 255, 255, 0.6);
  }
  .cardText {
    margin: 0.5em;
    border: 1px double #eaeaea;
    border-radius: 15px;
    padding: 0.3em;
    background-color: #f1f1f1;
    color: gray;
  }
  
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 0.5em;
    margin-top: 1em;
  }
}

.checkStudentsContainer,
.mailContainer {
  width: 100%;
  padding: 0 1.5em;
  animation: fadeIn 0.5s ease-in-out;
}
.mailContainer {
  max-height: 500px;
  padding:0;
  overflow-y: hidden;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

.selectContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5em;
  max-width: 500px;

  select {
    color: gray;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 8px;
  }
  option {
    color: gray;
  }
  > span {
    > input {
      border: 1px solid rgb(200, 200, 200);
      outline: none;
      border-radius: 8px;
      padding: 1em;
      color: gray;
    }
  }
}

.parent {
  width: 100%;
  box-sizing: border-box;
  height: auto;
  overflow-y: auto;
  min-height: 100vh;
  justify-content: flex-start;
  padding-bottom: 3em;
  > button {
    border: 1px solid gray;
    padding: 1em;
  }
}
.cardWrapper {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #fff;
  margin: 1em 0;
  border-radius: 8px;
  gap:0;
  padding: 0;
  height: auto;
  max-height: 100vh;
  overflow-y: auto;
}
.parent p {
  font-size: 16px;
  color: gray;
  width: 100%;
  text-align: left;
  padding: 1em;
}

.card {
  font-size: 12px;
  display: flex;
  gap: 1em;
  margin: 0.5em 0;
  align-items: center;
  position: relative;
  padding: 0em;
  justify-content: space-between;
  text-align: left;
}
.name {
  font-size: 14px;
  flex:1;
  white-space: nowrap;
  min-width: 150px;
}
.email {
  font-size: 14px;
  white-space: nowrap;

}

.button {
  border-radius: 5px;
  padding: 8px 16px;
  transition: 0.2s linear;
  flex:1;

  cursor: pointer;
  text-align: center;
  max-width: 100px;
  font-size: 14px;
  margin:0;
  font-weight: 800;
  background-color: green;
  color: #fff;
  &:hover {
    background-color: greenyellow;
    color: #000;
  }
}

.icons {
  display: flex;
  align-items: center;
  gap: 0.5em;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #888;
}
.icon {
  font-size: 25px;
}

@media (max-width: 768px) {
  .parent {
    width: 100%;
    box-sizing: border-box;
    height: auto;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 100vh;
    justify-content: flex-start;
    padding: 1em 0;
    padding-bottom: 3em;
  }
  .cardWrapper {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fff;
    margin: 0.5em 0;
    border-radius: 8px;
    padding: 0;
    height: auto;
    max-height: 100vh;
    overflow-y: auto;
  }

  .card {
    font-size: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    gap: 1em;
    margin: 0.5em 0;
    align-items: center;
    position: relative;
    padding: 0.5em;
    text-align: left;
  }
  .name {
    font-size: 12px;
    min-width: 200px;
  }
  .email {
    font-size: 10px;
    display:none;
  }

  .selectContainer {
    display: grid;
  grid-template-columns: 1fr 1fr 1fr;
    gap: 0.5em;
    max-width: 100%;
    margin:0;
    box-sizing: border-box;
  
    select {
      color: gray;
      background-color: #fff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;

    }
    option {
      color: gray;
    }
    > span {
      > input {
        border: 1px solid rgb(200, 200, 200);
        outline: none;
        border-radius: 8px;
        padding: 1em;
        color: gray;
      }
    }
  }
  
  .button {
    border-radius: 5px;
    padding: 8px;
    transition: 0.2s linear;
    cursor: pointer;
    text-align: center;
    min-width: 50px;
    font-size: 10px;
    font-weight: 800;
    background-color: green;
    color: #fff;
    &:hover {
      background-color: greenyellow;
      color: #000;
    }
  }
  
  .icons {
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #888;
  }
  .icon {
    font-size: 25px;
  }
}
